import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

type SelectElementParPagePropsStyleProps = {
    $invalid?: boolean
};

export const BoxSelectElementParPage = styled.div`
    width: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    font-family: Roboto-Regular;
`;

export const SelectElementParPageCustom = styled.select<SelectElementParPagePropsStyleProps>`
    width: 100%;
    height: 25px;
    border: none;
    background-color: transparent;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); 
    border-radius: ${theme.css.radius};
    padding: 0 10px;
    font-family: Roboto-Regular;
    font-size: ${theme.font.small};
    outline: none;
    ${props => props.$invalid && `border: solid 1px ${theme.color.alert};`}
    &option{
        font-family: Roboto-Regular;
        font-size: 16px;
        color: ${theme.color.letterGray};
    }
`;

export const LabelSelectElementParPage = styled.label`
    color: ${theme.color.info}
`;