import { styled } from "styled-components";
import { CheckboxSignatureStyleProps } from "./CheckboxSignatureType";
import { theme } from "../../../../theme/theme";

export const BoxCheckboxSignature = styled.div<CheckboxSignatureStyleProps>`
    width: ${props => props.$width ? `${props.$width}` : `fit-content`};
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const LabelCheckboxSignature = styled.label`
    font-size: ${theme.font.normal};
    font-weight: 550;
    margin-bottom: 5px;
    &:after{
        content: "*";
        color: crimson;
    }
    @media (max-width: 900px) {
        font-size: 14px;
    }
`;

export const InputCheckboxSignature = styled.input`
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
        width: 15px;
        min-width: 15px;
        height: 15px;
        min-height: 15px;
    }
`;