import { ChangeEvent, useState } from 'react';
import { DocumentsErreur } from '../../../../shared/class/DocumentsErreur';
import LabelCustom from '../../../common/field/labelCustom/LabelCustom';
import { InputFileKbisProps } from './InputFileKbisType';
import { BoxGlobalInputFileKbis, BoxIconLabelKbis, BoxInputFileKbis, BoxInputFileKbisIconClose, CameraKbis2, ErrorInputFileKbis, InputFileKbis2, LabelInputFileKbis } from './InputFileKbisStyle';

const InputFileKbis = ({ label, setFile, setError, error, nameError, require = false, disabled }: InputFileKbisProps): JSX.Element => {

    const [colorBorder, setcolorBorder] = useState<string>('#e0e0e0');
    const [colorLabel, setcolorLabel] = useState<string>('#616161');

    const [nameDoc, setNameDoc] = useState<string | null>(null);

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {

        setError('');
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');

        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size >= 67000000) {
                setError((error: DocumentsErreur) => {
                    const err = { ...error, [nameError]: 'Le document est limité a 67ko' };
                    return err;
                });

                return;
            };

            setFile(e.target.files[0]);
            setNameDoc(e.target.files[0].name);
            e.target.files[0] ? setcolorBorder('green') : setcolorBorder('#e0e0e0');
            e.target.files[0] ? setcolorLabel('green') : setcolorLabel('#616161');
        };
    };

    return (
        <BoxGlobalInputFileKbis>

            {label !== '' && <LabelCustom label={label} require={require} />}

            <BoxInputFileKbisIconClose $disabled={disabled}>
                <BoxInputFileKbis $color={colorBorder} $disabled={disabled}>
                    <BoxIconLabelKbis $disabled={disabled}>
                        <CameraKbis2 $colorLabel={colorLabel} $disabled={disabled}/>
                        <LabelInputFileKbis
                            $colorLabel={colorLabel}>
                            {nameDoc ? nameDoc : 'Ajouter un fichier...'}
                        </LabelInputFileKbis>
                    </BoxIconLabelKbis>

                    <InputFileKbis2
                        onChange={onChangeFile}
                        type="file"
                        capture="environment"
                        accept="image/*"
                    />
                </BoxInputFileKbis>

            </BoxInputFileKbisIconClose>

            <ErrorInputFileKbis>{error}</ErrorInputFileKbis>

        </BoxGlobalInputFileKbis>
    );
};

export default InputFileKbis;