import { z } from "zod";

export const SinistreIncompletSchema = z.object({
    idSinistre: z.number(),
    numSinistre: z.string(),
    createdAtFacture: z.string(),
    idFactVosFactures: z.string(),
    statusFacture: z.string(),
    nomAssure: z.string(),
    prenomAssure: z.string(),
    dossierTransmit: z.boolean()
});

export const SinistreIncompletRetourApiSchema =  SinistreIncompletSchema.array();

export type SinistreIncomplet = z.infer<typeof SinistreIncompletSchema>;
export type SinistreIncompletRetourApi = z.infer<typeof SinistreIncompletRetourApiSchema>;

export type ListeRowProps = {
    $linename: string
};

export type TableDossierIncompletProps = {
    sinistreIncomplet: SinistreIncomplet[]
};