import styled from "styled-components";
import { Camera } from 'react-bootstrap-icons';
import { theme } from "../../../../theme/theme";

export type InputFileKbisStyleProps = {
    $color?: string,
    $colorLabel?: string,
    $disabled?: boolean
};

export const BoxGlobalInputFileKbis = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 900px) {
        width: 95%; 
    }
`;

export const BoxInputFileKbis = styled.div<InputFileKbisStyleProps>`
    position: relative; 
    width: 100%;
    height: 47px;
    padding: 5px 10px; 
    border: solid 2px ${props => props.$color};
    background-color: white; 
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
    ${props => props.$disabled && `
            cursor: not-allowed;
            pointer-events: none;
            border: solid 2px ${theme.color.grayMedium};
    `}
`;

export const BoxInputFileKbisIconClose = styled.div<InputFileKbisStyleProps>`
    position: relative; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: center;
    &:hover{
        cursor: pointer;
    }
    ${props => props.$disabled && `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;

export const BoxIconLabelKbis = styled.div<InputFileKbisStyleProps>`
    width: 100%;
    background-color: transparent;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start; 
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
    ${props => props.$disabled && `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;

export const CameraKbis2 = styled(Camera)<InputFileKbisStyleProps>`
    color: ${props => props.$colorLabel};
    margin-right: 20px;
    font-size: 35px;
    &:hover{
        cursor: pointer;
    }
    ${props => props.$disabled && `
        cursor: not-allowed;
        pointer-events: none;
        color: ${theme.color.grayMedium};
    `}
`;

export const LabelInputFileKbis = styled.label<InputFileKbisStyleProps>`
    color: #616161;
    font-size: ${props => props.theme.font.medium};
    &:hover{
        cursor: pointer;
    }
`;

export const InputFileKbis2 = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const ErrorInputFileKbis = styled.p`
    margin-top: 5px;
    color: ${theme.color.alert}; 
    font-size: 16px;
    height: 16px;
`;





