import logoProfact from './logo_profact.png';
import { BoxLogo, ImgProfact } from './LogoProfactStyle';

const LogoProfact = (): JSX.Element => {
    return (
        <BoxLogo>
            <ImgProfact src={logoProfact} title='logo profact' loading="lazy"/>
        </BoxLogo>
    );
};

export default LogoProfact;