import { format, parse } from "date-fns";
import { BoxBtnModalKbisExpire, BoxDateKbis, DateKbis, FormModalKbisExpire, HeaderModalKbisExpire, ModalBody, SeparatorDateKbis, Wrapper } from "./KbisModalStyle";
import { createPortal } from 'react-dom';
import { FormEvent, useContext, useEffect, useState } from "react";
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import Button from "../../../common/button/Button";
import InputFileKbis from "../inputFileKbis/InputFileKbis";
import { catchError } from "../../../../core/request/catchError";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import { DateKbisSchema, DateKbisType } from "../../../../contexts/authContext/AuthContextType";
import { AuthContext, AuthTest } from "../../../../contexts/authContext/AuthContext";

const KbisModal = (): JSX.Element | null => {

    const { token, id, kbisEdition, kbisExpire } = useContext<AuthTest>(AuthContext).isAuthenticated;
    const { addDateKbis } = useContext<AuthTest>(AuthContext);

    const { pushToast } = useToasts();
    const [dateEditionKbis, setDateEditionKbis] = useState<string>('');
    const [dateEditionKbisIsDisabled, setDateEditionKbisIsDisabled] = useState<boolean>(false);
    const [dateEditionKbisErreur, setDateEditionKbisErreur] = useState<string>();

    const [kbis, setKbis] = useState<File>();
    const [kbisIsDisabled, setKbisIsDisabled] = useState<boolean>(false);
    const [kbisErreur, setKbisErreur] = useState<string>('');

    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        kbis && dateEditionKbis?.length ? setBtnIsDisabled(false) : setBtnIsDisabled(true);
    }, [kbis, dateEditionKbis]);

    const enregistrerNouveauKbis = (e: FormEvent) => {
        e.preventDefault();

        setDateEditionKbisErreur('');
        setKbisErreur('');

        if (!kbis && !dateEditionKbis?.length) {
            setDateEditionKbisErreur('obligatoire');
            setKbisErreur('obligatoire');
            return;
        };

        if (!dateEditionKbis?.length) {
            setDateEditionKbisErreur('obligatoire');
            return;
        };

        if (!kbis) {
            setKbisErreur('obligatoire');
            return;
        };

        setLoading(() => true);
        setKbisIsDisabled(() => true);
        setDateEditionKbisIsDisabled(() => true);
        setBtnIsDisabled(() => true);

        const upadteKbis = new FormData();
        upadteKbis.append('dateEditionKbis', JSON.stringify({ dateEditionKbis: dateEditionKbis }));
        upadteKbis.append('kbis', kbis);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/kbis/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: upadteKbis
            }
        )
            .then((response) => {
                return response.json();
            })
            .then((data: DateKbisType) => {

                catchError(data);

                if (!DateKbisSchema.safeParse(data).success) {
                    pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
                    return;
                };

                addDateKbis({ ...data });
            })
            .catch((error: Error) => {
                pushToast({ content: `${error.message}`, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
                setKbisIsDisabled(() => false);
                setDateEditionKbisIsDisabled(() => false);
                setBtnIsDisabled(() => false);
            });
    };

    if (kbisExpire && kbisExpire) {

        const date = format(new Date(), 'dd/MM/yyyy');
        const dateDuJour = parse(date, 'dd/MM/yyyy', new Date());
        const dateExpire = parse(kbisExpire, 'dd/MM/yyyy', new Date());

        if (dateExpire <= dateDuJour) {
            return createPortal(
                <Wrapper>
                    <ModalBody>
                        <HeaderModalKbisExpire>
                            <h1>Votre extrait de Kbis est expiré</h1>
                        </HeaderModalKbisExpire>

                        <FormModalKbisExpire onSubmit={enregistrerNouveauKbis} id="formKbis">

                            <BoxDateKbis>
                                <DateKbis>date d'édition: <span>{kbisEdition}</span></DateKbis>
                                <DateKbis>date d'expiration: <span>{kbisExpire}</span></DateKbis>
                            </BoxDateKbis>

                            <SeparatorDateKbis />

                            <InputCustom
                                width={100}
                                label="Date d'édition du Kbis"
                                id="dateEditionKbis"
                                type="date"
                                value={dateEditionKbis}
                                onChange={(e) => setDateEditionKbis(e.target.value)}
                                error={dateEditionKbisErreur}
                                require={true}
                                disabled={dateEditionKbisIsDisabled}
                            />

                            <InputFileKbis
                                setFile={setKbis}
                                label="Kbis"
                                setError={setKbisErreur}
                                error={kbisErreur}
                                nameError="Kbis"
                                require={true}
                                disabled={kbisIsDisabled}
                            />

                            <BoxBtnModalKbisExpire>
                                <Button
                                    label="Envoyer"
                                    type="submit"
                                    width={115}
                                    color="#0288d1"
                                    colorlabel="#EEEEEE"
                                    loader={loading}
                                    disabled={btnIsDisabled}
                                />
                            </BoxBtnModalKbisExpire>

                        </FormModalKbisExpire>
                    </ModalBody>
                </Wrapper>
                , document.body);
        };
    };

    return null;
};

export default KbisModal;