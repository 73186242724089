import Accordion from "../../../common/accordion/Accordion";
import { ModifieAbonnementProps } from "./ChangeAbonnementType";
import { BoxInfoDataAbonnement, DataInfoCreditRestant, DataInfoDataAbonnement, LabelInfoCreditRestant, LabelInfoDataAbonnement } from "./ChangeAbonnementStyle";

const ChangeAbonnement = ({ data }: ModifieAbonnementProps): JSX.Element => {
    return (
        <Accordion title="Mon abonnement">
            <div>
                <BoxInfoDataAbonnement>
                    <LabelInfoCreditRestant>Crédits mensuel restant:</LabelInfoCreditRestant>
                    <DataInfoCreditRestant>{data.creditAbonnement - data.creditConsoDuMois}</DataInfoCreditRestant>
                </BoxInfoDataAbonnement>

                <BoxInfoDataAbonnement>
                    <LabelInfoDataAbonnement>Crédits abonnement:</LabelInfoDataAbonnement>
                    <DataInfoDataAbonnement>{data.creditAbonnement}</DataInfoDataAbonnement>
                </BoxInfoDataAbonnement>

                <BoxInfoDataAbonnement>
                    <LabelInfoDataAbonnement>Crédits mensuel consommé:</LabelInfoDataAbonnement>
                    <DataInfoDataAbonnement>{data.creditConsoDuMois}</DataInfoDataAbonnement>
                </BoxInfoDataAbonnement>

                <BoxInfoDataAbonnement>
                    <LabelInfoDataAbonnement>Crédits consommé global:</LabelInfoDataAbonnement>
                    <DataInfoDataAbonnement>{data.creditConsoGlobal}</DataInfoDataAbonnement>
                </BoxInfoDataAbonnement>
            </div>
        </Accordion>
    );
};

export default ChangeAbonnement;