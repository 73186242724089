import { useEffect, useState } from "react";
import { catchError } from "../core/request/catchError";
import { z } from "zod";

export const CoordonneeAssuranceSchema = z.object({
    id: z.number(),
    nomSociete: z.string(),
    service: z.string().nullable(),
    adresse: z.string(),
    complementAdresse: z.string().nullable(),
    ville: z.string(),
    codePostal: z.string(),
    email: z.string().nullable(),
});

export const CoordonneeAssuranceTableSchema = CoordonneeAssuranceSchema.array();

export type CoordonneeAssuranceType = z.infer<typeof CoordonneeAssuranceSchema>;

export type useCoordonneeAssuranceRetour = {
    coordonneeAssurance: CoordonneeAssuranceType[]
    loading: boolean,
    error: string | null
};

export default function useCoordonneeAssurance(token: string | undefined): useCoordonneeAssuranceRetour {

    const [coordonneeAssurance, setCoordonneeAssurance] = useState<CoordonneeAssuranceType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/assurance/coordonnee`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => {

                return response.json();
            })
            .then((data: CoordonneeAssuranceType[]) => {

                catchError(data);

                if(!CoordonneeAssuranceTableSchema.safeParse(data).success){
                    setError(() => "Les données reçues ne sont pas conformes aux attentes");
                    return;
                };

                setCoordonneeAssurance(() => data);
            })
            .catch((error: Error) => {
                setError(() => error.message);
            })
            .finally(() => {
                setLoading(() => false);
            });
    }, [token]);

    return { coordonneeAssurance, loading, error } as const;
};