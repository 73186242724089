import { keyframes, styled } from "styled-components";
import { theme } from "../../../../theme/theme";

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const WrapperModalControl = styled.div`
    z-index: 10;
    width: 100vw;
    height: 100vh; 
    position: fixed; 
    top: 0; 
    left: 0; 
    background-color: black; 
    display: flex; 
    align-items: center;
    justify-content: center;
    animation: ${opacity} 225ms forwards;
`;

export const ModalBodyControl = styled.aside`
    background-color: ${theme.color.white};
    width: 35%;
    box-shadow: ${theme.css.shadowModal};
    border-radius: ${theme.css.radius};
    overflow: hidden;
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 96vw; 
    }
    @media screen and (max-width: 767px) {
        width: 96vw; 
    }
`;

export const HeaderModalControl = styled.header`
    width: 100%;
    height: 30px;
    padding: 25px;
    background: ${theme.color.linearGardian};
    color: ${theme.color.white};
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        & h1 {
          font-size: ${theme.font.normal};
        } 
    }
`;

export const BoxModalControl = styled.div`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextModalControl = styled.span`
    width: 100%;
    font-size: ${theme.font.medium};
    color: ${theme.color.letterGray};
    font-family: Roboto-Regular;
    text-align: center;
    margin-bottom: 25px;
    @media screen and (max-width: 767px) {
      font-size: ${theme.font.normal};
    }
`;
