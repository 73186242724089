import { keyframes, styled } from "styled-components";

export const BoxParamCentre = styled.div`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      padding: 25px 10px;
    }
`;

export const BoxParamCentreLoaderError = styled.div`
    width: 100%;
    padding: 25px;
    margin-top: 50px; 
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
`;

export const ErrorParamCentre = styled.p`
    color: crimson; 
    font-size: 20px;
`;

export const prixClipFix = keyframes`
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
    100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    animation: ${rotate} 1s linear infinite;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 3px solid #616161;
      animation: ${prixClipFix} 2s linear infinite ;
  }
`;
