import styled from 'styled-components';

export const BoxFieldModifieDonne = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex; 
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
    }
`;

export const BoxBtnModifieDonne = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: flex-end;
`;

export const BoxBtnAnnulModifiModifieDonne = styled.div`
    width: 210px;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
