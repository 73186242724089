export class Assure {
    isPro: boolean;
    nomSociete: string;
    numSiret: string;
    civilite: string;
    nom: string;
    prenom: string;
    adresse: string;
    codePostal: string;
    ville: string;
    telephone: string;
    email: string;

    constructor() {
        this.isPro = false;
        this.nomSociete = '';
        this.numSiret = '';
        this.civilite = 'M.'; 
        this.nom = '';
        this.prenom = '';
        this.adresse = '';
        this.codePostal = '';
        this.ville = '';
        this.telephone = '';
        this.email = '';
    };
};