import { z } from "zod";

export const LoginBodySchema = z.object({
    email: z.string().email(),
    password: z.string()
});

export const LoginResponseSchema = z.object({
    token: z.string(),
    refresh_token: z.string()
});

export type LoginBody = z.infer<typeof LoginBodySchema>;
export type LoginResponse = z.infer<typeof LoginResponseSchema>;