import { format } from "date-fns";
import { SinistreIncomplet, SinistreIncompletRetourApi, SinistreIncompletRetourApiSchema } from "./ArrayListeDossierClientType";
import { useNavigate } from "react-router-dom";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthTest } from '../../../../contexts/authContext/AuthContext';
import { BoxBodyListe, BoxHeadListe, BoxListe, IconFacture, IconNotSend, IconSend, IconVoir, LinkCustom, ListeRowBody, ListeRowHead, ListeRowIconBody, ListeRowIconVoirBody, ListeRowVoirHead, Loader, MessageNoDossier } from "./ArrayListeDossierClientStyle";
import { catchError } from "../../../../core/request/catchError";
import Pagination from "../../../common/pagination/Pagination";
import InputSearch from "../inputSearch/InputSearch";

const ArrayListeDossierClient = (): JSX.Element | null => {
    const navigate = useNavigate();
    const { pushToast } = useToasts();
    const { id, token } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [loading, setLoading] = useState<boolean>(true);
    const [sinistreIncomplet, setSinistreIncomplet] = useState<SinistreIncomplet[]>([]);

    const [fieldSearch, setFieldSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {

        setLoading(true);

        id && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/facture/incomplete/${id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                return response.json();
            })
            .then((data: SinistreIncompletRetourApi) => {

                catchError(data);

                if (!SinistreIncompletRetourApiSchema.safeParse(data).success) {
                    pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
                    return;
                };

                setSinistreIncomplet(data);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, token, pushToast]);

    if (loading) {
        return (
            <BoxListe>
                <Loader />
            </BoxListe>
        );
    } else if (!loading && sinistreIncomplet.length) {
        return (
            <BoxListe>

                <InputSearch
                    width={90}
                    id="search"
                    type="text"
                    value={fieldSearch}
                    onChange={(e) => setFieldSearch(e.target.value)}
                />

                <BoxHeadListe>
                    <ListeRowHead $linename="numSinistre">Numéro sinistre</ListeRowHead>
                    <ListeRowHead $linename="identite">Assuré</ListeRowHead>
                    <ListeRowHead $linename="date">Date édition</ListeRowHead>
                    <ListeRowHead $linename="facture"></ListeRowHead>
                    <ListeRowVoirHead $linename="display"></ListeRowVoirHead>
                    <ListeRowHead $linename="send"></ListeRowHead>
                </BoxHeadListe>

                {sinistreIncomplet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter((val) => {
                    return val.nomAssure.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                        `${val.nomAssure} ${val.prenomAssure}`.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                        val.numSinistre.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase()) ||
                        val.createdAtFacture.toLocaleLowerCase().includes(fieldSearch.toLocaleLowerCase())
                }).map((sinistre, index) => (
                    <BoxBodyListe key={index}>
                        <ListeRowBody $linename="numSinistre" onClick={() => navigate(`/dossier/complet/${sinistre.idSinistre}`)}>{sinistre.numSinistre}</ListeRowBody>
                        <ListeRowBody $linename="identite" onClick={() => navigate(`/dossier/complet/${sinistre.idSinistre}`)} >{sinistre.nomAssure} {sinistre.prenomAssure}</ListeRowBody>
                        <ListeRowBody $linename="date" onClick={() => navigate(`/dossier/complet/${sinistre.idSinistre}`)} >{format(new Date(sinistre.createdAtFacture), 'dd/MM/yyyy')}</ListeRowBody>

                        <ListeRowIconVoirBody $linename="facture">
                            <LinkCustom to={`/dossier/facture/${sinistre.idSinistre}`}>
                                {!sinistre.dossierTransmit ? <IconFacture /> : <div style={{ width: 25 }}></div>}
                            </LinkCustom>
                        </ListeRowIconVoirBody>

                        <ListeRowIconVoirBody $linename="display">
                            <LinkCustom to={`/dossier/complet/${sinistre.idSinistre}`}>
                                <IconVoir />
                            </LinkCustom>
                        </ListeRowIconVoirBody>

                        <ListeRowIconBody $linename="send">
                            {sinistre.dossierTransmit ? <IconSend /> : <IconNotSend />}
                        </ListeRowIconBody>
                    </BoxBodyListe>
                ))}

                <Pagination
                    elementParPage={rowsPerPage}
                    setElementParPage={setRowsPerPage}
                    page={page}
                    totalpages={Math.ceil(sinistreIncomplet.length / rowsPerPage)}
                    onClickChangePage={handleChangePage}
                />
            </BoxListe>
        );
    } else if (!loading && !sinistreIncomplet.length) {
        return (
            <BoxListe>
                <MessageNoDossier>Aucun dossier trouvé</MessageNoDossier>
            </BoxListe>
        );
    } else {
        return null;
    };
};

export default ArrayListeDossierClient;