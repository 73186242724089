import { BoxBodyDataTraitementDossier } from "./TraitementDossierStyle";
import { TraitementDossierProps } from "./TraitementDossierType";
import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";
import BtnTransmetreDossierClient from "../transmetreDossierClient/BtnTransmetreDossierClient";
import DisplaydataLre from "../dossierEstTransmis/displaydataLre/DisplaydataLre";
import LienAjouterProduitFacture from "../lienAjouterProduitFacture/LienAjouterProduitFacture";

const TraitementDossier = ({ sinistre, isTransmitted, setIsTransmitted, setSinistre }: TraitementDossierProps): JSX.Element | null => {
    return (
        <AccordionDossierCentre title="Traitement du dossier" forceOpen={true}>
            <BoxBodyDataTraitementDossier>
                <LienAjouterProduitFacture isVisible={isTransmitted} idSinistre={sinistre.id} />

                <BtnTransmetreDossierClient isVisilble={isTransmitted} idSinistre={sinistre.id} setSinistre={setSinistre} setDossierEstTransmit={setIsTransmitted} />

                <DisplaydataLre sinistre={sinistre} isVisilble={isTransmitted} />

            </BoxBodyDataTraitementDossier>
        </AccordionDossierCentre>
    );
};

export default TraitementDossier;
