import styled from "styled-components";

export const BoxTitleArticleLoi = styled.div`
    width: 100%;
    margin: 15px 0; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-evenly;
    @media screen and (max-width: 767px) {
        display: flex; 
        flex-direction: row; 
        align-items: center; 
        justify-content: space-between;
    }
`;

export const TitleTitleArticleLoi = styled.h4`
    font-family: Roboto-Regular;
    font-size: 18px;
    @media screen and (max-width: 767px) {
        font-size: 14px !important;
        margin: 0 !important;
    }
`;

export const BoxSeparatorTitleArticleLoi = styled.div`
    width: 40%; 
    height: 2px; 
    background-color: #e0e0e0;
`;