import { BoxTitleSectionParamCentre, H3ParamCentre } from "./TitleSectionParamCentreStyle";
import { TitleSectionParamCentreProps } from "./TitleSectionParamCentreType";

const TitleSectionParamCentre = ({ title }: TitleSectionParamCentreProps): JSX.Element => {
    return (
        <BoxTitleSectionParamCentre>
            <H3ParamCentre>{title}:</H3ParamCentre>
        </BoxTitleSectionParamCentre>
    );
};

export default TitleSectionParamCentre;