import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export type AccordionStyleProps = {
    $isOpen: boolean
};

export const WrapperDossierCentre = styled.div<AccordionStyleProps>`
    width: 49%;
    overflow: hidden;
    margin-bottom: 15px;
    @media screen and (min-width: 1024px) {
        width: 49%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 49%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
    
export const QuestionContainerDossierCentre = styled.button`
    width: 100%;
    text-align: left;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    color: ${theme.color.white};
    background: ${theme.color.linearGardian};
    border: none;
    cursor: pointer;
    &:hover {
       opacity: 0.8;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: ${theme.font.small};
    }
`;

export const AccordionChildrenDossierCentre = styled.div<AccordionStyleProps>`
    padding: 0 1rem;
    transition: height .3s ease-in-out, margin-top .3s ease-in-out;
    ${props => props.$isOpen ? `margin-top: 15px;` : `margin-top: 0;`}
     @media screen and (max-width: 767px) {
        padding: 0 0.5rem;
    };
    }
`;