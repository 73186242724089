import { styled } from "styled-components";

export const BoxDateKbis = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex; 
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
    }
`;

export const BoxBtnKbis = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: flex-end;
`;

export const BoxBtnAnnulekbis = styled.div`
    width: 210px;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;