//import AfficheDateDuJour from "../afficheDateDuJour/AfficheDateDuJour";
import BarreNavigation from "../barreNavigation/BarreNavigation";
import { BoxBreadcrumbDate, BoxLogoNavigation, HeaderBox } from "./HeaderStyle";
import KbisAlert from "../../../feature/expirationKbis/kbisAlert/KbisAlert";
import CompteurCredit from "../compteurCredit/CompteurCredit";
import { useEffect, useRef } from "react";
import LogoProfact from "../../logoProfact/LogoProfact";

const Header = (): JSX.Element => {

    const headerRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        headerRef.current && headerRef.current.scrollIntoView(); //{ behavior: 'smooth' }
    },[]);

    return (
        <HeaderBox ref={headerRef}>
            <BoxLogoNavigation>
                <LogoProfact/>
                
                {/*<AfficheDateDuJour />*/}
            </BoxLogoNavigation>

            <BoxBreadcrumbDate>
                <BarreNavigation />

                <CompteurCredit />
            </BoxBreadcrumbDate>

            <KbisAlert />
        </HeaderBox >
    );
};

export default Header;