import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthTest } from "../../../../contexts/authContext/AuthContext";
import { useLocation } from "react-router-dom";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import { catchError } from "../../../../core/request/catchError";
import { CallGtMotiveProps } from "./CallGtMotiveType";
import { useGtMotive } from "../../../../contexts/gtMotiveContext/GtMotiveContext";
import Button from "../../../common/button/Button";

const CallGtMotive = ({ sinistre }: CallGtMotiveProps): JSX.Element | null => {
    const { token } = useContext<AuthTest>(AuthContext).isAuthenticated;
    const location = useLocation();
    const { pushToast } = useToasts();

    const { loaderAddProduct, addUrlIframe, addIdSinistre, addIdEstimate } = useGtMotive();
    const [loading, setLoading] = useState<boolean>(false);

    const pathCurrent = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    useEffect(() => {
        setLoading(() => loaderAddProduct);
    },[loaderAddProduct]);

    const updateEstimate = () => {
        setLoading(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/gtmotive/estimation/${sinistre.id}`, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({
                immatriculation: sinistre.sinistreAssurance.immatriculationVhl,
                vin: sinistre.sinistreAssurance.numVin,
                idEstimation: sinistre.sinistreAssurance.idEstimation,
                urlCallback: pathCurrent
            })
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                if (data && data.urlEstimate) {
                    addIdSinistre(sinistre.id);
                    addIdEstimate(sinistre.sinistreAssurance.idEstimation);
                    addUrlIframe(data.urlEstimate);
                };
            })
            .catch((error: Error) => {
                addIdSinistre(null);
                addIdEstimate(null);
                addUrlIframe(null);
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    };

    return (
        <Button
            width={350}
            type="button"
            label="Sélectionner les pièces de rechange"
            color="#0288d1"
            onClick={updateEstimate}
            colorlabel="#FFFFFF"
            loader={loading}
        />
    );
};

export default CallGtMotive;