import { format } from "date-fns";
import { DisplaySinistreProps } from "./DisplaySinitreType";
import { BoxBodyDataSinistre, BoxBodySinistre, BoxInfoSinistre, DataInfoSinistre, IconCheckedInfoSinistre, IconUnCheckedInfoSinistre, LabelInfoSinistre, UlInfoSinistre } from "./DisplaySinistreStyle";
import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";

const DisplaySinistre = ({ sinistre }: DisplaySinistreProps): JSX.Element | null => {

    if (sinistre && sinistre.sinistreAssurance) {
        return (
            <AccordionDossierCentre title="Informations sinistre" forceOpen={true}>
                
                <BoxBodySinistre>
                    <BoxBodyDataSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Numéro:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.numSinistre}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Lieu:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.lieuSinistre}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Date:</LabelInfoSinistre>
                            <DataInfoSinistre>{format(new Date(sinistre.dateSinistre), 'dd/MM/yyyy')}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Circonstance:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.circonstance}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Dégat:</LabelInfoSinistre>
                            <UlInfoSinistre>
                                <li>Pare-brise{sinistre.pareBrise ? <IconCheckedInfoSinistre /> : <IconUnCheckedInfoSinistre />}</li>
                                <li>Lunette arriere{sinistre.lunetteArriere ? <IconCheckedInfoSinistre /> : <IconUnCheckedInfoSinistre />}</li>
                                <li>Toit panoramique: {sinistre.toitPanoramique ? <IconCheckedInfoSinistre /> : <IconUnCheckedInfoSinistre />}</li>
                            </UlInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Optique:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.optique ? sinistre.detailOptique : <IconUnCheckedInfoSinistre />}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Rétroviseur:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.retroviseur ? sinistre.detailRetroviseur : <IconUnCheckedInfoSinistre />}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Glace latérale:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.glaceLaterale ? sinistre.detailGlaceLaterale : <IconUnCheckedInfoSinistre />}</DataInfoSinistre>
                        </BoxInfoSinistre>

                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Calibrage Camera:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.calibrageCamera ? <IconCheckedInfoSinistre /> : <IconUnCheckedInfoSinistre />}</DataInfoSinistre>
                        </BoxInfoSinistre>
                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Réparation d'impact:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.reparationImpact ? <IconCheckedInfoSinistre /> : <IconUnCheckedInfoSinistre />}</DataInfoSinistre>
                        </BoxInfoSinistre>
                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Autre dégât:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.detailAutre}</DataInfoSinistre>
                        </BoxInfoSinistre>
                        <BoxInfoSinistre>
                            <LabelInfoSinistre>Précision:</LabelInfoSinistre>
                            <DataInfoSinistre>{sinistre.precisionPourAssurance}</DataInfoSinistre>
                        </BoxInfoSinistre>
                    </BoxBodyDataSinistre>
                </BoxBodySinistre>
            </AccordionDossierCentre>
        );
    };

    return null;
};

export default DisplaySinistre;