import SignaturePad from 'react-signature-pad-wrapper';
import { SignatureBoxPropsType } from './signatureBoxType';
import { BoxPrimarySiganture, BoxSiganture, ParagSiganture, SpanParagSiganture } from './signatureBoxStyle';
import InputError from '../inputError/InputError';
import { LegacyRef, forwardRef } from 'react';
import ButtonResetSignature from '../../buttonResetSignature/ButtonResetSignature';

const SignatureBox = forwardRef(function SignatureBox({ widthContainer, width, error, reset }: SignatureBoxPropsType, ref: LegacyRef<SignaturePad>): JSX.Element {

    return (
        <BoxPrimarySiganture $widthContainer={widthContainer}>

            <ParagSiganture>La signature doit être précédée de la mention: <SpanParagSiganture style={{ color: "crimson" }}>“lu et approuvé”</SpanParagSiganture></ParagSiganture>

            <BoxSiganture $width={width}>
                <SignaturePad ref={ref} />
            </BoxSiganture>
            <InputError error={error} />

            <ButtonResetSignature
                label="Effacer la signature"
                type="button"
                width={250}
                color="#f30000"
                colorlabel="#EEEEEE"
                onClick={reset}
            />
        </BoxPrimarySiganture>
    );
});

export default SignatureBox;