import { keyframes, styled } from "styled-components";

export const Form = styled.form`
    width: 100%;
    padding: 25px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
        padding: 25px 10px 20px 10px;
    }
`;

export const BoxLoader = styled.div`
    width: 100%;
    padding: 25px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BoxFieldFormAssure = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

export const BoxFieldPhoneAddressFormAssure = styled(BoxFieldFormAssure)`
    width: 66%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const BoxFieldBtnFormAssure = styled(BoxFieldFormAssure)`
    align-items: center;
    justify-content: flex-end;
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    animation: ${rotate} 1s linear infinite;
    &:before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 3px solid #616161;
        animation: ${prixClipFix} 2s linear infinite ;
    }
`;
