import { BoxTitleSection, H3, MentionRequire, MentionRequireSpan } from "./TitleSectionStyle";
import { TitleSectionPropsType } from "./TitleSectionType";

const TitleSection = ({ title, require = true }: TitleSectionPropsType): JSX.Element => {
    return (
        <BoxTitleSection>
            <H3>{title}:</H3>
            {require && <MentionRequire><MentionRequireSpan>*</MentionRequireSpan>Champ obligatoire</MentionRequire>}
        </BoxTitleSection>
    );
};

export default TitleSection;