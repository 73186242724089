import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxBtnModifiEmail = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex; 
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
    }
`;

export const BoxBtnAnnulModifiEmail = styled.div`
    width: 210px;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        margin-top: 15px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 15px;
        width: 100%;
    }
`;

export const ParagModifiEmail = styled.p`
    font-size: 14px; 
    color: ${theme.color.letterGray};
    & span {
        color: crimson;
    }

    @media screen and (max-width: 767px) {
       width: 100%;
     }
`;


