import { styled } from 'styled-components';
import { SignatureBoxStylePropsType } from './signatureBoxType';

export const BoxPrimarySiganture = styled.div<SignatureBoxStylePropsType>`
    width: ${props => props.$widthContainer}%;
    display: flex; 
    flex-direction: column; 
    justifyContent: flex-start;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
`;

export const BoxSiganture = styled.div<SignatureBoxStylePropsType>`
  margin-top: 5px; 
  width: ${props => props.$width}%; 
  background-color: ${props => props.theme.color.white};
  border-radius: 4px; 
  border: solid 2px ${props => props.theme.color.grayMedium};
`;

export const ParagSiganture = styled.div`
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
`;

export const SpanParagSiganture = styled.div`
  color: crimson;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;