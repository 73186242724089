import { z } from "zod";
const messageStr = { invalid_type_error: 'Le format est incorrect' };

export const regexPassword = new RegExp(/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{10,}$/);

const messageCurrentPassword = { message: 'Le mot de passe actuel n\'est pas conforme' };
const messageNewPassword = { message: 'Le nouveau mot de passe n\'est pas conforme' };
const messageConfirmPassword = { message: 'Le mot de passe de confimation n\'est pas conforme' };

export const DataPasswordSchema = z.object({
    currentPassword: z.string(messageStr).regex(regexPassword, messageCurrentPassword).optional(),
    newPassword: z.string(messageStr).regex(regexPassword, messageNewPassword).optional(),
    confirmPassword: z.string(messageStr).regex(regexPassword, messageConfirmPassword).optional(),
}).refine(data => data.newPassword === data.confirmPassword, {
    message: "Les mots de passe ne correspondent pas.",
    path: ["confirmPassword"]
});

export type DataPassword = z.infer<typeof DataPasswordSchema>;

export type DataPasswordError = {
    currentPassword: string | undefined,
    newPassword: string | undefined,
    confirmPassword: string | undefined
};