import { useEffect } from "react";
import { useCredit } from "../../../../contexts/creditcContext/CreditContext";
import { STATUS_CREDIT_BLOQUE } from "../../../../shared/constants/Constatnts";
import { BoxCredit, Credit } from "./CompteurCreditStyle";

const CompteurCredit = (): JSX.Element | null => {

    const {getCreditRestant, creditRestant, creditStatus } = useCredit();

    useEffect(() => {
        getCreditRestant();
    }, [getCreditRestant]);

    return (
        <BoxCredit $status={creditStatus}>
            
            {creditStatus === STATUS_CREDIT_BLOQUE ? 
                <Credit>Crédit épuisé</Credit>
                :
                <Credit>Crédit restant: {creditRestant}</Credit>
            }
        </BoxCredit>
    );
};

export default CompteurCredit;