import { CirconstanceSchema, CirconstanceSiAutreEtVraiSchema } from "../../pages/formCirconstance/FormCirconstanceType";
import { Circonstance } from "../../shared/class/Circonstance";
import { CirconstanceErreur } from "../../shared/class/CirconstanceErreur";

type ErreurCirconstanceType = {
    error: boolean,
    detail: CirconstanceErreur
};

export const circonstanceValidation = (data: Circonstance): ErreurCirconstanceType => {

    const erreurPage = new  CirconstanceErreur();

    const erreurCirconstanceFinal = {
        error: false,
        detail: erreurPage
    };

    const schemaValidationSiAutreEtFaux = CirconstanceSchema.safeParse(data);
    const schemaValidationSiAutreEtVrai = CirconstanceSiAutreEtVraiSchema.safeParse(data);

    if(!data.autre){
        if (!schemaValidationSiAutreEtFaux.success) {
            Object.assign(erreurCirconstanceFinal, { error: true });
    
            schemaValidationSiAutreEtFaux.error.issues.forEach(issue => {
                Object.assign(erreurPage, { [issue.path[0]]: issue.message });
            });

            return erreurCirconstanceFinal;
        };
    } else {
        if (!schemaValidationSiAutreEtVrai.success) {
            Object.assign(erreurCirconstanceFinal, { error: true });
    
            schemaValidationSiAutreEtVrai.error.issues.forEach(issue => {
                Object.assign(erreurPage, { [issue.path[0]]: issue.message });
            });
    
            return erreurCirconstanceFinal;
        };
    };
   
    return erreurCirconstanceFinal;
};