import { BoxBodyDataVhlFacture, BoxInfoVhl, BoxInfoVhlBase, DataInfoVhl, LabelInfoVhl } from "./DisplayDataFacureStyle";
import AccordionDossierCentre from "../../dossierClient/accordionDossierCentre/AccordionDossierCentre";
import { DisplayDataVhlFacureProps } from "./DisplayDataVhlFacureType";

const DisplayDataVhlFacure = ({ sinistre }: DisplayDataVhlFacureProps): JSX.Element | null => {

    if(sinistre && sinistre.sinistreAssurance){
        return (
            <AccordionDossierCentre title="Information véhicule" forceOpen={true}>
                <BoxBodyDataVhlFacture>
                        <BoxInfoVhl>
                            <LabelInfoVhl>Marque:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.marqueVhl}</DataInfoVhl>
                        </BoxInfoVhl>
    
                        <BoxInfoVhl>
                            <LabelInfoVhl>Modèle:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.modelVhl}</DataInfoVhl>
                        </BoxInfoVhl>
    
                        <BoxInfoVhl>
                            <LabelInfoVhl>Immatriculation:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.immatriculationVhl}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Numéro VIN:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.numVin}</DataInfoVhl>
                        </BoxInfoVhl>
    
                        <BoxInfoVhl>
                            <LabelInfoVhl>Mise en circulation:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.anneeMiseEnCirculationVhl}</DataInfoVhl>
                        </BoxInfoVhl>
    
                        <BoxInfoVhlBase>
                            <LabelInfoVhl>Kilkométrage:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre?.sinistreAssurance.kilometrageVhl}</DataInfoVhl>
                        </BoxInfoVhlBase>
                </BoxBodyDataVhlFacture>
            </AccordionDossierCentre>
        );
    };

    return null;
};

export default DisplayDataVhlFacure;