export class Circonstance {
    circonstance: string;
    lieuSinistre: string;
    dateSinistre: string;
    pareBrise: boolean;
    glaceLaterale: boolean;
    detailGlaceLaterale: string;
    lunetteArriere: boolean;
    optique: boolean;
    detailOptique: string;
    calibrageCamera: boolean;
    lieuSignature: string;
    dateSignature: string;
    autre: boolean;
    detailAutre: string;
    toitPanoramique: boolean;
    retroviseur: boolean;
    detailRetroviseur: string;
    reparationImpact: boolean;
    precisionPourAssurance: string;
    luEtApprouveDeclaration: boolean;

    constructor() {
        this.circonstance = '';
        this.lieuSinistre = '';
        this.dateSinistre = '';
        this.pareBrise = false;
        this.glaceLaterale = false;
        this.detailGlaceLaterale = '';
        this.lunetteArriere = false;
        this.optique = false;
        this.detailOptique = '';
        this.calibrageCamera = false;
        this.lieuSignature = '';
        this.dateSignature = '';
        this.toitPanoramique = false;
        this.retroviseur = false;
        this.detailRetroviseur = '';
        this.reparationImpact = false;
        this.autre = false;
        this.detailAutre = '';
        this.precisionPourAssurance = '';
        this.luEtApprouveDeclaration = false;
    };
};