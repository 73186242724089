import { z } from "zod";

const messageStr = { invalid_type_error: 'Le format est incorrect' };

const messageEmail = { message: 'Le format est incorrect' };

export const DataEmailSchema = z.string(messageStr).email(messageEmail);

export type DataEmail = z.infer<typeof DataEmailSchema>;

export type ChangeEmailProps = {
    email: string
};
