import { keyframes, styled } from "styled-components";
import { ButtonStylePropsType } from "./ButtonType";
import { theme } from "../../../theme/theme";

export const ButtonCustom = styled.button<ButtonStylePropsType>`
    width:  ${props => props.$width ? `${props.$width}px` : `fit-content`};
    font-size: ${props => props.theme.font.medium};
    height: 40px;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.disabled ? 
      `
        background-color: ${props.theme.color.disabledtest};
        color: ${props.$colorlabel};
        border: solid 1px ${props.theme.color.disabledtest};
        pointer-events: none;
        opacity: 0.5;
       `
       
      : 
      
      `
        background-color: ${props.$color};
        color: ${props.$colorlabel};
        border: solid 1px ${props.$color};
      `
    }
    &:hover {
      cursor: pointer;
      color: ${props => props.$color};
      background-color: transparent;
    }
    &:active {
      opacity: 0.5;
    }

    @media screen and (max-width: 767px) {
        font-size: ${theme.font.xsmall};
        height: 30px;
        width:  ${props => props.$width ? `calc(${props.$width}px - 30px)` : `fit-content`};
    }
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #FFF;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;
