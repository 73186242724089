import { ReactNode, useRef, useState } from "react";

import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { AccordionChildrenDossierCentre, QuestionContainerDossierCentre, WrapperDossierCentre } from "./AccordionDossierCentreStyle";
import TitleSectionParamCentre from "../../centreParametrage/titleSectionParamCentre/TitleSectionParamCentre";

const AccordionDossierCentre = ({ children, title, forceOpen }: { children: ReactNode, title: string , forceOpen?: boolean}): JSX.Element => {

    const contentHeight = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(forceOpen ? forceOpen : false);

    return (
        <WrapperDossierCentre $isOpen={isOpen}>
            <QuestionContainerDossierCentre onClick={() => setIsOpen((curr: boolean) => !curr)} >
                <TitleSectionParamCentre title={title} />
                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </QuestionContainerDossierCentre>

            <AccordionChildrenDossierCentre
                $isOpen={isOpen}
                ref={contentHeight}
                className="answer-container"
                style={isOpen ? { height: contentHeight.current?.scrollHeight } : { height: "0px" }}
            >
                {children}
            </AccordionChildrenDossierCentre>
        </WrapperDossierCentre>
    );
};

export default AccordionDossierCentre;