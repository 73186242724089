import { AssureIsProTrueSchema, AssureSchema } from "../../pages/formAssure/FormAssureType";
import { Assure } from "../../shared/class/Assure";
import { AssureErreur } from "../../shared/class/AssureErreur";

type ErreurAssureType = {
    error: boolean,
    detail: AssureErreur
};

export const assureValidation = (data: Assure): ErreurAssureType => {

    const erreurPage = new AssureErreur();

    const erreurAssureFinal = {
        error: false,
        detail: erreurPage
    };

    const schemaValidationIsproTrue = AssureIsProTrueSchema.safeParse(data);
    const schemaValidationIsproFalse = AssureSchema.safeParse(data);

    if(data.isPro === true){
        if (!schemaValidationIsproTrue.success) {
            Object.assign(erreurAssureFinal, { error: true });
    
            schemaValidationIsproTrue.error.issues.forEach(issue => {
                Object.assign(erreurPage, { [issue.path[0]]: issue.message });
            });
    
            return erreurAssureFinal;
        };
    }else {
        if (!schemaValidationIsproFalse.success) {
            Object.assign(erreurAssureFinal, { error: true });
    
            schemaValidationIsproFalse.error.issues.forEach(issue => {
                Object.assign(erreurPage, { [issue.path[0]]: issue.message });
            });
    
            return erreurAssureFinal;
        };
    };

    return erreurAssureFinal;
};