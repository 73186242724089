
import { House, Gear,  BoxArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const BoxBarreNavigation = styled.nav`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const LinkCustom = styled(Link)`
    width: 30px;
    height: 30px;
    color: ${props => props.theme.color.grayLight};
    font-size: ${props => props.theme.font.medium};
    font-weight: bold;
    @media screen and (max-width: 767px) {
       margin-left: 0;
    }
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.alert};
    }
`;

export const Home = styled(House)`
    color: ${props => props.theme.color.white};
    width: 30px;
    height: 30px;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.success};
    }
`;

export const UserParam = styled(Gear)`
    color: ${props => props.theme.color.white};
    width: 30px;
    height: 30px;
    @media screen and (max-width: 767px) {
        margin-left: 17.5px;
    }
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.success};
    }
`;

export const Logout = styled(BoxArrowRight)`
    color: ${props => props.theme.color.alert};
    width: 30px;
    height: 30px;
    @media screen and (max-width: 767px) {
        margin-left: 35px;
    }
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.color.success};
    }
`;