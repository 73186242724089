import jwtDecode from 'jwt-decode';
import { z } from 'zod';

export const DecodedTokenSchema = z.object({
    exp: z.number(),
    iat: z.number(),
    id: z.number(),
    centreNom: z.string(),
    username: z.string(),
    kbisEdition: z.string(),
    kbisAlerte: z.string(),
    kbisExpire: z.string(),
    creditAbonnement:z.number(),
    creditConsoDuMois: z.number(),
    emailConfirme: z.boolean(),
    roles: z.string().array(),
    isActif: z.boolean()
});

export type DecodedToken = z.infer<typeof DecodedTokenSchema>;

export type DataAuthToken = {
    connected: boolean;
    id: number | undefined;
    username: string | undefined;
    centreNom: string | undefined;
    role: string | undefined;
    kbisEdition: string | undefined;
    kbisAlerte: string | undefined;
    kbisExpire: string | undefined;
    creditAbonnement: number;
    creditConsoDuMois: number;
    emailConfirme: boolean;
    isActif: boolean;
    exp: number,
    token: string | undefined;
    refresh_token: string | undefined;
};

// passer centreNom en nomSociete

export const validStorage = (token: string | null, refreshToken: string | null): DataAuthToken => {

    if (token !== null && refreshToken !== null) {
        try {
            const decodeToken: DecodedToken = jwtDecode(token);

            const dataUser = {
                connected: true,
                id: decodeToken.id,
                username: decodeToken.username,
                role: decodeToken.roles[0],
                exp: decodeToken.exp,
                centreNom: decodeToken.centreNom,
                kbisEdition: decodeToken.kbisEdition,
                kbisAlerte: decodeToken.kbisAlerte,
                kbisExpire: decodeToken.kbisExpire,
                creditAbonnement: decodeToken.creditAbonnement,
                creditConsoDuMois: decodeToken.creditConsoDuMois,
                emailConfirme: decodeToken.emailConfirme,
                isActif: decodeToken.isActif,
                token: token,
                refresh_token: refreshToken
            };

            return dataUser;
        } catch (error) {
            const dataUser = {
                connected: false,
                id: undefined,
                username: undefined,
                centreNom: undefined,
                role: undefined,
                kbisEdition: undefined,
                kbisAlerte: undefined,
                kbisExpire: undefined,
                creditAbonnement: 0,
                creditConsoDuMois: 0,
                emailConfirme: false,
                isActif: false,
                exp: 0,
                token: undefined,
                refresh_token: undefined
            };

            return dataUser;
        };
    } else {
        const dataUser = {
            connected: false,
            id: undefined,
            username: undefined,
            centreNom: undefined,
            role: undefined,
            kbisEdition: undefined,
            kbisAlerte: undefined,
            kbisExpire: undefined,
            creditAbonnement: 0,
            creditConsoDuMois: 0,
            emailConfirme: false,
            isActif: false,
            exp: 0,
            token: undefined,
            refresh_token: undefined
        };

        return dataUser;
    };
};