import { format } from "date-fns";
import { AncreLienDocumentLre, BoxInfoLre, BoxLre, DataInfoLre, LabelInfoLre } from "./DisplaydataLreStyle";
import { DisplaydataLreProps } from "./DisplaydataLreType";

const DisplaydataLre = ({ sinistre, isVisilble }: DisplaydataLreProps): JSX.Element | null => {
    if (isVisilble === true) {
        return (
            <BoxLre>
                <BoxInfoLre>
                    <LabelInfoLre>Référence d'envoi:</LabelInfoLre>
                    <DataInfoLre>{sinistre.referenceLre}</DataInfoLre>
                </BoxInfoLre>

                <BoxInfoLre>
                    <LabelInfoLre>date d'envoi:</LabelInfoLre>
                    <DataInfoLre>{sinistre.dateDossierTransmit && format(new Date(sinistre.dateDossierTransmit), 'dd/MM/yyyy')}</DataInfoLre>
                </BoxInfoLre>

                <AncreLienDocumentLre href={sinistre.documentSuiviLre || ''} download={sinistre.documentSuiviLreName} target="_blank">Preuve d'envoi</AncreLienDocumentLre>
            </BoxLre>
        );
    };

    return null;
};

export default DisplaydataLre;