import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { FormEvent, useEffect, useState } from "react";
import LogoLogin from "../../components/feature/login/logoLogin/LogoLogin";
import { BasePageResetPassword, CardResetPassword, ErrorResetPassword, FormulaireResetPassword, HeadListeResetPassword, ItemListeResetPassword, ListeResetPassword } from "./ReinitialiserMotDePasseStyle";
import BoxField from "../../components/common/boxSection/BoxField";
import InputLogin from "../../components/feature/login/inputLogin/InputLogin";
import ButtonLogin from "../../components/feature/login/buttonLogin/ButtonLogin";
import { catchError } from "../../core/request/catchError";

const ReinitialiserMotDePasse = (): JSX.Element => {
    const { userId, token } = useParams();
    const navigate = useNavigate();
    const { pushToast } = useToasts();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [erreur, setErreur] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!userId || !token) {
            navigate('/connexion');
            return;
        };
    }, [userId, token, navigate]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (email === '' || password === '' || confirmPassword === '') {
            setErreur(() => "Une donnée pour traiter votre demande est manquante");
            return;
        };

        if (password !== confirmPassword) {
            setErreur(() => "Les 2 mots de passe doivent être identiques");
            return;
        };

        const changePassword = {
            token: token,
            email: email,
            password: password,
            confirmPassword: confirmPassword
        };

        setLoading(() => true);

        userId && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/password/reset/${userId}`, {
            method: 'POST',
            body: JSON.stringify(changePassword)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                pushToast({ content: data.detail, type: 'success', duration: 5 });
                navigate('/connexion');
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setEmail(() => '');
                setPassword(() => '');
                setConfirmPassword(() => '');
                setLoading(() => false);
            });
    };

    return (
        <BasePageResetPassword>
            <CardResetPassword>
                
                <LogoLogin />

                <FormulaireResetPassword onSubmit={onSubmit}>

                    <BoxField width={100} mt={1} alignItems="center" justifyContent="center" flexDirection="column">
                        <InputLogin
                            placeholder="Adresse e-mail du compte utilisateur"
                            id="adresseEmail"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <InputLogin
                            placeholder="Nouveau mot de passe"
                            id="newPassword"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <InputLogin
                            placeholder="Confirmé le nouveau mot de passe"
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />

                        <HeadListeResetPassword>Le mot de passe doit 10 caractères minimums dont au moins:</HeadListeResetPassword>

                        <ListeResetPassword>
                            <ItemListeResetPassword>1 majuscule</ItemListeResetPassword>
                            <ItemListeResetPassword>1 minuscule</ItemListeResetPassword>
                            <ItemListeResetPassword>1 chiffre</ItemListeResetPassword>
                            <ItemListeResetPassword>1 caractère spécial (@!&...)</ItemListeResetPassword>
                        </ListeResetPassword>

                        <ButtonLogin
                            label="Réinitialiser mon mot de passe"
                            type="submit"
                            loader={loading}
                        />

                        <ErrorResetPassword>{erreur}</ErrorResetPassword>
                    </BoxField>
                </FormulaireResetPassword>
            </CardResetPassword>
        </BasePageResetPassword>
    );
};

export default ReinitialiserMotDePasse;