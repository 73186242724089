import { useNavigate } from "react-router-dom";
import LogoLogin from "../../components/feature/login/logoLogin/LogoLogin";
import ButtonLogin from "../../components/feature/login/buttonLogin/ButtonLogin";
import { BasePageActive, CardActive, HeadingActive } from "./ConfirmActivationStyle";

const ConfirmActivation = () => {

    const navigate = useNavigate();

    return (
        <BasePageActive>
            <CardActive>
                <LogoLogin />

                <HeadingActive>Félicitation votre compte est activé</HeadingActive>

                <ButtonLogin
                    label="Me connecter"
                    type="button"
                    loader={false}
                    onClick={() => navigate('/connexion')}
                />
            </CardActive>
        </BasePageActive>
    );
};

export default ConfirmActivation;