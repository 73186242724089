export class DocumentsErreur {
    carteGriseFile: string;
    carteVerteFile: string;
    controleTechniqueFile: string;
    photosVehiculeFile: string;
    permisConduireFile: string;
    bonLivraisonFile: string;

    constructor() {
        this.carteGriseFile = '';
        this.carteVerteFile = '';
        this.controleTechniqueFile = '';
        this.photosVehiculeFile = '';
        this.permisConduireFile = '';
        this.bonLivraisonFile = '';
    };
};