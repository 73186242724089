import { RetourApiErreurSchema } from "../../types/RetourApiErreur";

export const catchError = (response: any): void => {
    if (RetourApiErreurSchema.safeParse(response).success) {
        throw Error(response.message);
    } else {
        if (response.status) {
            switch (response.status) {
                case 400:
                    throw Error(`Le serveur ne peut pas traiter la requête, la syntaxe ou le contenu est invalide`);
                case 401:
                    throw Error(`La requête n'a pas été autorisée, authentification invalide`);
                case 403:
                    throw Error(`L'accès a cette ressource n'est pas autorisé`);
                case 404:
                    throw Error(`Le serveur ne trouve pas la ressource demandée`);
                case 405:
                    throw Error(`La méthode utilisée pour la requête est incorrecte`);
                case 500:
                    throw Error(`Le serveur a rencontré un problème inattendu qui l'empêche de répondre à la requête`);
            };
        };
    };
};