import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { BoxTextareaCustomStylePropsType } from "./TextareaCustomType";

export const BoxTextareaCustom = styled.div<BoxTextareaCustomStylePropsType>`
    width: ${props => props.$width ? `${props.$width}%` : `100%`};
    display: flex; 
    flex-direction: column; 
    alignItems: flex-start;
`;

export const Textarea = styled.textarea<BoxTextareaCustomStylePropsType>`
    padding: 10px 15px;
    background-color: ${theme.color.white};
    font-size: ${theme.font.large};
    border-radius: 3px;
    border: 2px solid ${theme.color.grayMedium};
    font-family: Roboto-Regular;
    color: ${theme.color.gray};
    resize : none;
    &:focus {
        outline: none;
        border: 2px solid ${theme.color.gray};
    }
    &: invalid {
        border: 2px solid ${theme.color.alert};
        color: ${theme.color.alert};
    }
`;