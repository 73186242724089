import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxActionDossierClient = styled.div`
    width: 49%;
    height: 105px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    overflow: hidden;
    &:hover{
      cursor: pointer;
      opacity: 0.6;
    } 
    &:active{
      scale: 0.9;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: fit-content;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;

export const LinkFacture = styled(Link)`
    width: 320px;
    padding: 2px 0;
    border-radius: 3px;
    text-align: center;
    font-size: ${theme.font.medium};
    color: ${theme.color.white};
    background-color: ${theme.color.info};
    transition: opacity 0.2s, scale 0.2s;
    &:hover{
      cursor: pointer;
      opacity: 0.6;
    } 
    &:active{
      scale: 0.9;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
`;
