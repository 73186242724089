export const produitcalculPrixUnitaireHT = (prixUnitaireHT: string, quantite: string, tva: string,): string => {
    if (!prixUnitaireHT || !tva || !quantite) return '';
    if (typeof prixUnitaireHT !== "string" || typeof tva !== "string" || typeof quantite !== "string") return '';

    const tarif = ((Number(prixUnitaireHT) * Number(quantite)) / 100) * Number(tva) + Number(prixUnitaireHT) * Number(quantite);

    return tarif.toFixed(2);
};






