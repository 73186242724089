import { AssuranceSchema } from "../../pages/formAssurance/FormAssuranceType";
import { Assurance } from "../../shared/class/Assurance";
import { AssuranceErreur } from "../../shared/class/AssuranceErreur";

type ErreurAssuranceType = {
    error: boolean,
    detail: AssuranceErreur
};

export const assuranceValidation = (data: Assurance): ErreurAssuranceType => {

    const erreurAssurance = new AssuranceErreur();

    const erreurAssuranceFinal = {
        error: false,
        detail: erreurAssurance 
    };

    const schemaValidation = AssuranceSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurAssuranceFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurAssurance, { [issue.path[0]]: issue.message });
        });

        return erreurAssuranceFinal;
    };

    return erreurAssuranceFinal;
};