import { z } from "zod";

export { };

export const SinistreRetourApiSchema = z.object({
    id: z.number(),
    circonstance: z.string(),
    lieuSinistre: z.string(),
    dateSinistre: z.string(),
    pareBrise: z.boolean(),
    glaceLaterale: z.boolean(),
    lunetteArriere: z.boolean(),
    optique: z.boolean(),
    detailOptique: z.string().or(z.literal('')),
    calibrageCamera: z.boolean(),
    createdAt: z.string(),
    sinistreAssure: z.object({
        id: z.number(),
        nomSociete: z.string().nullable(),
        numSiret: z.string().nullable(),
        civilite: z.string(),
        nom: z.string(),
        prenom: z.string(),
        adresse: z.string(),
        codePostal: z.string(),
        ville: z.string(),
        telephone: z.string(),
        email: z.union([z.string().email(), z.literal('')])
    }),
    sinistreAssurance: z.object({
        nomSociete: z.string(),
        adresse: z.string(),
        codePostal: z.string(),
        ville: z.string(),
        numContrat: z.string(),
        email: z.string(),
        marqueVhl: z.string(),
        modelVhl: z.string(),
        immatriculationVhl: z.string(),
        anneeMiseEnCirculationVhl: z.string(),
        kilometrageVhl: z.string(),
        referenceSinistreAssurance: z.union([z.string(), z.literal('')]),	
        chiffrageAssurance: z.union([z.string(), z.literal('')]),
        montantFranchise: z.string(),
    }),
    sinistreFacture: z.object({
        id: z.number(),
        idFacture: z.number(),
        status: z.string(),
        dateEditionIncomplete: z.string()
    })
});

export const ProduitSchema = z.object({
    name: z.string().min(1),
    tax: z.string().min(1),
    total_price_gross: z.string().min(1),
    quantity: z.string().min(1),
});

export const TableProduitSchema = ProduitSchema.array();

export type Produit = z.infer<typeof ProduitSchema>;
export type SinistreRetourApi = z.infer<typeof SinistreRetourApiSchema>;