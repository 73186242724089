import { styled } from "styled-components";

export const FormCirconstanceCustom = styled.form`
  width: 100%;
  padding: 25px 25px 40px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding: 25px 10px 20px 10px;
  }
`;

export const BoxFieldFormCirconstance = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    height: auto;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const BoxFieldCheckBoxFormCirconstance = styled(BoxFieldFormCirconstance)`
  margin-top: 0;
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
`;

export const BoxFieldCheckBoxAutreFormCirconstance = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BoxFieldBtnFormCirconstance = styled(BoxFieldFormCirconstance)`
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CheckboxContainerTop = styled.div `
  margin-top: 25px;
  width: 100%;
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
`;

export const CheckboxContainerBottom = styled(CheckboxContainerTop)`
  margin-top: 0;
  @media screen and (max-width: 767px) {
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
  }
`;

export const BoxCheckBoxCirconstanceSignature = styled.div`
    width: 55%;
    margin-top: 25px;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 10px;
    }
`;

export const CheckboxContainer = styled.div`
  width: 49%; 
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start;
`;


