import InputError from "../inputError/InputError";
import LabelCustom from "../labelCustom/LabelCustom";
import { BoxInputCustom, SelectCustom } from "./SelectStyle";
import { SelectPropsType } from "./SelectType";

const Select = ({ width, label, value, id, onChange, error, require = true }: SelectPropsType): JSX.Element => {
    return (
        <BoxInputCustom $width={width}>
            <LabelCustom htmlFor={id} label={label} require={require}/>
            <SelectCustom id={id} name={id} value={value} onChange={onChange}>
                <option value="Projection de gravillons">Projection de gravillons</option>
                <option value="Autre">Autre</option>
            </SelectCustom>
            <InputError error={error}/>
        </BoxInputCustom>
    );
};

export default Select;