import { z } from 'zod';

const messageStr = { invalid_type_error: 'Le format est incorrect' };
const messageRegex = { message: 'Le format est incorrect' };

const regexKilometrage = new RegExp('.*[0-9].*');
const regexAnneeMiseEnCirculation = new RegExp('[0-9]{4}');
const emailRegex = new RegExp('[^@]+@[^@]+.[^@]+$');

export const AssuranceSchema = z.object({
  nomSociete: z.string(messageStr).min(1, { message: 'Le nom de la compagnie d\'assurance est obligatoire' }),
  service: z.union([z.string(messageStr), z.literal('')]).nullable(),
  email: z.string(messageStr).regex(emailRegex, { message: 'Le format de l\'adresse e-mail est invalide' }),
  adresse: z.string(messageStr).min(1, { message: 'L\'adresse de la compagnie d\'assurance est obligatoire' }),
  complementAdresse: z.union([z.string(messageStr), z.literal('')]).nullable(),
  codePostal: z.string(messageStr).min(1, { message: 'Le code postal de la compagnie d\'assurance est obligatoire' }),
  ville: z.string(messageStr).min(1, { message: 'La ville de la compagnie d\'assurance est obligatoire' }),
  numContrat: z.string(messageStr).min(1, { message: 'Le numéro de contrat ou de sociétaire est obligatoire' }),
  marqueVhl: z.string(messageStr).min(1, { message: 'La marque du véhicule est obligatoire' }),
  modelVhl: z.string(messageStr).min(1, { message: 'Le modéle de véhicule est obligatoire' }),
  immatriculationVhl: z.string(messageStr).min(1, { message: 'L\'immatriculation est obligatoire' }),
  anneeMiseEnCirculationVhl: z.string(messageStr).regex(regexKilometrage, messageRegex).min(4, { message: 'L\'année de mise en circulation est obligatoire' }).max(4, { message: 'L\'année doit contenir au maximum 4 caractère(s)' }),
  kilometrageVhl: z.string().regex(regexAnneeMiseEnCirculation, messageRegex).min(1, { message: 'Le kilométrage du véhicule est obligatoire' }),
  numVin: z.string().min(1, { message: 'Le numéro VIN du véhicule est obligatoire' }),
  chiffrageAssurance: z.union([z.string(messageStr), z.literal('')]),
  referenceSinistreAssurance: z.union([z.string(messageStr), z.literal('')]),
  montantFranchise: z.union([z.string(messageStr), z.literal('')])
});

export const AssuranceUpdateSchema = z.object({
  nomSociete: z.string(),
  service: z.string(),
  email: z.string(),
  adresse: z.string(),
  complementAdresse: z.string(),
  codePostal: z.string(),
  ville: z.string(),
  numContrat: z.string(),
  marqueVhl: z.string(),
  modelVhl: z.string(),
  immatriculationVhl: z.string(),
  anneeMiseEnCirculationVhl: z.string(),
  kilometrageVhl: z.string(),
  numVin: z.string(),
  chiffrageAssurance: z.string(),
  referenceSinistreAssurance: z.string(),
  montantFranchise: z.string()
});

export type AssuranceType = z.infer<typeof AssuranceSchema>;
export type AssuranceUpdateType = z.infer<typeof AssuranceUpdateSchema>;