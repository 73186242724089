import { FormEvent, useContext, useEffect, useState } from "react";
import { catchError } from "../../../../core/request/catchError";
import Accordion from "../../../common/accordion/Accordion";
import Button from "../../../common/button/Button";
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import   { AuthContext, AuthTest }  from '../../../../contexts/authContext/AuthContext';
import { ChangeEmailProps, DataEmail, DataEmailSchema } from "./ChangeEmailType";
import { BoxBtnAnnulModifiEmail, BoxBtnModifiEmail, ParagModifiEmail } from "./ChangeEmailStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";

const ChangeEmail = ({ email }: ChangeEmailProps): JSX.Element => {

    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;
    const { logout } = useContext<AuthTest>(AuthContext);
    const { pushToast } = useToasts();

    const [adresseEmail, setAdresseEmail] = useState<DataEmail>('');
    const [error, setError] = useState<string>('');

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);
    
    useEffect(() => {
        setAdresseEmail(() => email ? email : '');
    }, [email]);

    const annule = () => {
        setAdresseEmail(() => email ? email : '');
        setError(() => '');
        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        setError(() => '');

        if(!adresseEmail.length){
            setError(() => 'Une adresse email est requise');
            return;
        };

        if (!DataEmailSchema.safeParse(adresseEmail).success) {
            setError(() => 'Une adresse email n\'est pas conforme');
            return;
        };

        if(adresseEmail === email){
            setError(() => 'Cette adresse email est identique a adresse email actuel');
            return;
        };

        setBtnIsDisabled(() => true);
        setSubmitLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/email/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ email: adresseEmail })
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: "L'adresse email a été mise à jour", type: 'success', duration: 5 });

                setTimeout(() => {
                    logout();
                }, 2000);
            })
            .catch((error: Error) => {
                setError(() => error.message);
            })
            .finally(() => {
                setSubmitLoader(() => false);
            });
    };

    return (
        <Accordion title="E-mail">
            <form onSubmit={submitData}>

                <InputCustom
                    width={48.5}
                    label="Adresse email du centre"
                    id="email"
                    type="email"
                    value={adresseEmail}
                    onChange={(e) => setAdresseEmail(() => {
                        setBtnIsDisabled(() => false);
                       return e.target.value;
                    })}
                    require={false}
                    error={error}
                />

                <BoxBtnModifiEmail>

                    <ParagModifiEmail><span>*</span>Après la mise à jour de l'adresse email,<br/>&nbsp;&nbsp;vous serez redirigé sur la page d'authentification</ParagModifiEmail>

                    <BoxBtnAnnulModifiEmail>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnulModifiEmail>
                </BoxBtnModifiEmail>
            </form>
        </Accordion>
    );
};

export default ChangeEmail;