import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import Toast from "../toast/Toast";
import { Container } from "./ToastContainerStyle";
import { ToastContext } from "../../../../contexts/toastContext/toastContext";
import { ParamsToastRef, ToastItem } from "../../../../contexts/toastContext/toastContextType";

const ToastContainer = () => {

    const { pushToastRef } = useContext(ToastContext);
    const [toasts, setToasts] = useState<ToastItem[]>([]);

    pushToastRef.current = ({ duration, ...props }: ParamsToastRef) => {

        const toast = {...props, id: Date.now()};

        setToasts((v) => [...v, toast]);

        if (duration) {
            setTimeout(() => {
                setToasts((v) => v.filter((t) => t !== toast));
            }, duration * 1000);
        };
    };

    const removeToast = (toast: ToastItem) => {
        setToasts((v) => v.filter((t) => t !== toast));
    };

    return createPortal(
        <Container>
            {toasts.map((toast) => (
                <Toast {...toast} onClick={() => removeToast(toast)} key={toast.id} />
            ))}
        </Container>
        , document.body);
};

export default ToastContainer;