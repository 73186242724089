export class CirconstanceErreur {
    circonstance: string;
    lieuSinistre: string;
    dateSinistre: string;
    pareBrise: string;
    glaceLateral: string;
    detailGlaceLaterale: string;
    lunetteArriere: string;
    optique: string;
    detailOptique: string;
    calibrageCamera: string;
    autre: string;
    detailAutre: string;
    toitPanoramique: string;
    retroviseur: string;
    detailRetroviseur: string;
    reparationImpact: string;
    precisionPourAssurance: string;
    lieuSignature: string;
    dateSignature: string;

    constructor() {
        this.circonstance = '';
        this.lieuSinistre = '';
        this.dateSinistre = '';
        this.pareBrise = '';
        this.glaceLateral = '';
        this.detailGlaceLaterale = '';
        this.lunetteArriere = '';
        this.optique = '';
        this.detailOptique = '';
        this.calibrageCamera = '';
        this.autre = '';
        this.detailAutre = '';
        this.toitPanoramique = '';
        this.retroviseur = '';
        this.detailRetroviseur = '';
        this.reparationImpact = '';
        this.precisionPourAssurance = '';
        this.lieuSignature = '';
        this.dateSignature = '';
    };
};