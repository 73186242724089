import LabelCustom from "../labelCustom/LabelCustom";
import { CheckboxPropsType } from "./CheckboxType";
import { BoxCheckbox, InputCheckbox } from "./Checkboxstyle";

const Checkbox = ({ width, mb, reverse = false, label, id, name, checked, onChange, require = false }: CheckboxPropsType): JSX.Element => {
    return (
        <BoxCheckbox $width={width} $mb={mb} $reverse={reverse}>
            <LabelCustom htmlFor={id} label={label} require={require} />
            <InputCheckbox id={id} $reverse={reverse} name={name} checked={checked} onChange={onChange} type="checkbox" />
        </BoxCheckbox>
    );
};

export default Checkbox;