import { ToastProps } from "./ToastType";
import { IconAlert, IconClose, IconSuccess, MessageToast, ToastContent } from "./ToastStyle";

const IconToast = ({ type }: { type: "success" | "alert" | "regular" }): JSX.Element => {
    if (type === "success") {
        return <IconSuccess />
    };

    if (type === "alert") {
        return <IconAlert />
    };

    if (type === "regular") {
        return <IconAlert />
    };

    return <IconSuccess />
};

const Toast = ({ content, type = "regular", onClick }: ToastProps) => {
    return (
        <ToastContent $type={type}>
            <IconToast type={type} />

            <MessageToast>{content}</MessageToast>

            <IconClose onClick={onClick} />
        </ToastContent>
    );
};

export default Toast;

