import { ReactNode } from "react";
import { z } from "zod";

export type AuthProviderType = {
    children: ReactNode
};

export type Auth = {
    connected: boolean;
    id: number | undefined;
    centreNom: string | undefined;
    role: string | undefined;
    kbisEdition: string | undefined;
    kbisAlerte: string | undefined;
    kbisExpire: string | undefined;
    token: string | undefined;
    refresh_token: string | undefined;
    //addDateKbis: (datesKbis: DateKbisType) => void
    //addUser: (newData: DataAuth) => void,
    //logout: () => void
};

export type AccessToken = {
    token: string,
    refresh_token: string,
}

export type KbisContextType = {
    datesKbis: DateKbisType,
    addDateKbis: (datesKbis: DateKbisType) => void
};

export const DateKbisSchema = z.object({
    kbisEdition: z.string().optional(),
    kbisAlerte: z.string().optional(),
    kbisExpire: z.string().optional()
});

export type DateKbisType = z.infer<typeof DateKbisSchema>;