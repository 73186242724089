import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const LinkFactureTraitementDossier = styled(Link)`
    width: 90%;
    padding: 2px 0;
    border-radius: 3px;
    text-align: center;
    font-size: ${theme.font.medium};
    color: ${theme.color.white};
    background-color: ${theme.color.info};
    transition: opacity 0.2s, scale 0.2s;
    &: hover{
        opacity: 0.6;
    }
    &: active {
        scale: 0.9;
    }
`;
