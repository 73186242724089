import { z } from "zod";

const messageStr = { invalid_type_error: 'Le format est incorrect' };

export const DataDirigeantSchema = z.object({
    nomDirigeant: z.union([z.string(messageStr).min(1, { message: 'Le nom du dirigeant du centre obligatoire' }), z.literal('')]),
    prenomDirigeant: z.union([z.string(messageStr).min(1, { message: 'Le prénom du dirigeant du centre est obligatoire' }), z.literal('')]),
});

export type DataDirigeant = z.infer<typeof DataDirigeantSchema>;

export type DataDirigeantError = {
    nomDirigeant: string | undefined,
    prenomDirigeant: string | undefined
};