import { useNavigate } from "react-router-dom";
import { FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from '../formAssure/FormAssureStyle';
import TitleSection from '../../components/common/titleSection/TitleSection';
import Button from '../../components/common/button/Button';
import Header from '../../components/common/header/header/Header';
import BasePage from "../../components/common/basePage/BasePage";
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';
import { RetourPdfAnnexeSchema, RetourPdfAnnexeType } from "../../core/validation/retourPdfSchema";
import { SinistreContext } from "../../contexts/sinistreContext/SinistreContext";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { documentsValidation } from "../../core/validation/documentsValidation";
import { DocumentsErreur } from "../../shared/class/DocumentsErreur";
import InputFileCustom from "../../components/common/field/inputFileCustom/InputFileCustom";
import InputFileVhl from "../../components/common/field/inputFileVhl/InputFileVhl";
import { BoxBtnFinaliseFormDocumentAnnexe, BoxBtnFormDocumentAnnexe, BoxFieldDocumentAnnexe, BoxFieldFormDocumentAnnexe, BoxWarning, FormDocumentAnnexeParag } from "./FormDocumentAnnexeStyle";
import { catchError } from "../../core/request/catchError";
import { NAME_STORAGE_SINISTRE } from "../../shared/constants/Constatnts";
import { useCredit } from "../../contexts/creditcContext/CreditContext";


export default function FormDocumentAnnexe() {
  const navigate = useNavigate();
  const { pushToast } = useToasts();

  const { id, token } = useContext<AuthTest>(AuthContext).isAuthenticated;
  const { dataSinistre } = useContext(SinistreContext);
  const { getCreditRestant } = useCredit();

  const [loading, setLoading] = useState<boolean>(false);

  const [carteVerteFile, setCarteVerteFile] = useState<File>();
  const [photosVehiculeFile, setPhotosVehiculeFile] = useState<File[]>([]);
  const [carteGriseFile, setCarteGriseFile] = useState<File>();
  const [controleTechniqueFile, setControleTechniqueFile] = useState<File>();
  const [permisConduireFile, setPermisConduireFile] = useState<File>();
  const [bonLivraisonFile, setBonLivraisonFile] = useState<File>();

  const [docAnnexeIsCreated, setDocAnnexeIsCreated] = useState<boolean>(false);

  const [fileError, setFileError] = useState<DocumentsErreur>(new DocumentsErreur());

  useEffect(() => {
    !sessionStorage.getItem(NAME_STORAGE_SINISTRE) && navigate('/');
  }, [navigate]);

  const onSubmit = async (e: FormEvent) => {

    e.preventDefault();

    const isValide = documentsValidation(carteVerteFile, carteGriseFile, controleTechniqueFile, permisConduireFile, bonLivraisonFile);

    setFileError({ ...fileError, ...isValide.detail });

    if (!photosVehiculeFile?.length) {
      setFileError((curr: DocumentsErreur) => {
        return { ...curr, photosVehiculeFile: "Au moins 1 photo du véhicule est demandé" };
      })
    };

    if (isValide.error) {
      return;
    };

    const formdata = new FormData();
    carteGriseFile && formdata.append('carteGrise', carteGriseFile);
    carteVerteFile && formdata.append('carteVerte', carteVerteFile);
    controleTechniqueFile && formdata.append('controlTechnique', controleTechniqueFile);
    permisConduireFile && formdata.append('permisConduire', permisConduireFile);
    bonLivraisonFile && formdata.append('bonLivraison', bonLivraisonFile);

    photosVehiculeFile?.length && photosVehiculeFile.forEach((file, index) => {
      formdata.append(`photoVhl${index}`, file);
    });

    setLoading(() => true);

    token && dataSinistre.idSinistre && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/document/annexe/${dataSinistre.idSinistre}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` },
      body: formdata
    })
      .then((response) => {
        return response.json();
      })
      .then((data: RetourPdfAnnexeType) => {

        catchError(data);

        if (!RetourPdfAnnexeSchema.safeParse(data).success) {
          pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
          return;
        };

        pushToast({ content: "Le document a bien été créé", type: 'success', duration: 5 });

        getCreditRestant();

        setDocAnnexeIsCreated(() => true);
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  const createFacture = async () => {

    setLoading(() => true);

    token && id && dataSinistre.idSinistre && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/facture/${dataSinistre.idSinistre}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        catchError(data);

        setTimeout(() => {
          pushToast({ content: "Le dossier est complet", type: 'success', duration: 5 });
          sessionStorage.removeItem(NAME_STORAGE_SINISTRE);
          navigate(`/dossier/complet/${dataSinistre.idSinistre}`);
          setLoading(() => false);
        }, 5000);
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
        setLoading(() => false);
      });
  };

  return (
    <BasePage>
      <Header />

      {!docAnnexeIsCreated && <Form onSubmit={onSubmit}>

        <TitleSection title="Documents et prise de photos" />

        <BoxFieldFormDocumentAnnexe>

          <BoxWarning>
            <p>Attention à l'orientation de vos photos pour garder une bonne lisibilité une fois intégrée</p>
            <a href={`${process.env.PUBLIC_URL}/exempleDoc/DOC_ANNEXE_EXEMPLE.pdf`} target="_blank" rel="noreferrer">Voir un exemple de document</a>
          </BoxWarning>

          <BoxFieldDocumentAnnexe>
            <InputFileCustom
              file={carteVerteFile}
              setFile={setCarteVerteFile}
              label="Carte verte (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.carteVerteFile}
              nameError="carteVerteFile"
              require={true}
            />

            <InputFileVhl
              setFile={setPhotosVehiculeFile}
              label="Photos du véhicule (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.photosVehiculeFile}
              nameError="photosVehiculeFile"
              require={true}
            />

            <InputFileCustom
              file={carteGriseFile}
              setFile={setCarteGriseFile}
              label="Carte grise (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.carteGriseFile}
              nameError="carteGriseFile"
            />

            <InputFileCustom
              file={controleTechniqueFile}
              setFile={setControleTechniqueFile}
              label="Contrôle technique (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.controleTechniqueFile}
              nameError="controleTechniqueFile"
            />

            <InputFileCustom
              file={permisConduireFile}
              setFile={setPermisConduireFile}
              label="Permis de conduire (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.permisConduireFile}
              nameError="permisConduireFile"
            />

            <InputFileCustom
              file={bonLivraisonFile}
              setFile={setBonLivraisonFile}
              label="Bon de livraison (format .png ou .jpeg)"
              setError={setFileError}
              error={fileError.bonLivraisonFile}
              nameError="bonLivraisonFile"
            />
          </BoxFieldDocumentAnnexe>
        </BoxFieldFormDocumentAnnexe>

        <BoxBtnFormDocumentAnnexe>
          <Button
            label="Créer le document"
            type="submit"
            width={200}
            color="#0288d1"
            colorlabel="#EEEEEE"
            loader={loading}
          />
        </BoxBtnFormDocumentAnnexe>
      </Form>}

      {
        docAnnexeIsCreated &&
        <BoxBtnFinaliseFormDocumentAnnexe>

          <FormDocumentAnnexeParag>Tous les documents créés et enregistrés</FormDocumentAnnexeParag>

          <Button
            label="Finaliser le dossier"
            type="button"
            onClick={createFacture}
            width={230}
            color="#0288d1"
            colorlabel="#EEEEEE"
            loader={loading}
          />
        </BoxBtnFinaliseFormDocumentAnnexe>
      }

    </BasePage >
  );
};

