import { useContext } from "react";
import { Navigate } from 'react-router-dom';
import { AuthContext, AuthTest } from "../../../../contexts/authContext/AuthContext";

const ROLE_UTILISER = "ROLE_UTILISER";

const AuthRoute = ({ Component, isAuth = true }: { Component: any, isAuth?: boolean }) => {

    const { connected, role, isActif } = useContext<AuthTest>(AuthContext).isAuthenticated;

    if (isAuth === false) {
        return <Component />;
    };

    if (isAuth === true) {
        return (connected && isActif && role === ROLE_UTILISER) ? <Component /> : <Navigate to='/connexion' />
    };

    return <></>
};

export default AuthRoute; 
