import { ButtonLoginCustom, Loader } from "./ButtonLoginStyle";
import { ButtonLoginPropsType } from "./ButtonLoginType";

const ButtonLogin = ({  type, label, loader = true, disabled, onClick }: ButtonLoginPropsType) => {
    return (
        <ButtonLoginCustom
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {loader ? <Loader /> : label}

        </ButtonLoginCustom> 
    );
};

export default ButtonLogin;