import { AncreLienDocument, BoxBodyDataLienDocument, BoxBodyLienDocument } from "./LienDocumentStyle";
import { LienDocumentProps } from "./LienDocumentStyleType";
import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";

const LienDocument = ({ sinistre }: LienDocumentProps): JSX.Element | null => {
    return (
        <AccordionDossierCentre title="Documents" forceOpen={true}>
            <BoxBodyLienDocument>
                <BoxBodyDataLienDocument>
                    <AncreLienDocument href={sinistre.documentContratBase64 || ''} download={sinistre.nomDocumentContrat} target="_blank">Contrat</AncreLienDocument>
                    <AncreLienDocument href={sinistre.documentAnnexeBase64 || ''} download={sinistre.nomDocumentAnnexe} target="_blank">Documents annexe</AncreLienDocument>
                    <AncreLienDocument href={sinistre.lienFacture || ''}>Facture</AncreLienDocument>
                </BoxBodyDataLienDocument>
            </BoxBodyLienDocument>
        </AccordionDossierCentre>
    );
};

export default LienDocument;