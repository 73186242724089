import { BoxInputSearch, IconSearch, InputS } from "./InputSearchStyle";
import { InputSearchProps } from "./InputSearchType";

const InputSearch = ({ width, placeholder, id, type, value, onChange }: InputSearchProps): JSX.Element => {
    return (
        <BoxInputSearch $width={width}>
            <IconSearch />
            <InputS id={id} name={id} type={type} value={value} onChange={onChange} placeholder={placeholder} />
        </BoxInputSearch>
    );
};

export default InputSearch;