import { Iframe } from "./IframeGtMotiveStyle";
import { IframeGtMotiveProps } from "./IframeGtMotiveType";

const IframeGtMotive = ({ url = null }: IframeGtMotiveProps): JSX.Element | null => {
    if (url) {
        return <Iframe src={url} title="test gtmotive" />;
    };

    return null
};

export default IframeGtMotive;