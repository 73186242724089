import { FormEvent, useContext, useState } from "react";
import { BasePageAuthLogin, CardAuthLogin, ErrorLogin, Formulaire, LinkPassword } from "./LoginStyle";
import { useNavigate } from "react-router-dom";
import { LoginBody, LoginBodySchema, LoginResponse, LoginResponseSchema } from "./LoginType";
import  { AuthContext, AuthTest }  from '../../contexts/authContext/AuthContext';
import { catchError } from "../../core/request/catchError";
import { validStorage } from "../../services/validStorage";
import LogoLogin from "../../components/feature/login/logoLogin/LogoLogin";
import InputLogin from "../../components/feature/login/inputLogin/InputLogin";
import ButtonLogin from "../../components/feature/login/buttonLogin/ButtonLogin";
import { NAME_STORAGE_AUTH, NAME_STORAGE_REFRESH } from "../../shared/constants/Constatnts";

const Login = (): JSX.Element => {

  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext<AuthTest>(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [erreur, setErreur] = useState<string>('');

  const onSubmitConnexion = async (e: FormEvent) => {
    e.preventDefault();

    setErreur(() => '');
    setLoading(() => true);

    const login: LoginBody = {
      email: email.trim(),
      password: password.trim()
    };

    if (!LoginBodySchema.safeParse(login).success) {
      setErreur(() => "L'identifiant ou le mot de passe ne corresponde pas");
      setLoading(() => false);
      return;
    };

    fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(login)
      })
      .then((response) => {
        return response.json();
      })
      .then((data: LoginResponse) => {
          
        catchError(data);

        if (!LoginResponseSchema.safeParse(data).success) {
          setErreur(() => "Une erreur et survenue durant l'authentification veuillez réessayer"); 
          return;
        };

        sessionStorage.setItem(NAME_STORAGE_AUTH, data.token); 
        sessionStorage.setItem(NAME_STORAGE_REFRESH, data.refresh_token);

        setIsAuthenticated(validStorage(data.token, data.refresh_token));

        navigate('/');
      })
      .catch((error) => {
        setErreur(() => error.message);
      })
      .finally(() => {
        setEmail(() => '');
        setPassword(() => '');
        setLoading(() => false);
      });
  };

  return (
    <BasePageAuthLogin>
      <CardAuthLogin>
        <Formulaire onSubmit={onSubmitConnexion}>

          <LogoLogin />

          <InputLogin
            placeholder="Identifiant"
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputLogin
            placeholder="Mot de passe"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <LinkPassword onClick={() => navigate('/password/forget')}>J'ai oublié mon mot de passe ?</LinkPassword>

          <ButtonLogin
            label="Connexion"
            type="submit"
            loader={loading}
          />

          <ErrorLogin>{erreur}</ErrorLogin>

        </Formulaire>
      </CardAuthLogin>
    </BasePageAuthLogin>
  );
};

export default Login;
