import { createPortal } from "react-dom";
import { BoxModalControl, HeaderModalControl, ModalBodyControl, TextModalControl, WrapperModalControl } from "./ModalControleActivationStyle";
import Button from "../../../common/button/Button";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/authContext/AuthContext";
import { catchError } from "../../../../core/request/catchError";
import { useToasts } from "../../../../contexts/toastContext/toastContext";

const ModalControleActivation = (): JSX.Element | null => {

    const { isAuthenticated, logout } = useAuth();
    const { pushToast } = useToasts();

    const [open, setOpen] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        setOpen(() => (isAuthenticated.connected && !isAuthenticated.emailConfirme));
    },[isAuthenticated.connected, isAuthenticated.emailConfirme]);

    const demanderUnlien = () => {
        setLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/compte/activation/${isAuthenticated.username}`,
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: "Un email d'activation vous été envoyé, vous allez être déconnecté du site", type: 'success', duration: 5 });

                logout();
                
                setTimeout(() => {
                    setOpen(() => false);
                }, 5000);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoader(() => false);
            });
    };

    if (open) {
        return createPortal(
            <WrapperModalControl >
                <ModalBodyControl>
                    <HeaderModalControl>
                        <h1>Votre compte utilisateur n'est pas activé</h1>
                    </HeaderModalControl>

                    <BoxModalControl>

                        <TextModalControl>Pour recevoit un nouveau lien d'activation par email cliquer ci-dessous</TextModalControl>

                        <Button
                            label="Demander un lien d'activation"
                            type="submit"
                            width={310}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            onClick={demanderUnlien}
                            loader={loader}
                            disabled={loader}
                        />
                    </BoxModalControl>
                </ModalBodyControl>
            </WrapperModalControl>, document.body);
    };

    return null
};

export default ModalControleActivation;