import { keyframes, styled } from "styled-components";

export const BoxDossierClient = styled.div`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      padding: 25px 10px;
    }
`;

export const BoxHeadingDisplay = styled.div`
    width: 100%;
    margin-bottom: 25px; 
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column; 
      align-items: center;
      justify-content: space-between;
    }
`;

export const BoxHeadingDisplayAssureAssurance = styled.div`
    width: 49%;
    height: 105px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: fit-content;
    }
`;

export const TilteDossierClient = styled.h2`
    width: fit-content;
    color: ${props => props.theme.color.gray};
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
`;

export const ParagDossierClient = styled.p`
    width: fit-content;
    color: ${props => props.theme.color.gray};
`;

export const BoxAccordionDossierClient = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const LoaderDossierClient = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid black;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;