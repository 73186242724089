import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxTitleSectionParamCentre = styled.div`
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
`;

export const H3ParamCentre = styled.h4`
  width: 100%; 
  text-align: start; 
  white-space: nowrap;
  font-family: Roboto-Regular;
  font-size: ${theme.font.medium};
  @media screen and (max-width: 767px) {
        width: 100%;
        font-size: ${theme.font.small};
  }
`;