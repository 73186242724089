import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxAssurance = styled.div`
    width: 100%; 
`;

export const BoxHeadAssurance = styled.div`
    width: 100%; 
    background: ${theme.color.linearGardian};
    padding: 10px 15px;
    color: ${theme.color.white};
`;

export const HeadingBoxAssurance = styled.h4`
    font-size: ${props => props.theme.font.medium};
    white-space: nowrap;
`;

export const BoxBodyAssurance = styled.div`
    width: 100%;
`;

export const BoxBodyDataAssurance = styled.div`
    padding: 0 0 0 5px;
    width: 100%; 
`;

export const BoxInfoDataAssurance = styled.div`
    margin-bottom: 5px; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
`;

export const LabelInfoDataAssurance = styled.p`
    width: 50%;  
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px;
    }
`;

export const DataInfoDataAssurance = styled.p`
    width: 50%;
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;

export const UlAssurance = styled.ul`
    list-style-type: none;
    width: 50%;
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;