import { z } from "zod";
import { DocumentsErreur } from "../../shared/class/DocumentsErreur";

type ErreurCirconstanceType = {
    error: boolean,
    detail: DocumentsErreur
};

export const DocumentsSchema = z.object({
    carteVerteFile: z.instanceof(File, { message: "La carte verte est obligatoire" }),
    carteGriseFile: z.instanceof(File, { message: "Le format de la carte grise n'est pas celui attendu" }).optional(),
    controleTechniqueFile: z.instanceof(File, { message: "Le format du contrôle technique n'est pas celui attendu" }).optional(),
    permisConduireFile: z.instanceof(File, { message: "Le format du permis de conduire n'est pas celui attendu" }).optional(),
    bonLivraisonFile: z.instanceof(File, { message: "Le format du bon de livraison n'est pas celui attendu" }).optional(),
});

export const documentsValidation = (
    carteVerteFile: File | undefined,
    carteGriseFile: File | undefined,
    controleTechniqueFile: File | undefined,
    permisConduireFile: File | undefined,
    bonLivraisonFile: File | undefined
): ErreurCirconstanceType => {

    const data = {
        carteVerteFile: carteVerteFile,
        carteGriseFile: carteGriseFile,
        controleTechniqueFile: controleTechniqueFile,
        permisConduireFile: permisConduireFile,
        bonLivraisonFile: bonLivraisonFile
    };

    const erreurPage = new DocumentsErreur();

    const erreurDocumentsFinal = {
        error: false,
        detail: erreurPage
    };

    const schemaValidation = DocumentsSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurDocumentsFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurPage, { [issue.path[0]]: issue.message });
        });

        return erreurDocumentsFinal;
    };
    return erreurDocumentsFinal;
};