import { BoxBodyDataDegatFacture, BoxInfoDegatFacture, DataInfoDegatFacture, IconCheckedInfoDegatFacture, IconUnCheckedInfoDegatFacture, LabelInfoDegatFacture, UlInfoDegatFacture } from "./DisplayDataDegatFacureStyle";
import AccordionDossierCentre from "../../dossierClient/accordionDossierCentre/AccordionDossierCentre";
import { DisplayDataDegatFacureProps } from "./DisplayDataDegatFacureType";

const DisplayDataDegatFacure = ({ sinistre }: DisplayDataDegatFacureProps): JSX.Element | null => {

    if (sinistre && sinistre.sinistreAssurance) {
        return (
            <AccordionDossierCentre title="Information dégât" forceOpen={true}>
                <BoxBodyDataDegatFacture>
                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Dégat:</LabelInfoDegatFacture>
                        <UlInfoDegatFacture>
                            <li>Pare-brise{sinistre.pareBrise ? <IconCheckedInfoDegatFacture /> : <IconUnCheckedInfoDegatFacture />}</li>
                            <li>Lunette arriere{sinistre.lunetteArriere ? <IconCheckedInfoDegatFacture /> : <IconUnCheckedInfoDegatFacture />}</li>
                            <li>Toit panoramique{sinistre.toitPanoramique ? <IconCheckedInfoDegatFacture /> : <IconUnCheckedInfoDegatFacture />}</li>
                        </UlInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Optique:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.optique ? sinistre.detailOptique : <IconUnCheckedInfoDegatFacture />}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Rétroviseur:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.retroviseur ? sinistre.detailRetroviseur : <IconUnCheckedInfoDegatFacture />}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Glace latérale:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.glaceLaterale ? sinistre.detailGlaceLaterale : <IconUnCheckedInfoDegatFacture />}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Calibrage Camera:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.lunetteArriere ? <IconCheckedInfoDegatFacture /> : <IconUnCheckedInfoDegatFacture />}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Réparation d'impact:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.reparationImpact ? <IconCheckedInfoDegatFacture /> : <IconUnCheckedInfoDegatFacture />}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Autre dégât:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.detailAutre}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>

                    <BoxInfoDegatFacture>
                        <LabelInfoDegatFacture>Précision:</LabelInfoDegatFacture>
                        <DataInfoDegatFacture>{sinistre.precisionPourAssurance}</DataInfoDegatFacture>
                    </BoxInfoDegatFacture>
                </BoxBodyDataDegatFacture>
            </AccordionDossierCentre>
        );
    };

    return null;
};

export default DisplayDataDegatFacure;