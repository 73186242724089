import { createContext, useCallback, useContext, useState } from "react";
import { DataAuthToken } from "../../services/validStorage";
import { AuthProviderType, DateKbisType } from "./AuthContextType";
import { hasAuthenticated } from "../../services/authApi";
import { useNavigate } from "react-router-dom";
import KbisModal from "../../components/feature/expirationKbis/kbisModal/KbisModal";
import ModalControleActivation from "../../components/feature/controleAcitvationCompteUtilisateur/modalControleActivation/ModalControleActivation";
import ModalSession from "../../components/common/modalSession/ModalSession";
import { NAME_STORAGE_AUTH, NAME_STORAGE_REFRESH, NAME_STORAGE_SINISTRE } from "../../shared/constants/Constatnts";

export type AuthTest = {
    isAuthenticated: DataAuthToken,
    setIsAuthenticated: (value: DataAuthToken) => void,
    logout: () => void,
    addDateKbis: (datesKbis: DateKbisType) => void
};

export const AuthContext = createContext<AuthTest>({
    isAuthenticated: {
        connected: false,
        id: undefined,
        centreNom: undefined,
        username: undefined,
        role: undefined,
        kbisEdition: undefined,
        kbisAlerte: undefined,
        kbisExpire: undefined,
        creditAbonnement: 0,
        creditConsoDuMois: 0,
        emailConfirme: false,
        isActif: false,
        exp: 0,
        token: undefined,
        refresh_token: undefined
    },
    setIsAuthenticated: () => { },
    logout: () => { },
    addDateKbis: () => { }
});

export const useAuth = () => {
    return useContext<AuthTest>(AuthContext);
};


const AuthProvider = ({ children }: AuthProviderType): JSX.Element => {

    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated());

    const logout = useCallback(() => {
        sessionStorage.removeItem(NAME_STORAGE_AUTH);
        sessionStorage.removeItem(NAME_STORAGE_REFRESH);
        sessionStorage.removeItem(NAME_STORAGE_SINISTRE);

        navigate('/connexion');
    }, [navigate]);


    const addDateKbis = (datesKbis: DateKbisType) => {
        setIsAuthenticated((curr: DataAuthToken) => {
            return {
                ...curr,
                ...datesKbis
            };
        });
    };

    return (<AuthContext.Provider value={{
        isAuthenticated,
        setIsAuthenticated,
        logout,
        addDateKbis
    }}
    >
        <KbisModal />
        <ModalControleActivation />
        <ModalSession/>

        {children}
    </AuthContext.Provider>);
};

export default AuthProvider;