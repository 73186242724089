import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const SpanAlertKbis = styled.div`
    position: absolute;
    bottom: -35px;
    left: 25px;
    color: ${theme.color.alert};
    font-size: ${theme.font.xlarge};
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        bottom: -25px;
        left: 20px;
        font-size: ${theme.font.normal};
    }
    @media screen and (max-width: 767px) {
        bottom: -17px;
        left: calc(50% - 104px);
        font-size: ${theme.font.small};
    }
`;