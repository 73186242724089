import { FormEvent, useState } from "react";
import { BasePageAuthPasswordForget, CardAuthPasswordForget, ErrorForgotPassword, FormulaireForgotPassword, HeadingFormForgotPassword, LinkLogin } from "./MotDePasseOublieStyle";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { catchError } from "../../core/request/catchError";
import LogoLogin from "../../components/feature/login/logoLogin/LogoLogin";
import InputLogin from "../../components/feature/login/inputLogin/InputLogin";
import ButtonLogin from "../../components/feature/login/buttonLogin/ButtonLogin";
import { useNavigate } from "react-router-dom";

const MotDePasseOublie = (): JSX.Element => {

  const { pushToast } = useToasts();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [emailConfirm, setEmailConfirm] = useState<string>('');
  const [erreur, setErreur] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setErreur('');

    if (email === '' || emailConfirm === '') {
      setErreur('Les deux Adresse email sont obligator');
      return;
    };

    if (email !== emailConfirm) {
      setErreur('Les adresse e-mail ne sont pas identique');
      return;
    };

    const sendEmail = {
      email: email,
      emailConfirm: emailConfirm
    };

    setLoading(() => true);

    fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/password/forgot`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sendEmail)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        catchError(data);

        setEmail(() => '');
        setEmailConfirm(() => '');
        pushToast({ content: "Un e-mail vous a été envoyé, pour finaliser le changement de mot de passe", type: 'success', duration: 5 });
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  return (
    <BasePageAuthPasswordForget>
      <CardAuthPasswordForget>
        <FormulaireForgotPassword onSubmit={onSubmit}>

          <LogoLogin />

          <HeadingFormForgotPassword>J'ai oublié mon mot de passe ?</HeadingFormForgotPassword>

          <InputLogin
            placeholder="Adresse e-mail du compte utilisateur"
            id="adresseEmail"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputLogin
            placeholder="Confirmé l'adresse e-mail"
            id="adresseEmailConfirm"
            type="text"
            value={emailConfirm}
            onChange={(e) => setEmailConfirm(e.target.value)}
          />

         <LinkLogin onClick={() => navigate('/connexion')}>Me connecter ?</LinkLogin>

          <ButtonLogin
            label="Changer de mot de passe"
            type="submit"
            loader={loading}
          />

          <ErrorForgotPassword>{erreur}</ErrorForgotPassword>

        </FormulaireForgotPassword>
      </CardAuthPasswordForget>
    </BasePageAuthPasswordForget>
  );
};

export default MotDePasseOublie;