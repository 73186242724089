export class AssuranceErreur {
    nomSociete: string;
    service: string;
    email: string;
    adresse: string;
    complementAdresse: string;
    codePostal: string;
    ville: string;
    numContrat: string;
    marqueVhl: string;
    modelVhl: string;
    immatriculationVhl: string;
    anneeMiseEnCirculationVhl: string;
    kilometrageVhl: string;
    numVin: string;
    chiffrageAssurance: string;
    referenceSinistreAssurance: string;
    montantFranchise: string;

    constructor() {
        this.nomSociete = '';
        this.service= '';
        this.email= '';
        this.adresse = '';
        this.complementAdresse = '';
        this.codePostal = '';
        this.ville = '';
        this.numContrat = '';
        this.marqueVhl = '';
        this.modelVhl = '';
        this.immatriculationVhl = '';
        this.anneeMiseEnCirculationVhl = '';
        this.kilometrageVhl = '';
        this.numVin = '';
        this.chiffrageAssurance = '';
        this.referenceSinistreAssurance = '';
        this.montantFranchise = '';
    };
};