import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import Accordion from "../../../common/accordion/Accordion";
import Button from "../../../common/button/Button";
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import { AuthContext, AuthTest } from '../../../../contexts/authContext/AuthContext';
import { DataDirigeant, DataDirigeantError, DataDirigeantSchema } from "./ChangeDeDirigeantType";
import { catchError } from "../../../../core/request/catchError";
import { ModifieDonneDuCentreProps } from "../modifieDonneDuCentre/ModifieDonneDuCentreType";
import { BoxBtnAnnuleDirigeant, BoxBtnDirigeant, BoxNomPrenomDirigeant } from "./ChangeDeDirigeantStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import InputFileAccount from "../../../common/field/inputFileAccount/InputFileAccount";


const ChangeDeDirigeant = ({ data }: ModifieDonneDuCentreProps): JSX.Element => {
    const { pushToast } = useToasts();
    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [dataCentre, setDataCentre] = useState<DataDirigeant>({
        nomDirigeant: '',
        prenomDirigeant: ''
    });

    const [dataCentreError, setDataCentreError] = useState<DataDirigeantError>({
        nomDirigeant: undefined,
        prenomDirigeant: undefined
    });

    const [cniFile, setCniFile] = useState<File>();
    const [cniFileError, setCniFileError] = useState<string>('');

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        data && setDataCentre((curr: DataDirigeant) => {
            return {
                ...curr,
                nomDirigeant: data.nomDirigeant,
                prenomDirigeant: data.prenomDirigeant
            };
        });
    }, [data]);

    useEffect(() => {
        if (cniFile) {
            setBtnIsDisabled(() => false);
        } else {
            setBtnIsDisabled(() => true);

            data && setDataCentre((curr: DataDirigeant) => {
                return {
                    ...curr,
                    nomDirigeant: data.nomDirigeant,
                    prenomDirigeant: data.prenomDirigeant
                };
            });
        };
    }, [cniFile, data]);

    const handleChangeDataCentre = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setBtnIsDisabled(() => false);

        setDataCentre({ ...dataCentre, [name]: value });

        setDataCentre((curr: DataDirigeant) => {
            return { ...curr, [name]: value }
        });
    };

    const annule = () => {
        if (data) {
            setDataCentre((curr: DataDirigeant) => {
                return {
                    ...curr,
                    nomDirigeant: data.nomDirigeant,
                    prenomDirigeant: data.prenomDirigeant
                };
            });
        } else {
            setDataCentre({
                nomDirigeant: '',
                prenomDirigeant: ''
            });
        };

        setCniFile(() => undefined);
        setCniFileError(() => '');
        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        setDataCentreError({
            nomDirigeant: undefined,
            prenomDirigeant: undefined
        });

        const schemaValidation = DataDirigeantSchema.safeParse(dataCentre);

        if (!schemaValidation.success) {
            schemaValidation.error.issues.forEach(issue => {
                setDataCentreError((curr: DataDirigeantError) => {
                    return { ...curr, [issue.path[0]]: issue.message }
                });
            });

            return;
        };

        setSubmitLoader(() => true);
        setBtnIsDisabled(() => true);

        const formdata = new FormData();
        formdata.append('data', JSON.stringify(dataCentre));
        cniFile && formdata.append('cni', cniFile);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/dirigeant/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formdata
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: `Le dirigeant du centre a été mis à jour`, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setSubmitLoader(() => false);
                setCniFile(() => undefined);
                setCniFileError(() => '');
            });
    };

    return (
        <Accordion title="Dirigeant du centre">
            <form onSubmit={submitData}>
                <BoxNomPrenomDirigeant>
                    <InputCustom
                        width={48.5}
                        label="Nom du dirigeant du centre"
                        id="nomDirigeant"
                        type="text"
                        value={dataCentre.nomDirigeant}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={dataCentreError.nomDirigeant}
                    />

                    <InputCustom
                        width={48.5}
                        label="Prénom du dirigeant du centre"
                        id="prenomDirigeant"
                        type="text"
                        value={dataCentre.prenomDirigeant}
                        onChange={handleChangeDataCentre}
                        require={false}
                        error={dataCentreError.prenomDirigeant}
                    />
                </BoxNomPrenomDirigeant>

                <InputFileAccount
                    file={cniFile}
                    setFile={setCniFile}
                    label="Carte d'identité du dirigeant"
                    setError={setCniFileError}
                    error={cniFileError}
                    nameError="cni"
                />

                <BoxBtnDirigeant>
                    <BoxBtnAnnuleDirigeant>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnuleDirigeant>
                </BoxBtnDirigeant>
            </form>
        </Accordion>
    );
};

export default ChangeDeDirigeant;