import { z } from "zod";

export const SinistreRetourApiSchema = z.object({
    id: z.number(),
    numSinistre: z.string(),
    circonstance: z.string(),
    lieuSinistre: z.string(),
    dateSinistre: z.string(),
    pareBrise: z.boolean(),
    glaceLaterale: z.boolean(),
    detailGlaceLaterale: z.string().or(z.literal('')).nullable(),
    lunetteArriere: z.boolean(),
    optique: z.boolean(),
    detailOptique: z.string().or(z.literal('')).nullable(),
    retroviseur: z.boolean(),
    detailRetroviseur: z.string().or(z.literal('')).nullable(),
    reparationImpact: z.boolean(),
    calibrageCamera: z.boolean(),
    toitPanoramique: z.boolean(),
    detailAutre: z.string().or(z.literal('')).nullable(),
    precisionPourAssurance: z.string().or(z.literal('')).nullable(),
    createdAt: z.string(),
    nomDocumentAnnexe: z.string(),
    nomDocumentContrat: z.string(),
    documentContratBase64: z.string().or(z.literal('')).nullable(),
    documentAnnexeBase64: z.string().or(z.literal('')).nullable(),
    documentSuiviLreName: z.string().or(z.literal('')).nullable(),
    documentSuiviLre: z.string().or(z.literal('')).nullable(),
    lienFacture: z.string().or(z.literal('')).nullable(),
    dossierTransmit: z.boolean(),
    referenceLre: z.string().or(z.literal('')).nullable(),
    dateDossierTransmit: z.string().or(z.literal('')).nullable(),
    sinistreAssure: z.object({
        id: z.number(),
        nomSociete: z.string().nullable(),
        numSiret: z.string().nullable(),
        civilite: z.string(),
        nom: z.string(),
        prenom: z.string(),
        adresse: z.string(),
        codePostal: z.string(),
        ville: z.string(),
        telephone: z.string(),
        email: z.union([z.string().email(), z.literal('')]).nullable()
    }),
    sinistreAssurance: z.object({
        nomSociete: z.string(),
        adresse: z.string(),
        complementAdresse: z.string(),
        codePostal: z.string(),
        ville: z.string(),
        numContrat: z.string(),
        email: z.string(),
        marqueVhl: z.string(),
        modelVhl: z.string(),
        immatriculationVhl: z.string(),
        anneeMiseEnCirculationVhl: z.string(),
        kilometrageVhl: z.string(),
        numVin: z.string(),
        referenceSinistreAssurance: z.union([z.string(), z.literal('')]).nullable(),
        chiffrageAssurance: z.union([z.string(), z.literal('')]).nullable(),
        montantFranchise: z.union([z.string(), z.literal('')]).nullable(),
        idEstimation: z.string()
    }),
    sinistreFacture: z.object({
        id: z.number(),
        idFacture: z.number(),
        numFacture:  z.string().nullable(),
        status: z.string(),
        dateEdition: z.string()
    })
});

const regexPUHT = new RegExp('[0-9]+([.][0-9]{0,2})?');

export const ProduitSchema = z.object({
    name: z.string().min(1, "Le nom du produit est obligatoire"),
    tax: z.string().min(1, "Le taux de TVA est obligatoire"),
    total_price_gross: z.string().regex(regexPUHT, { message: "Le prix unitaire hors taxe n'est pas conforme" }),
    quantity: z.string().min(1, "La quantité est obligatoire"),
});

export const TableProduitSchema = ProduitSchema.array();

export type Produit = z.infer<typeof ProduitSchema>;
export type SinistreRetourApi = z.infer<typeof SinistreRetourApiSchema>;