import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export type LabelStyleProps = {
    $require: boolean
};

export const Label = styled.label<LabelStyleProps>`
    font-size: ${theme.font.medium};
    font-weight: bold;
    margin-bottom: 5px;
    white-space: nowrap;
    ${props => props.$require && `
        &:after{
            content: "*";
            color: crimson;
        }
    `}
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: ${theme.font.small};
        margin-bottom: 5px;
    }
`;