import { styled } from "styled-components";
import { theme } from "../../theme/theme";

export const BasePageActive = styled.main`
    @font-face {
        font-family: Montserrat-Regular;
        src: url(${require("../../styles/fonts/Montserrat-Regular.ttf")});
    }
    width: 60vw;
    min-height: 100vh;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    background-color: ${theme.color.grayLight};
    box-shadow: 2px 2px 5px #757575;
    @media screen and (min-width: 1024px) {
        width: 60vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100vw;
    }
    @media screen and (max-width: 767px) {
        width: 100vw;
    }
`;

export const CardActive = styled.div`
    width: 40%; 
    height: 30%; 
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (min-width: 1024px) {
        width: 40%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 60%;
    }
    @media screen and (max-width: 767px) {
        width: 90%;
    }
`;

export const HeadingActive = styled.p`
    width: 100%;
    height: fit-content;
    color: ${theme.color.letterGray};
    text-align: left;
    font-size: ${theme.font.xlarge};
    font-family: Montserrat-Regular;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.large};
    }
`;

export const ParagFormActive = styled.p`
    width: 100%;
    height: fit-content;
    color: ${theme.color.letterGray};
    text-align: left;
    font-size: ${theme.font.small};
    font-family: Montserrat-Regular;
    margin-bottom: 5px;
    margin-left: 3px;
`;

export const ParagFormActive2 = styled(ParagFormActive)`
    margin-bottom: 20px;
`;