import { keyframes, styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const ButtonLoginCustom = styled.button`
    width: 100%;
    font-size: ${props => props.theme.font.medium};
    height: 40px;
    background: ${theme.color.linearGardian};
    color: ${theme.color.white};
    border: none;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      height: 40px;
      cursor: pointer;
      background: -webkit-linear-gradient(#9E9E9E, #424242);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border: solid 2px #9E9E9E;
    }
    &:active {
      opacity: 0.5;
    }
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #616161;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;


