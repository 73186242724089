import { ButtonCustom, Loader } from "./ButtonStyle";
import { ButtonPropsType } from "./ButtonType";

const Button = ({ width, type, label, loader = true, color, colorlabel, disabled, onClick }: ButtonPropsType) => {
    return (
        <ButtonCustom
            id={label}
            type={type}
            $width={width}
            $color={color}
            $colorlabel={colorlabel}
            disabled={disabled}
            onClick={onClick}
        >
            {loader ? <Loader /> : label}

        </ButtonCustom> 
    );
};

export default Button;