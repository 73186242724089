export class UpdateCentreErreur {
    capital: string;
    siret: string;
    numTva: string;
    telephone: string;
    tauxHoraireHt: string;
    urlVosFactures: string;
    apiKeyVosFactures: string;
    nomSociete: string;

    constructor() {
        this.capital = '';
        this.siret = '';
        this.numTva = '';
        this.telephone = '';
        this.tauxHoraireHt = '';
        this.urlVosFactures = '';
        this.apiKeyVosFactures = '';
        this.nomSociete = '';
    };
};