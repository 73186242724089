import styled from "styled-components";

export const BoxBodyDataVhlFacture = styled.div`
    padding: 0 0 0 5px;
    width: 100%; 
`;

export const DataAssureFacture = styled.p`
    white-space: nowrap; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
`;

export const NomAssureFacture = styled(DataAssureFacture)`
    margin-bottom: 10px;
`;

export const AdresseAssureFacture = styled.address`
    width: 100%; 
    margin-bottom: 10px;
`;

export const BoxInfoVhlBase = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-start;
`;

export const BoxInfoVhl = styled(BoxInfoVhlBase)`
    margin-bottom: 5px; 
`;

export const LabelInfoVhl = styled.p`
    width: 50%; 
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
`;

export const DataInfoVhl = styled.p`
    width: 50%; 
    white-space: nowrap; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
`;