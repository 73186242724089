

import { MainCustom } from "./BasePageStyle";
import { ChildrenType } from "./BasePageType";

const BasePage = ({ children}: ChildrenType): JSX.Element => {
    return (
        <MainCustom>
            {children}
        </MainCustom>
    );
};

export default BasePage;
