import InputError from "../inputError/InputError";
import LabelCustom from "../labelCustom/LabelCustom";
import { BoxInputCustom, Input } from "./InputCustomStyle";
import { InputCustomPropsType } from "./InputCustomType";

const InputCustom = ({ width, label, placeholder, id, type, value, onChange, disabled = false, error, require = true, pattern }: InputCustomPropsType): JSX.Element => {
    return (
        <BoxInputCustom $width={width}> 
            {label !== '' && <LabelCustom htmlFor={id} label={label} require={require}/>}
            <Input id={id} name={id} type={type} value={value} onChange={onChange} disabled={disabled} placeholder={placeholder} pattern={pattern}/>
            <InputError error={error} />
        </BoxInputCustom>
    );
};

export default InputCustom;