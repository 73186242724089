import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { PlusCircle, XCircle } from "react-bootstrap-icons";

export type AddProduitFactureStyleProps = {
    $linename: 'nom' | 'reference' | 'prixUnitaireHT' | 'quantite' | 'tva' | 'actionAjouter' | 'actionEffacer';
};

export const BoxFormHeadAddProduit = styled.form`
    width: 100%;
    display: grid;
    margin-top: 15px;
    grid-template-columns: [nom] 3fr [prixUnitaireHT] 1fr [quantite] 0.4fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr;
    background: ${theme.color.linearGardian};
    padding: 10px 15px;
    color: ${theme.color.white};
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        display: grid;
        grid-template-columns: [nom] 2fr [prixUnitaireHT] 1fr [quantite] 0.4fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr; 
    }
    @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: [nom] 0.8fr [prixUnitaireHT] 0.5fr [quantite] 0.5fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr; 
        padding: 10px 0;
    }
`;

export const BoxFormBodyAddProduit = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: [nom] 3fr [prixUnitaireHT] 1fr [quantite] 0.4fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr;
    background: transparent;
    padding: 10px 15px;
    color: ${theme.color.white};
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        display: grid;
        grid-template-columns: [nom] 2fr [prixUnitaireHT] 1fr [quantite] 0.4fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr; 
    }
    @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: [nom] 0.8fr [prixUnitaireHT] 0.5fr [quantite] 0.5fr [tva] 0.4fr [actionAjouter] 0.2fr [actionEffacer] 0.2fr; 
        padding: 10px 0;
    }
`;

export const LabelFieldAddProduit = styled.label<AddProduitFactureStyleProps>`
    grid-column-start: ${props => props.$linename};
    font-size: 16px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    @media screen and (max-width: 767px) {
        font-size: 11px;
    }
`;

export const BoxFieldAddProduit = styled.div<AddProduitFactureStyleProps>`
    width: 100%;
    grid-column-start: ${props => props.$linename};
    display: flex; 
    align-items: center; 
    justify-content: center;
`;

export const InputAddProcuct = styled.input`
    width: 95%;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: white;
    border: solid 2px ${props => props.theme.color.grayMedium};
    outline: none;
    &:focus{
        border: solid 2px ${theme.color.letterGray};
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 11px;
    }
`;

export const SelectAddProcuct = styled.select`
    width: 95%;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: white;
    border: solid 2px ${props => props.theme.color.grayMedium};
    outline: none;
    &:focus{
        border: solid 2px ${theme.color.letterGray};
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 11px;
    }
`;

export const IconAdd = styled(PlusCircle)`
    font-size: 25px;
    color: ${theme.color.info};
    transition: scale 0.2s;
    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        scale: 0.9;
    }
`;

export const IconReset = styled(XCircle)`
    font-size: 25px; 
    color: ${theme.color.alert};
    transition: scale 0.2s;
    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        scale: 0.9;
    }
`;

export const FieldRequired  = styled.span`
    font-size: 16px;
    font-weight: 100;
    color: ${theme.color.alert};
    @media screen and (max-width: 767px) {
        font-size: 11px;
    }
`;
