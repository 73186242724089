export class DataCentre {
    id: number | undefined;
    nomSociete: string;
    nomDirigeant: string;
    prenomDirigeant: string;
    email: string;
    siret: string;
    numTva: string;
    capital: string;
    telephone: string;
    tauxHoraireHt: string;
    urlVosFactures: string;
    apiKeyVosFactures: string;
    dateEditionKbis: string;
    dateExpireKbis: string;
    creditAbonnement: number;
    creditConsoDuMois: number;
    creditConsoGlobal: number;
    createdAt: string;
    updateAt: string;

    constructor() {
        this.id = undefined;
        this.nomSociete = '';
        this.nomDirigeant = '';
        this.prenomDirigeant = '';
        this.email = '';
        this.siret = '';
        this.numTva = '';
        this.capital = '';
        this.telephone = '';
        this.tauxHoraireHt = '';
        this.urlVosFactures = '';
        this.apiKeyVosFactures = '';
        this.dateEditionKbis = '';
        this.dateExpireKbis = '';
        this.creditAbonnement = 0;
        this.creditConsoDuMois = 0;
        this.creditConsoGlobal = 0;
        this.createdAt = '';
        this.updateAt = '';
    };
};