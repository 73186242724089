import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { catchError } from "../../../../core/request/catchError";
import Accordion from "../../../common/accordion/Accordion";
import Button from "../../../common/button/Button";
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import   { AuthContext, AuthTest }  from '../../../../contexts/authContext/AuthContext';
import { DataPassword, DataPasswordError, DataPasswordSchema } from "./ChangeDeMotPasseType";
import { BoxBtnAnnulePassword, BoxBtnPassword, BoxConditionPassword, BoxFieldPassword, HeadListePassword, ItemListePassword, ListePassword } from "./ChangeDeMotPasseStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";

const ChangeDeMotPasse = () => {

    const { pushToast } = useToasts();
    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [dataCentre, setDataCentre] = useState<DataPassword>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [dataCentreError, setDataCentreError] = useState<DataPasswordError>({
        currentPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
    });

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (dataCentre.confirmPassword === '' && dataCentre.currentPassword === '' && dataCentre.newPassword === '') {
            setBtnIsDisabled(() => true);
        } else {
            setBtnIsDisabled(() => false);
        };
    }, [dataCentre]);

    const handleChangeDataCentre = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setDataCentre({ ...dataCentre, [name]: value });

        setDataCentre((curr: DataPassword) => {
            return { ...curr, [name]: value }
        });
    };

    const annule = () => {
        setDataCentre({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        });

        setDataCentreError({
            currentPassword: undefined,
            newPassword: undefined,
            confirmPassword: undefined
        });

        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        setDataCentreError({
            currentPassword: undefined,
            newPassword: undefined,
            confirmPassword: undefined
        });

        const schemaValidation = DataPasswordSchema.safeParse(dataCentre);

        if (!schemaValidation.success) {
            schemaValidation.error.issues.forEach(issue => {
                setDataCentreError((curr: DataPasswordError) => {
                    return { ...curr, [issue.path[0]]: issue.message }
                });
            });

            return;
        };

        setBtnIsDisabled(() => true);
        setSubmitLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/password/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(dataCentre)
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                pushToast({ content: `Le mot de passe a été mis à jour`, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setSubmitLoader(() => false);

                setDataCentre({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
            });
    };

    return (
        <Accordion title="Mot de passe">
            <form onSubmit={submitData}>
                <BoxFieldPassword>
                    <InputCustom
                        width={48.5}
                        label="Mot de passe actuel"
                        id="currentPassword"
                        type="password"
                        value={dataCentre.currentPassword}
                        onChange={handleChangeDataCentre}
                        require={true}
                        error={dataCentreError.currentPassword}
                    />

                    <InputCustom
                        width={48.5}
                        label="Nouveau mot de passe"
                        id="newPassword"
                        type="password"
                        value={dataCentre.newPassword}
                        onChange={handleChangeDataCentre}
                        require={true}
                        error={dataCentreError.newPassword}
                    />
                </BoxFieldPassword>

                <BoxFieldPassword>
                    <InputCustom
                        width={48.5}
                        label="Confirmer le mot de passe"
                        id="confirmPassword"
                        type="password"
                        value={dataCentre.confirmPassword}
                        onChange={handleChangeDataCentre}
                        require={true}
                        error={dataCentreError.confirmPassword}
                    />

                    <BoxConditionPassword>
                        <HeadListePassword>Le mot de passe doit <span>10</span> caractères minimums dont au moins:</HeadListePassword>
                        <ListePassword>
                            <ItemListePassword><span>1</span> majuscule</ItemListePassword>
                            <ItemListePassword><span>1</span> minuscule</ItemListePassword>
                            <ItemListePassword><span>1</span> chiffre</ItemListePassword>
                            <ItemListePassword><span>1</span> caractère spécial (@!&...)</ItemListePassword>
                        </ListePassword>
                    </BoxConditionPassword>
                    
                </BoxFieldPassword>
                <BoxBtnPassword>
                    <BoxBtnAnnulePassword>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnulePassword>
                </BoxBtnPassword>
            </form>
        </Accordion>
    );
};

export default ChangeDeMotPasse;