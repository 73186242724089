import { BoxSeparatorTitleArticleLoi, BoxTitleArticleLoi, TitleTitleArticleLoi } from "./TitleArticleLoiStyle";
import { TitleArticleLoiProps } from "./TitleArticleLoiType";

const TitleArticleLoi = ({ title }: TitleArticleLoiProps): JSX.Element => {
    return (
        <BoxTitleArticleLoi>
            <BoxSeparatorTitleArticleLoi />
            <TitleTitleArticleLoi>{title}</TitleTitleArticleLoi>
            <BoxSeparatorTitleArticleLoi />
        </BoxTitleArticleLoi>
    );
};

export default TitleArticleLoi;