import { styled } from "styled-components";
import { theme } from "../../../theme/theme";

export const BoxTitleSection = styled.div`
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start;
`;

export const H3 = styled.h3`
  width: 100%; 
  text-align: start; 
  text-decoration: underline;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: ${theme.font.normal};
  }
`;

export const MentionRequire = styled.p`
  width: 100%; 
  font-size: ${theme.font.xxsmall};
  color: ${theme.color.letterGray};
`;

export const MentionRequireSpan = styled.span`
  font-size: ${theme.font.xxsmall};
  color: ${theme.color.alert};
`;




