import { ThemeType } from "./themeType"

export const theme: ThemeType = {
    color: {
        letterGray: '#616161',
        white: '#FFFFFF',
        gray: '#616161',
        grayLight: '#EEEEEE',
        grayMedium: '#e0e0e0',
        black: '#000000',
        disabledtest: '#BDBDBD',
        info: '#0288d1',
        warning: '#ed6c02',
        success: '#2e7d32',
        alert: 'crimson',
        linearGardian: 'linear-gradient(#9E9E9E, #424242)'
    },
    css: {
        radius: '5px',
        shadow: '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
        shadowModal: '3px 3px 5px #424242'
    },
    font: {
        xxsmall: '10px',
        xsmall: '12px',
        small: '14px',
        normal: '16px',
        medium: '18px',
        large: '20px',
        xlarge: '25px',
        xxlarge: '30px'
    }
};