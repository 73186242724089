import { styled } from "styled-components";
import { theme } from "../../../../../theme/theme";

export const BoxLre = styled.div`
    width: 90%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) { 
        width: 100%;
    }
`;

export const AncreLienDocumentLre = styled.a`
    width: 100%;
    font-size: 18px;
    padding: 2px 0;
    color: ${theme.color.white};
    background-color: ${theme.color.success};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: scale 0.2s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        scale: 0.9;
    }
    @media screen and (max-width: 767px) { 
        width: 90%;
    }
`;

export const BoxInfoLre = styled.div`
    margin-bottom: 10px; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
    @media screen and (max-width: 767px) { 
        width: 90%;
    }
`;

export const LabelInfoLre = styled.p`
    width: 50%; 
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px;
    }
`;

export const DataInfoLre = styled.p`
    width: 50%; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;