import { styled } from "styled-components";
import { BoxFieldStylepropsType } from "./BoxFieldType";

export const BoxFieldCustom = styled.div<BoxFieldStylepropsType>`
  margin-top: ${props => props.$mt ? `${props.$mt}px` : `15px`};
  margin-bottom: ${props => props.$mb ? `${props.$mb}px` : `0`};
  width: ${props => props.$width ? `${props.$width}%` : `100%`};
  ${props => props.$height && `height: ${props.$height}px;`}
  display: flex;
  flex-direction: ${props => props.$flexDirection ? `${props.$flexDirection}` : `row`};
  align-items: ${props => props.$alignItems ? `${props.$alignItems}` : `flex-start`};
  justify-content: ${props => props.$justifyContent ? `${props.$justifyContent}` : `space-between`};
`;
