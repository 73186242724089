import styled from "styled-components";

export const Iframe = styled.iframe`
    z-index: 9999;
    width: 100%; 
    height: 100%; 
    position: fixed;
    top: 0; 
    left: 0;
`;
   
