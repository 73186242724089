import LabelCustom from "../../../common/field/labelCustom/LabelCustom";
import { BoxSelectCoordonneeAssuranceCustom, SelectCoordonneeAssuranceCustom } from "./SelectCoordonneeAssuranceStyle";
import { SelectCoordonneeAssurancePropsType } from "./SelectCoordonneeAssuranceType";

const SelectCoordonneeAssurance = ({ width, label, value, option, id, onChange, require = true }: SelectCoordonneeAssurancePropsType): JSX.Element => {
    return (
        <BoxSelectCoordonneeAssuranceCustom $width={width}>

            {label && <LabelCustom htmlFor={id} label={label} require={require} />}

            <SelectCoordonneeAssuranceCustom id={id} name={id} defaultValue={value} onChange={onChange}>

                <option value="">Sélectionner une compagnie assurance ou effectuer ci-dessous une saisie manuelle directement</option>

                {option.map((coord) => (
                    <option key={coord.id} value={coord.id}>{coord.nomSociete}</option>
                ))}
            </SelectCoordonneeAssuranceCustom>


        </BoxSelectCoordonneeAssuranceCustom>
    );
};

export default SelectCoordonneeAssurance;