import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DocumentsErreur } from '../../../../shared/class/DocumentsErreur';
import { InputFileCustomProps } from './InputFileCustomType';
import { BoxCamera, BoxGlobalInputFileCustom, BoxIconLabelCustom, BoxInputFileCustom, BoxInputFileIconClose, CameraCustom3, ErrorInputFileCustom, FolderCustom, IconRestFile, InputFileCustom2, LabelInputFileCustom } from './InputFileCustomStyle';
import LabelCustom from '../labelCustom/LabelCustom';

const InputFileCustom = ({ label, file, setFile, setError, error, nameError, require = false }: InputFileCustomProps): JSX.Element => {

    const [colorBorder, setcolorBorder] = useState<string>('#e0e0e0');
    const [colorLabel, setcolorLabel] = useState<string>('#616161');

    const [nameDoc, setNameDoc] = useState<string | null>(null);

    const resetFile = useCallback(() => {
        setFile();
        setNameDoc(null);
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');
    }, [setFile]);

    useEffect(() => {
        !file && resetFile();
    }, [file, resetFile]);

    const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        setError('');
        setcolorBorder('#e0e0e0');
        setcolorLabel('#616161');

        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0].size >= 67000000) {
                setError((error: DocumentsErreur) => {
                    const err = { ...error, [nameError]: 'Le document est limité a 67ko' };
                    return err;
                });

                return;
            };

            if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png") {
                setError((error: DocumentsErreur) => {
                    const err = { ...error, [nameError]: 'Le format dois être .jpeg ou .png' };
                    return err;
                });

                return;
            };

            setFile(e.target.files[0]);
            setNameDoc(e.target.files[0].name);
            e.target.files[0] ? setcolorBorder('green') : setcolorBorder('#e0e0e0');
            e.target.files[0] ? setcolorLabel('green') : setcolorLabel('#616161');
        };
    };

    return (
        <BoxGlobalInputFileCustom $nameDocument={nameDoc}>

            {label !== '' && <LabelCustom label={label} require={require} />}

            <BoxInputFileIconClose>

                <BoxCamera>
                    <CameraCustom3 $colorLabel="#FFFFFF" />

                    <InputFileCustom2
                        onChange={onChangeFile}
                        type="file"
                        capture="environment"
                        accept="image/png, image/jpeg"
                    />
                </BoxCamera>

                <BoxInputFileCustom $color={colorBorder}>
                    <BoxIconLabelCustom>
                        {!nameDoc && <FolderCustom $colorLabel={colorLabel} />}
                        <LabelInputFileCustom
                            
                            $colorLabel={colorLabel}>
                            {nameDoc ? nameDoc : 'Ajouter un fichier...'}
                        </LabelInputFileCustom>
                    </BoxIconLabelCustom>

                    <InputFileCustom2
                        onChange={onChangeFile}
                        type="file"
                        title={nameDoc ? nameDoc : 'Ajouter un fichier'}
                        //capture="environment"
                        accept="image/png, image/jpeg"
                    />
                </BoxInputFileCustom>

                {nameDoc && <IconRestFile onClick={resetFile} />}

            </BoxInputFileIconClose>

            <ErrorInputFileCustom>{error}</ErrorInputFileCustom>

        </BoxGlobalInputFileCustom>
    );
};

export default InputFileCustom;