import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";
import { BoxBodyAssure, BoxBodyDataAssure, BoxInfoDataAssure, DataInfoDataAssure, LabelInfoDataAssure, UlAssure } from "./DisplayAssureStyle";
import { DisplayAssureProps } from "./DisplayAssureType";

const DisplayAssure = ({ sinistre }: DisplayAssureProps): JSX.Element | null => {

    if (sinistre && sinistre.sinistreAssure) {
        return (
            <AccordionDossierCentre title="Informations assuré(e)" forceOpen={true}>
                <BoxBodyAssure>
                    <BoxBodyDataAssure >

                        <BoxInfoDataAssure>
                            <LabelInfoDataAssure>Nom:</LabelInfoDataAssure>
                            <DataInfoDataAssure>{sinistre.sinistreAssure.civilite} {sinistre.sinistreAssure.nom} {sinistre.sinistreAssure.prenom}</DataInfoDataAssure>
                        </BoxInfoDataAssure>

                        <BoxInfoDataAssure>
                            <LabelInfoDataAssure>Adresse:</LabelInfoDataAssure>
                            <UlAssure>
                                <li>{sinistre.sinistreAssure.adresse}</li>
                                <li>{sinistre.sinistreAssure.codePostal}</li>
                                <li>{sinistre.sinistreAssure.ville}</li>
                            </UlAssure>
                        </BoxInfoDataAssure>

                        <BoxInfoDataAssure>
                            <LabelInfoDataAssure>Téléphone:</LabelInfoDataAssure>
                            <DataInfoDataAssure>{sinistre.sinistreAssure.telephone}</DataInfoDataAssure>
                        </BoxInfoDataAssure>

                        <BoxInfoDataAssure>
                            <LabelInfoDataAssure>E-mail:</LabelInfoDataAssure>
                            <DataInfoDataAssure>{sinistre.sinistreAssure.email}</DataInfoDataAssure>
                        </BoxInfoDataAssure>
                    </BoxBodyDataAssure>
                </BoxBodyAssure>
            </AccordionDossierCentre>
        );
    };
    
    return null;
};

export default DisplayAssure; 