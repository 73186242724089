import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { XLg, CheckLg } from "react-bootstrap-icons";

export const BoxBodyDataDegatFacture = styled.div`
    padding: 0 0 0 5px;
    width: 100%; 
`;

export const BoxAccordionDegatFacture = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;


export const BoxInfoDegatFacture = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
    margin-bottom: 5px; 
`;

export const LabelInfoDegatFacture = styled.p`
    width: 50%; 
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
`;

export const UlInfoDegatFacture = styled.ul`
    list-style-type: none;
    width: 50%; 
    white-space: nowrap; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
    & li {
        display: flex; 
        flex-direction: row; 
        align-items: center; 
        justify-content: flex-start;
    }
`;

export const DataInfoDegatFacture = styled.p`
    width: 50%; 
    white-space: nowrap; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) {
        font-size: 13px;
    }
`;

export const IconCheckedInfoDegatFacture = styled(CheckLg)`
    font-size: 20px; 
    color: ${theme.color.success};
    margin-left: 10px;
`;

export const IconUnCheckedInfoDegatFacture = styled(XLg)`
    font-size: 15px; 
    color: ${theme.color.alert}; 
    margin-left: 10px;
`;
