import styled, { keyframes } from "styled-components";
import { theme } from "../../../../theme/theme";

export const ButtonTransmetreDossierClient = styled.button`
    width: 90%;
    height: 26px;
    border-radius: 3px;
    text-align: center;
    font-size: ${theme.font.medium};
    color: ${theme.color.white};
    background-color: #ed6c02;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s, scale 0.2s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    } 
    &:active{
        scale: 0.9;
    }
`;

export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #FFF;
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;
