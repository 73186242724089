import { useEffect, useState } from "react";
import { NAME_STORAGE_SINISTRE } from "../shared/constants/Constatnts";

export type DataSinistre = {
    idSinistre: number | undefined,
    idContrat: number | undefined,
    sigantureDeclarationSinistre: string | undefined,
    sigantureConventionDeReglement: string | undefined
};

export default function useSinistre() {

    const [dataSinistre, setDataSinistre] = useState<DataSinistre>({
        idSinistre: undefined,
        idContrat: undefined,
        sigantureDeclarationSinistre: undefined,
        sigantureConventionDeReglement: undefined
    });

    useEffect(() => {
        if (sessionStorage && sessionStorage.getItem(NAME_STORAGE_SINISTRE)) {
            const storage: string = sessionStorage.getItem(NAME_STORAGE_SINISTRE) || '';
            const storageJson: DataSinistre = JSON.parse(storage);

            setDataSinistre((curr: DataSinistre) => {
                return {
                    ...curr,
                    ...storageJson
                };
            });
        };
    }, []);

    const addIdSinistre = (idSinistre: number) => {
        setDataSinistre((curr: DataSinistre) => {
            return {
                ...curr,
                idSinistre: idSinistre
            };
        });

        sessionStorage.setItem(NAME_STORAGE_SINISTRE, JSON.stringify({
            ...dataSinistre,
            idSinistre: idSinistre
        }));
    };

    const addIdContrat = (idContrat: number) => {
        setDataSinistre((curr: DataSinistre) => {
            return {
                ...curr,
                idContrat: idContrat
            };
        });

        sessionStorage.setItem(NAME_STORAGE_SINISTRE, JSON.stringify({
            ...dataSinistre,
            idContrat: idContrat
        }));
    };

    const addSigantureDeclaration = (sigantureDeclarationSinistre: string) => {
        setDataSinistre((curr: DataSinistre) => {
            return {
                ...curr,
                sigantureDeclarationSinistre: sigantureDeclarationSinistre
            };
        });

        sessionStorage.setItem(NAME_STORAGE_SINISTRE, JSON.stringify({
            ...dataSinistre,
            sigantureDeclarationSinistre: sigantureDeclarationSinistre
        }));
    };

    const addSigantureConvention = (sigantureConventionDeReglement: string) => {
        setDataSinistre((curr: DataSinistre) => {
            return {
                ...curr,
                sigantureConventionDeReglement: sigantureConventionDeReglement
            };
        });

        sessionStorage.setItem(NAME_STORAGE_SINISTRE, JSON.stringify({
            ...dataSinistre,
            sigantureConventionDeReglement: sigantureConventionDeReglement
        }));
    };

    const resetSinistre = () => {
        sessionStorage.removeItem(NAME_STORAGE_SINISTRE);

        setDataSinistre((curr: DataSinistre) => {
            return {
                ...curr,
                idSinistre: undefined,
                idContrat: undefined,
                sigantureDeclarationSinistre: undefined,
                sigantureConventionDeReglement: undefined
            };
        });
    };

    return { dataSinistre, addIdSinistre, addIdContrat, addSigantureDeclaration, addSigantureConvention, resetSinistre } as const;
};