import { LinkFactureTraitementDossier } from "./LienAjouterProduitFactureStyle";
import { LienAjouterProduitFactureProps } from "./LienAjouterProduitFactureType";

const LienAjouterProduitFacture = ({ isVisible, idSinistre }: LienAjouterProduitFactureProps): JSX.Element | null => {
    if (!isVisible) {
        return <LinkFactureTraitementDossier to={`/dossier/facture/${idSinistre}`}>Compléter la facture manuellement</LinkFactureTraitementDossier>
    };

    return null;
};

export default LienAjouterProduitFacture;