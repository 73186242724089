import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { BoxInputSearchStyleProps } from "./InputSearchType";
import { Search } from "react-bootstrap-icons";

export const BoxInputSearch = styled.div<BoxInputSearchStyleProps>`
    width: ${props => props.$width ? `${props.$width}%` : `100%`};
    display: flex; 
    flex-direction: row; 
    align-items: center;
    border-bottom: 1px solid ${theme.color.grayMedium};
    @media screen and (max-width: 767px) {
        width: 96vw;
    }
`;

export const IconSearch = styled(Search)`
    font-size: 20px;
    color: ${theme.color.gray};
`;

export const InputS = styled.input<BoxInputSearchStyleProps>`
    width: 100%;
    padding: 5px 15px;
    font-family: Roboto-Regular;
    background-color: transparent;
    font-size: ${theme.font.normal};
    border-top: none;
    border-left: none;
    border-right: none;
    border: none;
    color: ${theme.color.gray};
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: ${theme.font.small};
    }
    &: focus {
        outline: none;
    }
`;