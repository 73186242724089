import LogoLogin from "../../components/feature/login/logoLogin/LogoLogin";
import {  ErrorLogin } from "../login/LoginStyle";
import ButtonLogin from "../../components/feature/login/buttonLogin/ButtonLogin";
import { BasePageActive, CardActive, HeadingActive, ParagFormActive, ParagFormActive2 } from "./ConfirmActivationStyle";
import { useState } from "react";
import InputLogin from "../../components/feature/login/inputLogin/InputLogin";
import { catchError } from "../../core/request/catchError";
import { useToasts } from "../../contexts/toastContext/toastContext";

const ConfirmActivationFail = () => {
    const { pushToast } = useToasts();
    
    const [email, setEmail] = useState<string>('');
    const [erreur, setErreur] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);

    const demanderUnNouveauLien = () => {

        setErreur(() => '');

        if (!email || email === '') {
            setErreur("l'adresse e-amil est obligatoire");
            return;
        };

        setLoader(()=> true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/compte/activation/${email}`,
            {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: data.message, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                setErreur(error.message);
            })
            .finally(() => {
                setLoader(()=> false);
            });
    };

    return (
        <BasePageActive>
            <CardActive>
                <LogoLogin />

                <HeadingActive>La session d'activation a expiré</HeadingActive>

                <ParagFormActive>Vous pouvez demander un nouveau lien d'activation </ParagFormActive>
                <ParagFormActive2>en saisissant votre identifiant (adresse e-mail du compte)</ParagFormActive2>

                <InputLogin
                    placeholder="identifiant du compte"
                    id="email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <ErrorLogin>{erreur}</ErrorLogin>

                <ButtonLogin
                    label="Demander mon lien d'activation"
                    type="button"
                    loader={loader}
                    onClick={demanderUnNouveauLien}
                />

            </CardActive>
        </BasePageActive>
    );


};

export default ConfirmActivationFail;