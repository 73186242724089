import { ReactNode, useRef, useState } from "react";
import { AccordionChildren, QuestionContainer, Wrapper } from "./AccordionStyle";

import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import TitleSectionParamCentre from "../../feature/centreParametrage/titleSectionParamCentre/TitleSectionParamCentre";

const Accordion = ({ children, title, forceOpen }: { children: ReactNode, title: string , forceOpen?: boolean}): JSX.Element => {

    const contentHeight = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState<boolean>(forceOpen ? forceOpen : false);

    return (
        <Wrapper $isOpen={isOpen}>
            <QuestionContainer onClick={() => setIsOpen((curr: boolean) => !curr)} >
                <TitleSectionParamCentre title={title} />
                {isOpen ? <ChevronUp /> : <ChevronDown />}
            </QuestionContainer>

            <AccordionChildren
                $isOpen={isOpen}
                ref={contentHeight}
                className="answer-container"
                style={isOpen ? { height: contentHeight.current?.scrollHeight } : { height: "0px" }}
            >
                {children}
            </AccordionChildren>
        </Wrapper>
    );
};

export default Accordion;