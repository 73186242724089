import { ButtonCustomResetSignature } from "./ButtonResetSignatureStyle";
import { ButtonResetSignatureProps } from "./ButtonResetSignatureType";

const ButtonResetSignature = ({ width, type, label, color, colorlabel, disabled, onClick }: ButtonResetSignatureProps) => {
    return (
        <ButtonCustomResetSignature
            type={type}
            $width={width}
            $color={color}
            $colorlabel={colorlabel}
            disabled={disabled}
            onClick={onClick}
        >
            {label}

        </ButtonCustomResetSignature> 
    );
};

export default ButtonResetSignature;