import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";
import { BoxBodyAssurance, BoxBodyDataAssurance, BoxInfoDataAssurance, DataInfoDataAssurance, LabelInfoDataAssurance, UlAssurance } from "./DisplayAssuranceStyle";
import { DisplayAssuranceProps } from "./DisplayAssuranceType";

const DisplayAssurance = ({ sinistre }: DisplayAssuranceProps): JSX.Element | null => {

    if (sinistre && sinistre.sinistreAssure) {
        return (
            <AccordionDossierCentre title="Informations assurance" forceOpen={true}>
                <BoxBodyAssurance>
                    <BoxBodyDataAssurance >

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Compagnie:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.nomSociete}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance>

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Adresse:</LabelInfoDataAssurance>
                            <UlAssurance>
                                <li>{sinistre.sinistreAssurance.adresse}</li>
                                <li>{sinistre.sinistreAssurance.complementAdresse}</li>
                                <li>{sinistre.sinistreAssurance.codePostal}</li>
                                <li>{sinistre.sinistreAssurance.ville}</li>
                            </UlAssurance>
                        </BoxInfoDataAssurance>

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>E-mail:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.email}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance> 

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Numéro contrat:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.numContrat}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance>

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Montant franchise:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.montantFranchise}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance>

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Chiffrage assurance:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.chiffrageAssurance}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance>

                        <BoxInfoDataAssurance>
                            <LabelInfoDataAssurance>Référence du sinistre:</LabelInfoDataAssurance>
                            <DataInfoDataAssurance>{sinistre.sinistreAssurance.referenceSinistreAssurance}</DataInfoDataAssurance>
                        </BoxInfoDataAssurance>
                    </BoxBodyDataAssurance>
                </BoxBodyAssurance>
            </AccordionDossierCentre>
        );
    };

    return null;
};

export default DisplayAssurance; 