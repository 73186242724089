import { createContext, useContext, useEffect, useState } from "react";
import { GtMotiveContextProviderType, GtMotiveContextType, WindowWithAttachEvent } from "./GtMotiveContextType";
import { useToasts } from "../toastContext/toastContext";
import { AuthContext, AuthTest } from "../authContext/AuthContext";
import { catchError } from "../../core/request/catchError";
import IframeGtMotive from "./iframeGtMotive/IframeGtMotive";

export const GtMotiveContext = createContext<GtMotiveContextType>({
    loaderAddProduct: false,
    addIdSinistre: () => { },
    addIdEstimate: () => { },
    addUrlIframe: () => { }
});

export const useGtMotive = () => {
    return useContext<GtMotiveContextType>(GtMotiveContext);
};

const GtMotiveContextProvider = ({ children }: GtMotiveContextProviderType) => {
    const { pushToast } = useToasts();
    const { token } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [url, setUrl] = useState<string | null>(null);
    const [idSinistre, setIdSinistre] = useState<number | null>(null);
    const [idEstimation, setIdEstimation] = useState<string | null>(null);
    const [loaderAddProduct, setLoaderAddProduct] = useState<boolean>(false);

    useEffect(() => {
        const receiveMessage = (event: MessageEvent) => {
            const RegexTargetURI = /https:\/\/estimate.mygtmotive.com/i;

            if (RegexTargetURI.test(event.origin) && idSinistre) {

                setUrl(null);
                setLoaderAddProduct(() => true);

                fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/gtmotive/estimation/ajout-produit-facture/${idEstimation}/${idSinistre}`, {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${token}` },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {

                        catchError(data);

                        pushToast({ content: data.message, type: 'success', duration: 5 });
                    })
                    .catch((error: Error) => {
                        pushToast({ content: error.message, type: 'alert', duration: 5 });
                    })
                    .finally(() => {
                        setLoaderAddProduct(() => false);
                        setUrl(() => null);
                        setIdSinistre(() => null);
                        setIdEstimation(() => null);
                    });
            };
        };

        const win = window as WindowWithAttachEvent;

        win.attachEvent ? win.attachEvent("onmessage", receiveMessage as EventListener) : win.addEventListener("message", receiveMessage, false);

        return () => {
            win.detachEvent ? win.detachEvent("onmessage", receiveMessage as EventListener) : win.removeEventListener("message", receiveMessage, false);
        };
    }, [ pushToast, idSinistre, idEstimation, token]);

    const addIdSinistre = (id: number | null) => {
        setIdSinistre(() => id);
    };

    const addIdEstimate = (id: string | null) => {
        setIdEstimation(() => id);
    };

    const addUrlIframe = (url: string | null) => {
        setUrl(() => url);
    };

    return (
        <GtMotiveContext.Provider value={{ loaderAddProduct, addIdSinistre, addIdEstimate, addUrlIframe }}>
            <IframeGtMotive url={url} />

            {children}
        </GtMotiveContext.Provider>
    );
};

export default GtMotiveContextProvider;


