import styled from "styled-components";
import { BoxInputLoginStylePropsType } from "./InputLoginType";
import { theme } from "../../../../theme/theme";

export const BoxInputLogin = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex; 
    flex-direction: column; 
    alignItems: flex-start;
`;

export const InputLog = styled.input<BoxInputLoginStylePropsType>`
    padding: 10px 15px;
    background-color: ${theme.color.white};
    font-size: ${theme.font.large};
    border-radius: 3px;
    border: 2px solid ${theme.color.grayMedium};
    color: ${theme.color.gray};
    &:focus {
        outline: none;
        border: 2px solid ${theme.color.gray};
    }
`;