import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxBodyLienDocument = styled.div`
    width: 100%;
`;

export const BoxBodyDataLienDocument = styled.div`
    width: 100%; 
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const AncreLienDocument = styled.a`
    width: 90%;
    font-size: 18px;
    padding: 2px 0;
    color: ${theme.color.white};
    background-color: ${theme.color.success};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: scale 0.2s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        scale: 0.9;
    }
`;
