import { BoxBodyDataVhl, BoxBodyVhl, BoxInfoVhl, DataInfoVhl, LabelInfoVhl } from "./DisplayVhlStyle";
import { DisplayVhlProps } from "./DisplayVhlType";
import AccordionDossierCentre from "../accordionDossierCentre/AccordionDossierCentre";

const DisplayVhl = ({ sinistre }: DisplayVhlProps): JSX.Element | null => {

    if (sinistre && sinistre.sinistreAssurance) {
        return (
            <AccordionDossierCentre title="Informations véhicule" forceOpen={true}>
                <BoxBodyVhl>
                    <BoxBodyDataVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Marque:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.marqueVhl}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Modèle:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.modelVhl}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Immatriculation:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.immatriculationVhl}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Numéro VIN:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.numVin}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Mise en circulation:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.anneeMiseEnCirculationVhl}</DataInfoVhl>
                        </BoxInfoVhl>

                        <BoxInfoVhl>
                            <LabelInfoVhl>Kilométrage:</LabelInfoVhl>
                            <DataInfoVhl>{sinistre.sinistreAssurance.kilometrageVhl}</DataInfoVhl>
                        </BoxInfoVhl>
                    </BoxBodyDataVhl>
                </BoxBodyVhl>
            </AccordionDossierCentre>
        );
    };

    return null;
};

export default DisplayVhl;