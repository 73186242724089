import { NAME_STORAGE_AUTH, NAME_STORAGE_REFRESH } from '../shared/constants/Constatnts';
import { validStorage } from './validStorage';

export const hasAuthenticated = () => {

    if (sessionStorage.getItem(NAME_STORAGE_AUTH) !== null && sessionStorage.getItem(NAME_STORAGE_REFRESH) !== null) {
        
        const userAuth = validStorage(sessionStorage.getItem(NAME_STORAGE_AUTH), sessionStorage.getItem(NAME_STORAGE_REFRESH));

        return userAuth;
    };

    const dataUser = {
        connected: false,
        id: undefined,
        username: undefined,
        centreNom: undefined,
        senderId: undefined,
        role: undefined,
        kbisEdition: undefined,
        kbisAlerte: undefined,
        kbisExpire: undefined,
        creditAbonnement: 0,
        creditConsoDuMois: 0,
        emailConfirme: false,
        isActif: false,
        exp: 0,
        token: undefined,
        refresh_token: undefined
    };

    return dataUser;
};
