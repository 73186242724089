import InputError from "../inputError/InputError";
import LabelCustom from "../labelCustom/LabelCustom";
import { BoxSelectCiviliteCustom, SelectCiviliteCustom } from "./SelectCiviliteStyle";
import { SelectCivilitePropsType } from "./SelectCiviliteType";

const SelectCivilite = ({ width, label, value, id, onChange, error, require = true }: SelectCivilitePropsType): JSX.Element => {
    return (
        <BoxSelectCiviliteCustom $width={width}>
            <LabelCustom htmlFor={id} label={label} require={require}/>
            <SelectCiviliteCustom id={id} name={id} defaultValue={value} onChange={onChange}>
                <option value="M.">M.</option>
                <option value="Mme">Mme</option>
                <option value="Mmes">Mmes</option>
                <option value="Mrs">Mrs</option>
                <option value="M.Mme">M.Mme</option>
            </SelectCiviliteCustom>
            <InputError error={error}/>
        </BoxSelectCiviliteCustom>
    );
};

export default SelectCivilite;