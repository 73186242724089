import { z } from "zod";

const messageStr = { invalid_type_error: 'Le format est Incorrect' };



export const CirconstanceSiAutreEtVraiSchema = z.object({
    circonstance: z.string(messageStr).min(1, { message: "La circonstance est obligatoire" }),
    lieuSinistre: z.string(messageStr).min(1, { message: "Le lieu du sinistre est obligatoire" }),
    dateSinistre: z.string(messageStr).min(1, { message: "La date du bris de glace est obligatoire" }),
    autre: z.boolean(),
    detailAutre: z.string(messageStr).min(1, { message: "La circonstance est obligatoire" }),
    precisionPourAssurance: z.union([z.string(messageStr), z.literal('')]),
    lieuSignature: z.string(messageStr).min(1, { message: "Le lieu de signature est obligatoire" }),
    dateSignature: z.string(messageStr).min(1, { message: "La date de signature est obligatoire" })
});

export const CirconstanceSchema = z.object({
    circonstance: z.string(messageStr).min(1, { message: "La circonstance est obligatoire" }),
    lieuSinistre: z.string(messageStr).min(1, { message: "Le lieu du sinistre est obligatoire" }),
    dateSinistre: z.string(messageStr).min(1, { message: "La date du bris de glace est obligatoire" }),
    pareBrise: z.boolean(),
    glaceLaterale: z.boolean(),
    detailGlaceLaterale: z.union([z.string(messageStr), z.literal('')]),
    lunetteArriere: z.boolean(),
    optique: z.boolean(),
    detailOptique: z.union([z.string(messageStr), z.literal('')]),
    calibrageCamera: z.boolean(),
    autre: z.boolean(),
    detailAutre: z.union([z.string(messageStr), z.literal('')]),
    toitPanoramique: z.boolean(),
    retroviseur: z.boolean(),
    detailRetroviseur: z.union([z.string(messageStr), z.literal('')]),
    reparationImpact: z.boolean(),
    precisionPourAssurance: z.union([z.string(messageStr), z.literal('')]),
    lieuSignature: z.string(messageStr).min(1, { message: "Le lieu de signature est obligatoire" }),
    dateSignature: z.string(messageStr).min(1, { message: "La date de signature est obligatoire" })
});

export const CirconstanceUpdateSchema = z.object({
    circonstance: z.string().nullable(),
    lieuSinistre: z.string().nullable(),
    dateSinistre: z.string().nullable(),
    pareBrise: z.boolean(),
    glaceLaterale: z.boolean(),
    lunetteArriere: z.boolean(),
    optique: z.boolean(),
    detailOptique: z.string().nullable(),
    calibrageCamera: z.boolean(),
    autre: z.boolean(),
    detailAutre: z.union([z.string(messageStr), z.literal('')]).nullable(),
    toitPanoramique: z.boolean(),
    retroviseur: z.boolean(),
    detailRetroviseur: z.union([z.string(messageStr), z.literal('')]).nullable(),
    reparationImpact: z.boolean(),
    precisionPourAssurance: z.union([z.string(messageStr), z.literal('')]).nullable(),
    lieuSignature: z.string().nullable(),
    dateSignature: z.string().nullable(),
});

export type CirconstanceType = z.infer<typeof CirconstanceSchema>;
export type CirconstanceUpdateType = z.infer<typeof CirconstanceUpdateSchema>;

