import { styled } from "styled-components";
import { theme } from "../../theme/theme"; 

export const BasePageResetPassword = styled.main`
    width: 60vw;
    min-height: 100vh;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    background-color: ${theme.color.grayLight};
    box-shadow: 2px 2px 5px #757575;
    @media screen and (min-width: 1024px) {
        width: 60vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100vw;
    }
    @media screen and (max-width: 767px) {
        width: 100vw;
    }
`;

export const CardResetPassword = styled.div`
    width: 40%; 
    height: 30%; 
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (min-width: 1024px) {
        width: 40%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 60%;
    }
    @media screen and (max-width: 767px) {
        width: 90%;
    }
`;

export const HeadingFormResetPassword = styled.p`
    width: 100%;
    height: fit-content;
    color: ${theme.color.letterGray};
    text-align: left;
    font-size: ${theme.font.large};
    font-family: Montserrat-Regular;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.large};
    }
`;

export const TitleResetPassword = styled.h1`
    width: 100%; 
    height: 100px;
    color: white; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    background: ${theme.color.linearGardian};
`;

export const FormulaireResetPassword = styled.form`
    width: 100%;
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    border-top: none;
`;

export const ErrorResetPassword = styled.p`
    width: 100%;
    height: 18px; 
    margin-top: 10px; 
    color: crimson; 
    font-size: 18px; 
    text-align: left;
`;

export const HeadListeResetPassword = styled.p`
    width: 100%;
    font-size: 14px;
    text-align: left;
    font-style: italic;
`;

export const ListeResetPassword = styled.ul`
    width: 100%;
    text-align: left;
    list-style-type: none;
    font-style: italic;
    margin-bottom: 20px;
`;

export const ItemListeResetPassword = styled.li`
    margin-left: 8px;
    font-size: 14px;
    &:before{
        content: "-";
        padding-right: 8px;
    } 
`;
