import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const BoxInfoDataAbonnement = styled.div`
    margin-bottom: 5px; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
`;

export const LabelInfoDataAbonnement = styled.p`
    width: 50%;  
    font-weight: 600; 
    font-size: 16px;
    text-align: left;
    @media screen and (max-width: 767px) { 
        width: 70%; 
        font-size: 13px;

    }
`;

export const LabelInfoCreditRestant = styled(LabelInfoDataAbonnement)`
    color: ${theme.color.alert};
`;

export const DataInfoDataAbonnement = styled.p`
    width: 50%;
    font-style: italic; 
    font-size: 16px;
    text-align: right;
    @media screen and (max-width: 767px) { 
        width: 30%; 
        font-size: 13px;
    }
`;

export const DataInfoCreditRestant = styled(DataInfoDataAbonnement)`
    color: ${theme.color.alert};
`;
