import BasePage from "../../components/common/basePage/BasePage";
import Header from "../../components/common/header/header/Header";
import { useContext, useEffect, useState } from "react";
import { BoxParamCentre, BoxParamCentreLoaderError, ErrorParamCentre, Loader } from "./CentreParametrageStyle";
import  { AuthContext, AuthTest }  from '../../contexts/authContext/AuthContext';
import ModifieDonneDuCentre from "../../components/feature/centreParametrage/modifieDonneDuCentre/ModifieDonneDuCentre";
import ChangeLeCachet from "../../components/feature/centreParametrage/changeDeCachet/ChangeLeCachet";
import ChangeDeDirigeant from "../../components/feature/centreParametrage/changeDeDirigeant/ChangeDeDirigeant";
import ChangeDeMotPasse from "../../components/feature/centreParametrage/changeDeMotPasse/ChangeDeMotPasse";
import ChangeDeRib from "../../components/feature/centreParametrage/changeDeRib/ChangeDeRib";
import ChangeDeKbis from "../../components/feature/centreParametrage/changeDeKbis/ChangeDeKbis";
import { DataCentreSchema } from "../../components/feature/centreParametrage/modifieDonneDuCentre/ModifieDonneDuCentreType";
import { catchError } from "../../core/request/catchError";
import { DataCentre } from "../../shared/class/Centre";
import ChangeEmail from "../../components/feature/centreParametrage/changeEmail/ChangeEmail";
import ChangeAbonnement from "../../components/feature/centreParametrage/changeAbonnement/ChangeAbonnement";

const CentreParametrage = (): JSX.Element | null => {
    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [loader, setLoader] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [dataCentre, setDataCentre] = useState<DataCentre>(new DataCentre());

    useEffect(() => {
        id && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/${id}`, {
            method: "GET",
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then((response) => {
                return response.json()
            })
            .then((data: DataCentre) => {
                catchError(data);

                if(!DataCentreSchema.safeParse(data).success){
                    setError(() => "Les données reçues ne sont pas conformes aux attentes");
                    return;
                };

                setDataCentre((curr: DataCentre) => {
                    return { ...curr, ...data };
                });
            })
            .catch((error: Error) => {
                setError(() => error.message);
            })
            .finally(() => {
                setLoader(() => false);
            });
    }, [id, token]);

    return (
        <BasePage>

            <Header />

            {loader &&
                <BoxParamCentreLoaderError>
                    <Loader />
                </BoxParamCentreLoaderError>
            }

            {!loader && error &&
                <BoxParamCentreLoaderError>
                    <ErrorParamCentre>{error}</ErrorParamCentre>
                </BoxParamCentreLoaderError>
            }

            {!loader && !error && DataCentreSchema.safeParse(dataCentre).success &&
                <BoxParamCentre>

                    <ModifieDonneDuCentre data={dataCentre} />

                    <ChangeAbonnement data={dataCentre}/>

                    <ChangeEmail email={dataCentre.email} />

                    <ChangeDeMotPasse />

                    <ChangeDeDirigeant data={dataCentre} />

                    <ChangeDeRib />

                    <ChangeDeKbis />

                    <ChangeLeCachet />

                </BoxParamCentre>
            }
        </BasePage>
    );
};

export default CentreParametrage;