import { styled } from "styled-components";
import { BoxSelectStylePropsType } from "./SelectType";
import { theme } from "../../../../theme/theme";

export const BoxInputCustom = styled.div<BoxSelectStylePropsType>`
    width: ${props => props.$width ? `${props.$width}%` : `100%`};
    display: flex; 
    flex-direction: column; 
    alignItems: flex-start;
     @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const SelectCustom = styled.select`
    padding: 10px 15px;
    background-color: ${props => props.theme.color.white};
    font-size: ${props => props.theme.font.large};
    border-radius: 3px;
    border: 2px solid ${props => props.theme.color.grayMedium};
    color: ${props => props.theme.color.gray};
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.small};
    }
    &:focus {
        outline: none;
        border: 2px solid ${props => props.theme.color.gray};
    }
    &: invalid {
        border: 2px solid ${props => props.theme.color.alert};
        color: ${props => props.theme.color.alert};
    }
`;