import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import { Trash } from "react-bootstrap-icons";

export const BoxTableProduit = styled.div`
    width: 100%;
`;

export const BoxHeadProduit = styled.div`
    background: ${theme.color.linearGardian};
    padding: 10px 15px;
    color: ${theme.color.white};
    display: grid; 
    grid-template-columns: [action] 0.2fr [produit] 2fr [quantite] 0.5fr [prixUnitaireHT] 0.5fr [tva] 0.5fr [prixTotalHT] 1fr [prixTotalTTC] 1fr;
    @media screen and (max-width: 767px) {
        padding: 10px 0;
        display: grid; 
        grid-template-columns: [action] 0.2fr [produit] 1fr [quantite] 0.5fr [prixUnitaireHT] 0.5fr [tva] 0.5fr [prixTotalHT] 0.7fr [prixTotalTTC] 0.7fr;
    }
`;

export type TableProduitFactureStyleProps = {
    $linename: 'action' | 'produit' | 'quantite' | 'prixUnitaireHT' | 'tva' | 'prixTotalHT' | 'prixTotalTTC'
};

export const CelHeadProduit = styled.div<TableProduitFactureStyleProps>`
    grid-column-start: ${props => props.$linename};
    font-size: 16px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    @media screen and (max-width: 767px) {
        font-size: 11px;
    }
`;

export const BoxRowProduit = styled(BoxHeadProduit)`
    margin-top: 0; 
    background: none;
    color: ${theme.color.black};
    border-bottom: solid 1px #e0e0e0;
`;

export const CelBodyProduit = styled.div<TableProduitFactureStyleProps>`
    grid-column-start: ${props => props.$linename};
    font-size: 16px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    @media screen and (max-width: 767px) {
        font-size: 11px;
    }
`;

export const IconDeleteProduit = styled(Trash)`
    color: ${theme.color.alert}; 
    font-size: 18px;
    transition: scale 0.2s;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    &:active{
        scale: 0.9;
    }
    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`;

export const BoxNoProducts = styled.div`
    width: 100%; 
    height: 1px; 
    background-color: ${theme.color.letterGray};
`;