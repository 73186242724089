import { useNavigate } from "react-router-dom";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { AssureErreur } from '../../shared/class/AssureErreur';
import { Assure } from '../../shared/class/Assure';
import { assureValidation } from '../../core/validation/assureValidation';
import { TilePage } from '../../components/common/basePage/BasePageStyle';
import { BoxFieldBtnFormAssure, BoxFieldFormAssure, BoxFieldPhoneAddressFormAssure, BoxLoader, Form, Loader } from './FormAssureStyle';
import TitleSection from '../../components/common/titleSection/TitleSection';
import InputCustom from '../../components/common/field/inputCustom/InputCustom';
import Button from '../../components/common/button/Button';
import Header from '../../components/common/header/header/Header';
import BasePage from "../../components/common/basePage/BasePage";
import Checkbox from "../../components/common/field/checkbox/Checkbox";
import SelectCivilite from "../../components/common/field/selectCivilite/SelectCivilite";
import { AssureUpdateRetourSchema, AssureUpdateRetourType, AssureUpdateSchema, AssureUpdateType } from "./FormAssureType";
import { SinistreContext } from "../../contexts/sinistreContext/SinistreContext";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { catchError } from "../../core/request/catchError";
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';
import { useCredit } from "../../contexts/creditcContext/CreditContext";
import { STATUS_CREDIT_BLOQUE } from "../../shared/constants/Constatnts";

export default function FormAssure() {

  const navigate = useNavigate();
  
  const { creditStatus } = useCredit();
  const { pushToast } = useToasts();
  const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;
  const { dataSinistre, addIdSinistre } = useContext(SinistreContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [erreur, setErreur] = useState<AssureErreur>(new AssureErreur());
  const [dataAssure, setDataAssure] = useState<Assure>(new Assure());

  useEffect(() => {
    creditStatus === STATUS_CREDIT_BLOQUE && navigate('/');
  }, [creditStatus, navigate]);

  useEffect(() => {
    if (dataSinistre.idSinistre && token) {

      setLoading(() => true);

      fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/assure/${dataSinistre?.idSinistre}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then((response) => {
          return response.json();
        })
        .then((data: AssureUpdateType) => {
          catchError(data);

          if (!AssureUpdateSchema.safeParse(data).success) {
            pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
            return;
          };

          setDataAssure((curr: Assure) => {
            return {
              ...curr,
              ...data,
              nomSociete: data.nomSociete ? data.nomSociete : '',
              numSiret: data.numSiret ? data.numSiret : ''
            };
          });
        })
        .catch((error: Error) => {
          pushToast({ content: error.message, type: 'alert', duration: 5 });
        })
        .finally(() => {
          setLoading(() => false);
        });
    };
  }, [dataSinistre, pushToast, token]);

  const onChangeAssure = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataAssure({ ...dataAssure, [name]: value });
  };

  const onChangeCivilite = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setDataAssure({ ...dataAssure, [name]: value });
  };

  const onChangeIsProfessionnel = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setDataAssure({ ...dataAssure, [name]: checked });

    !checked === false && setDataAssure((curr: Assure) => {
      return {
        ...curr,
        nomSociete: '',
        numSiret: ''
      };
    });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const isValide = assureValidation(dataAssure);
    setErreur({ ...erreur, ...isValide.detail });

    if (isValide.error) {
      return;
    };

    setLoadingBtn(() => true);

    const urlCreate = `${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/${id}`;
    const urlUpadte = `${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/assure/${dataSinistre.idSinistre}`;

    id && token && fetch(dataSinistre.idSinistre ? urlUpadte : urlCreate, {
      method: dataSinistre.idSinistre ? "PATCH" : "POST",
      headers: { 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataAssure)
    })
      .then((response) => {
        return response.json();
      })
      .then((data: AssureUpdateRetourType) => {

        catchError(data);

        if (!AssureUpdateRetourSchema.safeParse(data).success) {
          pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
          return;
        };

        addIdSinistre(data.idSinistre);
        pushToast({ content: "Les données de l'assuré sont bien enregistrées", type: 'success', duration: 5 });
        navigate(`/declaration/assurance/${data.idSinistre}`);
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoadingBtn(() => false);
      });
  };

  return (
    <BasePage>
      <Header />

      <TilePage>Déclaration de bris de glace</TilePage>

      {!loading ?
        <Form onSubmit={onSubmit}>

          <TitleSection title="Assuré(e)" />

          <BoxFieldFormAssure>
            <Checkbox
              label="Professionnel"
              checked={dataAssure.isPro}
              id="isPro"
              name="isPro"
              onChange={onChangeIsProfessionnel}
            />
          </BoxFieldFormAssure>

          {dataAssure.isPro &&
            <BoxFieldFormAssure>
              <InputCustom
                width={49}
                label="Nom société"
                id="nomSociete"
                type="text"
                value={dataAssure.nomSociete}
                onChange={onChangeAssure}
                error={erreur.nomSociete}
              />

              <InputCustom
                width={49}
                label="N° SIRET"
                id="numSiret"
                type="text"
                value={dataAssure.numSiret}
                onChange={onChangeAssure}
                error={erreur.numSiret}
                require={dataAssure.isPro}
              />
            </BoxFieldFormAssure>
          }

          <BoxFieldFormAssure>
            <SelectCivilite
              width={14}
              label="Civlilté"
              id="civilite"
              value={dataAssure.civilite}
              onChange={onChangeCivilite}
              error={erreur.civilite}
              require={!dataAssure.isPro}
            />

            <InputCustom
              width={41}
              label={dataAssure.isPro ? "Nom du représentant" : "Nom assuré(e)"}
              id="nom"
              type="text"
              value={dataAssure.nom}
              onChange={onChangeAssure}
              error={erreur.nom}
              require={!dataAssure.isPro}
            />

            <InputCustom
              width={41}
              label={dataAssure.isPro ? "Prénom du représentant" : "Prénom assuré(e)"}
              id="prenom"
              type="text"
              value={dataAssure.prenom}
              onChange={onChangeAssure}
              error={erreur.prenom}
              require={!dataAssure.isPro}
            />
          </BoxFieldFormAssure>

          <BoxFieldFormAssure>
            <InputCustom
              width={32}
              label="Adresse"
              id="adresse"
              type="text"
              value={dataAssure.adresse}
              onChange={onChangeAssure}
              error={erreur.adresse}
            />

            <InputCustom
              width={32}
              label="Code Postal"
              id="codePostal"
              type="text"
              value={dataAssure.codePostal}
              onChange={onChangeAssure}
              error={erreur.codePostal}
            />

            <InputCustom
              width={32}
              label="Ville"
              id="ville"
              type="text"
              value={dataAssure.ville}
              onChange={onChangeAssure}
              error={erreur.ville}
            />
          </BoxFieldFormAssure>

          <BoxFieldPhoneAddressFormAssure>
            <InputCustom
              width={48.5}
              label="Téléphone"
              id="telephone"
              type="tel"
              value={dataAssure.telephone}
              onChange={onChangeAssure}
              error={erreur.telephone}
            />

            <InputCustom
              width={48.5}
              label="Adresse mail"
              id="email"
              type="text"
              value={dataAssure.email}
              onChange={onChangeAssure}
              error={erreur.email}
              require={dataAssure.isPro}
            />
          </BoxFieldPhoneAddressFormAssure>

          <BoxFieldBtnFormAssure>
            <Button
              label="Suivant"
              type="submit"
              width={115}
              color="#0288d1"
              colorlabel="#EEEEEE"
              loader={loadingBtn}
            />
          </BoxFieldBtnFormAssure>
        </Form>
        :
        <BoxLoader>
          <Loader />
        </BoxLoader>
      }
    </BasePage>
  );
};

