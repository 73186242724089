import { styled } from "styled-components";

export const BoxBodyAssure = styled.div`
    width: 100%;
`;

export const BoxBodyDataAssure = styled.div`
    padding: 0 0 0 5px;
    width: 100%; 
`;

export const BoxInfoDataAssure = styled.div`
    margin-bottom: 5px; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
`;

export const LabelInfoDataAssure = styled.p`
    width: 40%; 
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;

export const DataInfoDataAssure = styled.p`
    width: 60%; 
    white-space: nowrap; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;

export const UlAssure = styled.ul`
    list-style-type: none;
    width: 60%; 
    white-space: nowrap; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;