import { format, parse } from "date-fns";
import { useContext } from "react";
import { SpanAlertKbis } from "./KbisAlertStyle";
import   { AuthContext, AuthTest }  from '../../../../contexts/authContext/AuthContext';

const KbisAlert = (): JSX.Element | null => {
    const { kbisExpire, kbisAlerte } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const date = format(new Date(), 'dd/MM/yyyy');

    if (kbisAlerte && kbisExpire) {

        const dateDuJour = parse(date, 'dd/MM/yyyy', new Date());
        const dateAlerte = parse(kbisAlerte, 'dd/MM/yyyy', new Date());
        const dateExpire = parse(kbisExpire, 'dd/MM/yyyy', new Date());

        if (dateDuJour >= dateAlerte && dateDuJour < dateExpire) {
            return <SpanAlertKbis>Votre KBIS expire le : {kbisExpire}</SpanAlertKbis>
        };
    };

    return null;
};

export default KbisAlert;