import { styled } from "styled-components";

export const FormAssuranceCustom = styled.form`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        padding: 25px 10px;
    }
`;

export const BoxFiledFormAssurance = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

export const BoxFiledBtnFormAssurance = styled(BoxFiledFormAssurance)`
    align-items: center;
    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
`;
