import { useContext, useEffect, useState } from "react";
import BasePage from "../../components/common/basePage/BasePage";
import Header from "../../components/common/header/header/Header";
import TitleSection from "../../components/common/titleSection/TitleSection";
import { BoxBtnCreateFacure, BoxContainerGtmotive, BoxFactureCustom, LoaderFacture } from "./FormFactureStyle";
import Button from "../../components/common/button/Button";
import { Produit, SinistreRetourApiSchema } from "./FormFactureType";
import FormAddProduit from "../../components/feature/facturation/formAddProduit/FormAddProduit";
import TableProduitFacture from "../../components/feature/facturation/tableProduitFacture/TableProduitFacture";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { SinistreRetourApi, TableProduitSchema } from "../DossierClient/DossierClientType";
import { catchError } from "../../core/request/catchError";
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';
import DisplayDataVhlFacure from "../../components/feature/facturation/displayDataVhlFacure/DisplayDataVhlFacure";
import DisplayDataDegatFacure from "../../components/feature/facturation/displayDataDegatFacure/DisplayDataDegatFacure";
import { BoxAccordionDegatFacture } from "../../components/feature/facturation/displayDataDegatFacure/DisplayDataDegatFacureStyle";
import CallGtMotive from "../../components/feature/dossierClient/callGtMotive/CallGtMotive";

const FormFacture = (): JSX.Element | null => {

    const navigate = useNavigate();
    const { idSinistre } = useParams();

    const { pushToast } = useToasts();
    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [sinistre, setSinistre] = useState<SinistreRetourApi>();
    const [loading, setLoading] = useState<boolean>(false);

    const [produitsFacture, setProduitFacture] = useState<Produit[]>([]);
    const [loadingBtnAjouter, setLoadingBtnAjouter] = useState<boolean>(false);

    useEffect(() => {

        setLoading(() => true);

        idSinistre && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/${idSinistre}`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => {
                return response.json();
            })
            .then((data: SinistreRetourApi) => {

                catchError(data);

                if (SinistreRetourApiSchema.safeParse(data).success) {
                    pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
                    return;
                };

                if (data.dossierTransmit === true) {
                    navigate('/dossier/client');
                    return;
                };

                setSinistre(() => data);
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoading(() => false);
            });
    }, [idSinistre, navigate, pushToast, token]);

    const ajouterDesProduitAlaFacture = async () => {
        if (!produitsFacture.length || !TableProduitSchema.safeParse(produitsFacture).success) {
            pushToast({ content: "Une donnée est incorecte ou manquante sur un produit", type: 'alert', duration: 5 });
            return;
        };

        setLoadingBtnAjouter(true);

        idSinistre && token && id && sinistre?.sinistreFacture.idFacture && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/facture/${id}/${idSinistre}/${sinistre?.sinistreFacture.idFacture}`, {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(produitsFacture)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {

                catchError(data);

                pushToast({ content: "Les produits ont été ajoutés à la facture", type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoadingBtnAjouter(false);
                setProduitFacture(() => []);
            });
    };

    if (loading) {
        return (
            <BasePage>
                <Header />

                <BoxFactureCustom>
                    <LoaderFacture />
                </BoxFactureCustom>

            </BasePage>
        );
    } else if (!loading && sinistre) {
        return (
            <BasePage>
                <Header />

                <BoxFactureCustom>
                    <TitleSection title="Facturation manuelle" require={false} />

                    <BoxContainerGtmotive>
                        <CallGtMotive sinistre={sinistre} />
                    </BoxContainerGtmotive>

                    <BoxAccordionDegatFacture>
                        <DisplayDataVhlFacure sinistre={sinistre} />

                        <DisplayDataDegatFacure sinistre={sinistre} />
                    </BoxAccordionDegatFacture>

                    <TitleSection title="Ajout de produit à la facture" require={false} />

                    <FormAddProduit setProduitFacture={setProduitFacture} />

                    <TableProduitFacture produits={produitsFacture} setProduitFacture={setProduitFacture} />

                    <BoxBtnCreateFacure>
                        <Button
                            label="Retour"
                            width={100}
                            type="button"
                            loader={false}
                            color="#BDBDBD"
                            colorlabel="#EEEEEE"
                            disabled={false}
                            onClick={() => navigate(`/dossier/complet/${idSinistre}`)}
                        />

                        <Button
                            label="Ajouter les produits"
                            width={200}
                            type="button"
                            loader={loadingBtnAjouter}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            disabled={!produitsFacture.length}
                            onClick={ajouterDesProduitAlaFacture}
                        />
                    </BoxBtnCreateFacure>
                </BoxFactureCustom>
            </BasePage>
        );
    };

    return null;
};

export default FormFacture;