import styled from "styled-components";

export const HeaderBox = styled.header`
    position: relative;
    width: 60vw;
    height: 130px;
    padding: 20px;
    background: ${props => props.theme.color.linearGardian};
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    @media screen and (min-width: 1024px) {
        width: 60vw;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100vw;
    }
    @media screen and (max-width: 767px) {
        width: 100vw;
        height: 110px;
        padding: 10px 20px 10px 20px;
    }
`;

export const BoxLogoNavigation = styled.div`
    width: 220px;
    height: 100%;  
    display: flex;  
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (min-width: 1024px) {
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const DisplayTelephone = styled.span`
    margin-top: 10px;
    color: ${props => props.theme.color.grayLight}; 
`;

export const BoxLogoCentre = styled.div`
    width: 30%;
    height: 40px;  
    display: flex;  
    flex-direction: row;
    align-items: center; 
    justify-content: center;
`;

export const BoxBarreNavigation = styled.div`
    width: 70%; 
    height: 40px;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-end;
`;

export const BoxBreadcrumbDate = styled.div`
    min-width: 170px; 
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        min-width: 170px; 
    }
`;



