import { z } from "zod";

export const ControleSigantureSchema = z.object({
    certifiLectureTextLoi: z.boolean(),
    accepteCgu: z.boolean(),
    luEtApprouveConvention: z.boolean(),
    sigantureDeclarationSinistre: z.string().min(1).optional(),
    sigantureConventionDeReglement: z.string().min(1)
});

export type ControleSigantureSchemaType = z.infer<typeof ControleSigantureSchema>;

export type ErreurConventionReglementDesReparationsType = {
    signatureConvention?: string
};