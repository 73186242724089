import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";
import { StatusType } from "../../../../contexts/creditcContext/CreditContextType";
import { STATUS_CREDIT_ALERT, STATUS_CREDIT_BLOQUE, STATUS_CREDIT_INFO } from "../../../../shared/constants/Constatnts";

export type CompteurCreditStyleProps = {
    $status: StatusType;
};

const styleByStatus = (status: StatusType) => {
    switch (status) {
        case STATUS_CREDIT_INFO:
            return `
                color: ${theme.color.black};
            `;
        case STATUS_CREDIT_ALERT:
        case STATUS_CREDIT_BLOQUE:
            return `
                border: solid 1px ${theme.color.alert};
                color: ${theme.color.alert};
            `;
        default:
            return `
                color: ${theme.color.black};
            `;
    };
};

export const BoxCredit = styled.div<CompteurCreditStyleProps>`
    width: 100%;
    height: 28px;
    background-color: #E0E0E0;
    padding: 0 1em;
    border-radius: 3px;
    display: flex; 
    align-items: center;
    justify-content: center;
    ${props => styleByStatus(props.$status)}
`;

export const Credit = styled.span`
    font-size: ${theme.font.small};
    font-weight: bold;
`;