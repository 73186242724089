import { useContext } from "react";
import  {AuthContext, AuthTest } from "../../../../contexts/authContext/AuthContext";
import { BoxBarreNavigation, Home, LinkCustom, Logout, UserParam, } from "./BarreNavigationStyle";
import { SinistreContext } from "../../../../contexts/sinistreContext/SinistreContext";
import { SinistreContextType } from "../../../../contexts/sinistreContext/SinistreContextContextType";

const BarreNavigation = (): JSX.Element => {
    const { logout } = useContext<AuthTest>(AuthContext);
    const { resetSinistre } = useContext<SinistreContextType>(SinistreContext);

    const disconnect = () => {
        logout();
        resetSinistre();
    };

    return (
        <BoxBarreNavigation>
            <LinkCustom to="/"><Home /></LinkCustom>
            <LinkCustom to="/parametrage/centre"><UserParam /></LinkCustom>
            <Logout onClick={disconnect} />
        </BoxBarreNavigation> 
    ); 
};

export default BarreNavigation;