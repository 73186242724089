import { createContext } from "react";
import useSinistre from "../../hooks/useSinistre";
import { SinistreContextProviderType, SinistreContextType } from "./SinistreContextContextType";

export const SinistreContext = createContext<SinistreContextType>({
    dataSinistre: {
            idSinistre: undefined,
            idContrat: undefined,
            sigantureDeclarationSinistre: undefined,
            sigantureConventionDeReglement: undefined,
    },
    addIdSinistre: () => { },
    addIdContrat: () => { },
    addSigantureDeclaration: () => { },
    addSigantureConvention: () => { },
    resetSinistre: () => { },
});

const SinistreContextProvider = ({ children }: SinistreContextProviderType) => {

    const dataSinistre = useSinistre();

    return (
        <SinistreContext.Provider value={dataSinistre}>
            {children}
        </SinistreContext.Provider>
    );
};

export default SinistreContextProvider;

