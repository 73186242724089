import { BoxInputLogin, InputLog } from "./InputLoginStyle";
import { InputLoginPropsType } from "./InputLoginType";

const InputLogin = ({ placeholder, id, type, value, onChange }: InputLoginPropsType): JSX.Element => {
    return (
        <BoxInputLogin>
            <InputLog id={id} name={id} type={type} value={value} onChange={onChange} placeholder={placeholder} />
        </BoxInputLogin>
    );
};

export default InputLogin;