import styled from "styled-components";

export const Container = styled.div`
    z-index: 99999;
    width: fit-content; 
    height: fit-content; 
    position: fixed; 
    top: 30px; 
    right: 30px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-end;
    @media screen and (max-width: 767px) {
        width: 100%; 
        position: fixed; 
        top: 30px; 
        right: 5px;
    }
`;