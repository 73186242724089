import { z } from "zod"

export const RetourPdfContratSchema = z.object({
    status: z.number(),
    detail: z.string(),
    idContrat: z.number()
});

export const RetourPdfAnnexeSchema = z.object({
    status: z.number(),
    detail: z.string(),
    idDocumentAnnexe: z.number()
});

export type RetourPdfAnnexeType = z.infer<typeof RetourPdfAnnexeSchema>;
export type RetourPdfContratType = z.infer<typeof RetourPdfContratSchema>;
