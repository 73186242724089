import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const Nav = styled.nav`
  width: 100%; 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const LinkStyleBtn = styled(Link)`
  width: 50%;
  padding: 20px;
  background: ${props => props.theme.color.linearGardian};
  margin-bottom: 30px;
  border-radius: 5px;
  color: ${props => props.theme.color.grayLight};
  font-size: ${props => props.theme.font.xlarge};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: opacity 0.2s, scale 0.2s;
  &: hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &: active {
    scale: 0.95;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px)  {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const LinkStyleBtnDeclaration = styled.div`
  width: 50%;
  padding: 20px;
  background: ${props => props.theme.color.linearGardian};
  margin-bottom: 30px;
  border-radius: 5px;
  color: ${props => props.theme.color.grayLight};
  font-size: ${props => props.theme.font.xlarge};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: opacity 0.2s, scale 0.2s;
  &: hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &: active {
    scale: 0.95;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px)  {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const BoxLinkMention = styled.div`
  width: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
`;

export const LinkMentionLegale = styled(Link)`
  font-size: 12px;
  text-decoration: underline;
  transition: opacity 0.2s, scale 0.2s;
  &:first-child {
    margin-right: 20px;
  }²  
  &:hover{
    opacity: 0.6;
  }
  &:active{
    scale: 0.95;
  }
`;