import InputError from "../inputError/InputError";
import LabelCustom from "../labelCustom/LabelCustom";
import { BoxTextareaCustom, Textarea } from "./TextareaCustomStyle";
import { TextareaCustomPropsType } from "./TextareaCustomType";

const TextareaCustom = ({ width, label, placeholder, id, value, onChange, disabled = false, error, require = true }: TextareaCustomPropsType): JSX.Element => {
    return (
        <BoxTextareaCustom $width={width}>
            <LabelCustom htmlFor={id} label={label} require={require} />
            <Textarea
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                rows={3}
                maxLength={250}
            >{value}</Textarea>
            <InputError error={error} />
        </BoxTextareaCustom>
    );
};

export default TextareaCustom;