import { BoxLogoLogin, ImgProfact } from "./LogoLoginStyle";
import logoProfact from './logo_profact.png';

const LogoLogin = () => {
    return (
        <BoxLogoLogin>
            <ImgProfact src={logoProfact} title='logo profact' loading="lazy"/>
        </BoxLogoLogin>
    );
};

export default LogoLogin;