import { styled } from "styled-components";

export const FormConvention = styled.form`
    width: 100%;
    padding: 25px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: 25px 10px;
    }
`;

export const ArcticleFormSignature = styled.article`
    width: 100%;
    font-size: 18px; 
    @media screen and (max-width: 767px) {
        & h4 {
            font-size: 14px !important; 
        }
        & p {
            font-size: 14px !important; 
        }
    }
`;

export const BoxFieldConvention = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    & h4 {
        font-size: 18px; 
        margin-bottom: 10px;
    }
    & p {
        font-family: Roboto-Regular;
        font-size: 18px;
        text-align: justify;
    }
`;

export const BoxArticleConvention = styled(BoxFieldConvention)`
    margin-bottom: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BoxCheckboxConvention = styled(BoxFieldConvention)`
    flex-direction: column;
    align-items: center;
    ustify-content: center;
`;

export const BoxBtnConvention = styled(BoxFieldConvention)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BoxCheckBoxSignature = styled.div`
    margin-top: 25px;
    width: 55%;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 10px;
    }
`;