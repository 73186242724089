import BasePage from "../../components/common/basePage/BasePage"
import { TilePage } from "../../components/common/basePage/BasePageStyle";
import Header from "../../components/common/header/header/Header";
import ArrayListeDossierClient from "../../components/feature/dossierClient/listeDossierClient/ArrayListeDossierClient";

const ListeDossierClient = (): JSX.Element => {
    return (
        <BasePage>
            <Header />

            <TilePage>Dossier Client</TilePage>

            <ArrayListeDossierClient/>
        </BasePage>
    );
};

export default ListeDossierClient;