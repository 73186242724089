import styled, { keyframes } from "styled-components";
import { theme } from "../../../theme/theme";

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const WrapperSession = styled.div`
    z-index: 10;
    width: 100vw;
    height: 100vh; 
    position: fixed; 
    top: 0; 
    left: 0; 
    background-color: black; 
    display: flex; 
    align-items: center;
    justify-content: center;
    animation: ${opacity} 225ms forwards;
`;

export const ModalSessionBody = styled.aside`
    background-color: ${theme.color.white};
    width: 30%;
    box-shadow: ${theme.css.shadowModal};
    border-radius: ${theme.css.radius};
    overflow: hidden;
    @media screen and (min-width: 1024px) {
        width: 60%;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 96%;
    }
    @media screen and (max-width: 767px) {
        width: 96%;
    }
`;

export const HeaderModalSession = styled.header`
    width: 100%;
    height: 30px;
    background: ${theme.color.linearGardian};
    color: ${theme.color.white};
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    & h1 {
        font-size: ${theme.font.medium};
    }
`;

export const MainModalSession = styled.div`
    width: 100%;
    padding: 25px;
`;

export const BoxButtonSession = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 767px) {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const CountModalSession = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    color: ${theme.color.alert}; 
    font-size: ${theme.font.xlarge};
    @media screen and (max-width: 767px) {
         font-size: ${theme.font.normal};
    }
`;