import { FormEvent, useContext, useEffect, useState } from "react";
import { catchError } from "../../../../core/request/catchError";
import Accordion from "../../../common/accordion/Accordion";
import  { AuthContext, AuthTest }  from "../../../../contexts/authContext/AuthContext";
import Button from "../../../common/button/Button";
import { BoxBtnAnnuleRib, BoxBtnRib } from "./ChangeDeRibStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import InputFileAccount from "../../../common/field/inputFileAccount/InputFileAccount";

const ChangeDeRib = () => {

    const { pushToast } = useToasts();
    const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [ribFile, setRibFile] = useState<File>();
    const [ribFileError, setRibFileError] = useState<string>('');

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        setBtnIsDisabled(() => ribFile ? false : true);
    }, [ribFile]);

    const annule = () => {
        setRibFileError(() => '');
        setRibFile(() => undefined);
        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        setRibFileError(() => '');

        if (!ribFile) {
            setRibFileError(() => 'Le Relevé d\'identité bancaire est requis');
            return;
        };
        
        setBtnIsDisabled(() => true);
        setSubmitLoader(() => true);

        const formdata = new FormData();
        formdata.append('rib', ribFile);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/rib/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formdata
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                catchError(data);

                pushToast({ content: `Le RIB a été mis à jour`, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setSubmitLoader(() => false);
                setRibFile(() => undefined);
            });
    };

    return (
        <Accordion title="Relevé d'identité bancaire (RIB)">
            <form onSubmit={submitData}>
                <InputFileAccount
                    file={ribFile}
                    setFile={setRibFile}
                    label="Relevé d'identité bancaire"
                    setError={setRibFileError}
                    error={ribFileError}
                    nameError="rib"
                />

                <BoxBtnRib>
                    <BoxBtnAnnuleRib>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnuleRib>
                </BoxBtnRib>
            </form>
        </Accordion>
    );
};

export default ChangeDeRib;