import styled, { keyframes } from "styled-components";
import { theme } from "../../../../theme/theme";
import { ListeRowProps } from "./ArrayListeDossierClientType";
import { Link } from "react-router-dom";

import { Receipt, Eye, SendCheck, SendExclamation } from "react-bootstrap-icons";

export const BoxListe = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100vw; 
    }
`;

export const BoxHeadListe = styled.div`
    width: 90%;
    display: grid;
    margin-top: 10px;
    grid-template-columns: [numSinistre] 1fr [identite] 1fr [date] 1fr [facture] 0.3fr [display] 0.3fr [send] 0.3fr;
    background: ${props => props.theme.color.linearGardian};
    color: ${theme.color.white};
    @media screen and (max-width: 767px) {
      width: 96vw; 
      grid-template-columns: [numSinistre] 1fr [identite] 1fr [date] 1fr [facture] 0.2fr [send] 0.2fr;
    }
`;

export const BoxBodyListe = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: [numSinistre] 1fr [identite] 1fr [date] 1fr [facture] 0.3fr [display] 0.3fr [send] 0.3fr;
    border-bottom: solid 1px #e0e0e0;
    @media screen and (max-width: 767px) {
      width: 96vw; 
      grid-template-columns: [numSinistre] 1fr [identite] 1fr [date] 1fr [facture] 0.2fr [send] 0.2fr;
    }
`;

export const ListeRowHead = styled.div<ListeRowProps>`
    padding: 10px 15px;
    grid-column-start: ${props => props.$linename};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
      padding: 10px 10px; 
      white-space: nowrap;
      font-size: ${theme.font.xsmall};
    }
`;


export const ListeRowVoirHead = styled(ListeRowHead)`
    @media screen and (max-width: 767px) {
      display: none;
    }
`;

export const ListeRowBody = styled.div<ListeRowProps>`
    padding: 10px 15px;
    grid-column-start: ${props => props.$linename};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
    &:hover{
      cursor: pointer;
      opacity: 0.5;
    }
    @media screen and (max-width: 767px) {
      padding: 10px 10px; 
      white-space: nowrap;
      font-size: ${theme.font.xsmall};
    }
`; 

export const ListeRowIconBody = styled(ListeRowBody)`
  &: hover {
    cursor: default;
    opacity: 1;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 3px; 
    white-space: nowrap;
      ont-size: ${theme.font.xsmall};
  }
`;

export const ListeRowIconVoirBody = styled(ListeRowBody)`
    @media screen and (max-width: 767px) {
      display: none;
    }
`;

export const LinkCustom = styled(Link)`
  width: 25px;
  display: flex;
  align-items: center; 
  justify-content: center;
  transition: scale 0.2s;
  &:active{
    scale: 0.9;
  }
`;

export const IconFacture = styled(Receipt)`
  font-size: 25px;
  color: #0288d1;
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
`;

export const IconVoir = styled(Eye)`
  font-size: 25px;
  color: #0288d1;
  @media screen and (max-width: 767px) {
    display: none;
  } 
`;

export const IconSend = styled(SendCheck)`
  font-size: 25px;
  color: #2e7d32;
  @media screen and (max-width: 767px) {
    font-size: 15px;
  } 
`;

export const IconNotSend = styled(SendExclamation)`
  font-size: 25px;
  color: #ed6c02;
  @media screen and (max-width: 767px) {
    font-size: 15px;
  } 
`;

export const MessageNoDossier = styled.p`
    font-size: 18px;
    color: ${theme.color.letterGray};
`;


export const prixClipFix = keyframes`
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;

export const rotate = keyframes`
  100% {transform: rotate(360deg)}
`;

export const Loader = styled.span`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  animation: ${rotate} 1s linear infinite;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid ${theme.color.letterGray};
    animation: ${prixClipFix} 2s linear infinite ;
  }
`;
