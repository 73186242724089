import styled, { keyframes } from "styled-components";
import { theme } from "../../../../theme/theme";
import { X } from "react-bootstrap-icons";

const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
    z-index: 10;
    width: 100vw;
    height: 100vh; 
    position: fixed; 
    top: 0; 
    left: 0; 
    background-color: black; 
    display: flex; 
    align-items: center;
    justify-content: center;
    animation: ${opacity} 225ms forwards;
`;

export const ModalBody = styled.aside`
    background-color: ${theme.color.white};
    width: 30%;
    box-shadow: ${theme.css.shadowModal};
    border-radius: ${theme.css.radius};
    overflow: hidden;
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
        width: 96vw;
    }
    @media screen and (max-width: 767px) {
        width: 96vw; 
    }
`;

export const HeaderModalKbisExpire = styled.header`
    width: 100%;
    height: 30px;
    padding: 25px;
    background: ${theme.color.linearGardian};
    color: ${theme.color.white};
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px) {
        & h1 {
            font-size: ${theme.font.normal};
        }
    }
`;

export const FormModalKbisExpire = styled.form`
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BoxDateKbis = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const DateKbis = styled.div`
    font-size: ${theme.font.normal};
    white-space: nowrap;
    & span {
        font-size: ${theme.font.medium};
        font-weight: bold;
        white-space: nowrap;
    }
    @media screen and (max-width: 767px) {
        & span {
            font-size: ${theme.font.normal};
        }
        &: first-child {
            margin-bottom: 5px;
        }
    }
`;

export const SeparatorDateKbis = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.color.grayLight};
    margin: 15px 0;
`;



export const CircleClosePortal = styled(X)`
    font-size: ${theme.font.xxlarge};
    color: ${theme.color.letterGray};
    transition: opacity 0.1s;
    &:hover{
        cursor: pointer;
        opacity: 0.5;
        color: ${theme.color.success};
    }
`;

export const BoxBtnModalKbisExpire = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-itels: center;
    justify-content: flex-end;
`;