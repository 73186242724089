import { DonneDuCentre, ValidationDataCentreSchema } from "../../../components/feature/centreParametrage/modifieDonneDuCentre/ModifieDonneDuCentreType";
import { UpdateCentreErreur } from "../../../shared/class/UpdateCentreErreur";

type ErreurAssuranceType = {
    error: boolean,
    detail: UpdateCentreErreur
};

export const centreValidation = (data: DonneDuCentre): ErreurAssuranceType => {

    const erreurCentre = new UpdateCentreErreur();

    const erreurAssuranceFinal = {
        error: false,
        detail: erreurCentre 
    };

    const schemaValidation = ValidationDataCentreSchema.safeParse(data);

    if (!schemaValidation.success) {
        Object.assign(erreurAssuranceFinal, { error: true });

        schemaValidation.error.issues.forEach(issue => {
            Object.assign(erreurCentre, { [issue.path[0]]: issue.message });
        });

        return erreurAssuranceFinal;
    };

    return erreurAssuranceFinal;
};