import { styled } from "styled-components";

export const BoxBodyVhl = styled.div`
    width: 100%; 
`;

export const BoxBodyDataVhl = styled.div`
    padding: 0 0 0 5px;
    width: 100%; 
`;

export const BoxInfoVhl = styled.div`
    margin-bottom: 5px; 
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    justify-content: flex-start;
`;

export const LabelInfoVhl = styled.p`
    width: 50%; 
    font-weight: 600; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px;
    }
`;

export const DataInfoVhl = styled.p`
    width: 50%; 
    font-style: italic; 
    font-size: 16px;
    @media screen and (max-width: 767px) { 
        font-size: 13px; 
    }
`;