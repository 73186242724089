import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { AssuranceErreur } from '../../shared/class/AssuranceErreur';
import { Assurance } from '../../shared/class/Assurance';
import { assuranceValidation } from '../../core/validation/assuranceValidation';
import { BoxFiledBtnFormAssurance, BoxFiledFormAssurance, FormAssuranceCustom } from './FormAssuranceStyle';
import TitleSection from '../../components/common/titleSection/TitleSection';
import InputCustom from '../../components/common/field/inputCustom/InputCustom';
import Button from '../../components/common/button/Button';
import Header from '../../components/common/header/header/Header';
import BasePage from "../../components/common/basePage/BasePage";
import { AssuranceUpdateSchema, AssuranceUpdateType } from "./FormAssuranceType";
import { useToasts } from "../../contexts/toastContext/toastContext";
import SelectCoordonneeAssurance from "../../components/feature/formAssurance/selectCoordonneeAssurance/SelectCoordonneeAssurance";
import useCoordonneeAssurance from "../../hooks/useCoordonneeAssurance";
import { catchError } from "../../core/request/catchError";
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';

export default function FormAssurance() {
  const navigate = useNavigate();
  const { idSinistre } = useParams();
  const { pushToast } = useToasts();
  const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;
  const { coordonneeAssurance } = useCoordonneeAssurance(token);

  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [erreur, setErreur] = useState<AssuranceErreur>(new AssuranceErreur());
  const [dataAssurance, setDataAssurance] = useState<Assurance>(new Assurance());

  useEffect(() => {
    !idSinistre && navigate('/');
  }, [idSinistre, navigate]);

  useEffect(() => {
    idSinistre && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/assurance/${idSinistre}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then((response) => {
        return response.json();
      })
      .then((data: AssuranceUpdateType) => {

        if (data === null) return;

        catchError(data);

        if (!AssuranceUpdateSchema.safeParse(data).success) {
          pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
          return;
        };

        setDataAssurance((curr: Assurance) => {
          return {
            ...curr,
            ...data
          };
        });

      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      });
  }, [idSinistre, pushToast, token]);

  const onChangeAssurance = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDataAssurance({ ...dataAssurance, [name]: value });
  };

  const onChangeCoordonneeAssurance = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    const selectedAssurance = value ? coordonneeAssurance.find(item => Number(value) === item.id) : null;

    setDataAssurance((curr: Assurance) => ({
      ...curr,
      nomSociete: (selectedAssurance && selectedAssurance.nomSociete) || '',
      service: (selectedAssurance && selectedAssurance.service) || '',
      adresse: (selectedAssurance && selectedAssurance.adresse) || '',
      complementAdresse: (selectedAssurance && selectedAssurance.complementAdresse) || '',
      codePostal: (selectedAssurance && selectedAssurance.codePostal) || '',
      ville: (selectedAssurance && selectedAssurance.ville) || '',
      email: (selectedAssurance && selectedAssurance.email) || '',
    }));
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const isValide = assuranceValidation(dataAssurance);
    setErreur({ ...erreur, ...isValide.detail });

    if (isValide.error) {
      return;
    };

    setLoadingBtn(() => true);

    id && token && idSinistre && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/assurance/${id}/${idSinistre}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(dataAssurance)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        catchError(data);

        pushToast({ content: "Les données de l'assurance sont bien enregistrées", type: 'success', duration: 5 });
        navigate(`/declaration/circonstance/${idSinistre}`);

      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoadingBtn(() => false);
      });
  };

  return (
    <BasePage>
      <Header />

      <FormAssuranceCustom onSubmit={onSubmit}>

        <TitleSection title="Assurance" />

        <BoxFiledFormAssurance>
          <SelectCoordonneeAssurance
            label="Sélectionner une compagnie assurance"
            id="nomSociete"
            value={dataAssurance.nomSociete}
            option={coordonneeAssurance}
            onChange={onChangeCoordonneeAssurance}
            require={false}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={48.5}
            label="Nom compagnie"
            id="nomSociete"
            type="text"
            value={dataAssurance.nomSociete}
            onChange={onChangeAssurance}
            error={erreur.nomSociete}
          />

          <InputCustom
            width={48.5}
            label="Adresse e-mail "
            id="email"
            type="text"
            value={dataAssurance.email}
            onChange={onChangeAssurance}
            error={erreur.email}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={48.5}
            label="Service"
            id="service"
            type="text"
            value={dataAssurance.service}
            onChange={onChangeAssurance}
            error={erreur.service}
            require={false}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={48.5}
            label="Adresse"
            id="adresse"
            type="text"
            value={dataAssurance.adresse}
            onChange={onChangeAssurance}
            error={erreur.adresse}
          />

          <InputCustom
            width={48.5}
            label="Complément d'adresse"
            id="complementAdresse"
            type="text"
            value={dataAssurance.complementAdresse}
            onChange={onChangeAssurance}
            error={erreur.complementAdresse}
            require={false}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={15}
            label="Code postal"
            id="codePostal"
            type="text"
            value={dataAssurance.codePostal}
            onChange={onChangeAssurance}
            error={erreur.codePostal}
          />

          <InputCustom
            width={82}
            label="Ville"
            id="ville"
            type="text"
            value={dataAssurance.ville}
            onChange={onChangeAssurance}
            error={erreur.ville}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={48.5}
            label="N° de contrat"
            id="numContrat"
            type="text"
            value={dataAssurance.numContrat}
            onChange={onChangeAssurance}
            error={erreur.numContrat}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={32}
            label="Montant de la franchise"
            id="montantFranchise"
            type="text"
            value={dataAssurance.montantFranchise}
            onChange={onChangeAssurance}
            error={erreur.montantFranchise}
            require={false}
          />
          <InputCustom
            width={32}
            label="Référence sinistre"
            id="referenceSinistreAssurance"
            type="text"
            value={dataAssurance.referenceSinistreAssurance}
            onChange={onChangeAssurance}
            error={erreur.referenceSinistreAssurance}
            require={false}
          />

          <InputCustom
            width={32}
            label="Chiffrage assurance"
            id="chiffrageAssurance"
            type="text"
            value={dataAssurance.chiffrageAssurance}
            onChange={onChangeAssurance}
            error={erreur.chiffrageAssurance}
            require={false}
          />
        </BoxFiledFormAssurance>

        <TitleSection title="véhicule" />

        <BoxFiledFormAssurance>
          <InputCustom
            width={32}
            label="Marque du véhicule"
            id="marqueVhl"
            type="text"
            value={dataAssurance.marqueVhl}
            onChange={onChangeAssurance}
            error={erreur.marqueVhl}
          />

          <InputCustom
            width={32}
            label="Modèle du véhicule"
            id="modelVhl"
            type="text"
            value={dataAssurance.modelVhl}
            onChange={onChangeAssurance}
            error={erreur.modelVhl}
          />

          <InputCustom
            width={32}
            label="N° d'immatriculation"
            id="immatriculationVhl"
            type="text"
            value={dataAssurance.immatriculationVhl}
            onChange={onChangeAssurance}
            error={erreur.immatriculationVhl}
          />
        </BoxFiledFormAssurance>

        <BoxFiledFormAssurance>
          <InputCustom
            width={32}
            label="Numéro VIN"
            id="numVin"
            type="text"
            value={dataAssurance.numVin}
            onChange={onChangeAssurance}
            error={erreur.numVin}
          />

          <InputCustom
            width={32}
            label="Kilométrage"
            id="kilometrageVhl"
            type="text"
            value={dataAssurance.kilometrageVhl}
            onChange={onChangeAssurance}
            error={erreur.kilometrageVhl}
          />

          <InputCustom
            width={32}
            label="Année de mise en circulation"
            id="anneeMiseEnCirculationVhl"
            type="text"
            value={dataAssurance.anneeMiseEnCirculationVhl}
            onChange={onChangeAssurance}
            error={erreur.anneeMiseEnCirculationVhl}
          />
        </BoxFiledFormAssurance>

        <BoxFiledBtnFormAssurance>
          <Button
            label="Précédent"
            type="button"
            width={115}
            color="#BDBDBD"
            colorlabel="#EEEEEE"
            loader={false}
            onClick={() => { navigate(`/declaration/assure/${idSinistre}`) }}
          />

          <Button
            label="Suivant"
            type="submit"
            width={115}
            color="#0288d1"
            colorlabel="#EEEEEE"
            loader={loadingBtn}
          />
        </BoxFiledBtnFormAssurance>
      </FormAssuranceCustom>
    </BasePage>
  );
};

