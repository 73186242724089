import { BoxCheckboxSignature, InputCheckboxSignature, LabelCheckboxSignature } from "./CheckboxSignatureStyle";
import { CheckboxSignatureProps } from "./CheckboxSignatureType";

const CheckboxSignature = ({ width, label, id, name, checked, onChange }: CheckboxSignatureProps): JSX.Element => {
    return (
        <BoxCheckboxSignature $width={width}>
            <InputCheckboxSignature id={id} name={name} checked={checked} onChange={onChange} type="checkbox" />
            <LabelCheckboxSignature htmlFor={id}>{label}</LabelCheckboxSignature>
        </BoxCheckboxSignature>
    );
};

export default CheckboxSignature;