import { styled } from "styled-components";
import { ButtonResetSignatureStyleProps } from "./ButtonResetSignatureType";
import { theme } from "../../../theme/theme";

export const ButtonCustomResetSignature = styled.button<ButtonResetSignatureStyleProps>`
    width: fit-content;
    padding: 0 5px;
    font-size: ${theme.font.xsmall};
    height: 20px;
    border-radius: 3px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.disabled ?
    `
        background-color: ${props.theme.color.disabledtest};
        color: ${props.$colorlabel};
        border: solid 1px ${props.theme.color.disabledtest};
        pointer-events: none;
        opacity: 0.5;
       `
    :
    `
        background-color: ${props.$color};
        color: ${props.$colorlabel};
        border: solid 1px ${props.$color};
      `
  }
    &:hover {
      cursor: pointer;
      color: ${props => props.$color};
      background-color: transparent;
    }
    &:active {
      opacity: 0.5;
    }
`;