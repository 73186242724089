import { styled } from "styled-components";

export const BoxLogo = styled.div`
    width: fit-content; 
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const ImgProfact = styled.img`
    width: 120px;
    height: auto;
    @media screen and (min-width: 1024px) {
        width: 120px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
       width: 120px;
    }
    @media screen and (max-width: 767px) {
       width: 100px;
       margin-top: 7.5px;
    }
`;