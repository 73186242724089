import CallGtMotive from "../callGtMotive/CallGtMotive";
import { BoxActionDossierClient } from "./ActionDossierClientStyle";
import { ActionDossierClientProps } from "./ActionDossierClientType";

const ActionDossierClient = ({ isTransmitted, sinistre }: ActionDossierClientProps): JSX.Element | null => {
    if (!isTransmitted) {
        return (
            <BoxActionDossierClient>
                <CallGtMotive sinistre={sinistre} />
           </BoxActionDossierClient>
        );
    };

    return null;
};

export default ActionDossierClient;