import { styled } from "styled-components";

export const BoxFieldPassword = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex; 
        flex-direction: column;
        align-items: center; 
        justify-content: space-between;
    }
`;

export const HeadListePassword = styled.p`
    width: 100%;
    font-size: 14px;
    text-align: left;
    font-style: italic;
    & span {
        color: crimson;
    }
`;

export const BoxConditionPassword = styled.div`
    width: 48.5%;
    text-align: left;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;

export const ListePassword = styled.ul`
    width: 100%;
    text-align: left;
    list-style-type: none;
    font-style: italic;
    margin-bottom: 20px;
`;

export const ItemListePassword = styled.li`
    margin-left: 8px;
    font-size: 14px;
    &:before{
        content: "-";
        padding-right: 8px;
    } 
    & span {
        color: crimson;
    }
`;

export const BoxBtnPassword = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: flex-end;
`;

export const BoxBtnAnnulePassword = styled.div`
    width: 210px;
    display: flex; 
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
