import { createContext,  useContext,  useState } from "react";
import { CreditContextProviderType, CreditContextType, StatusType } from "./CreditContextType";
import { useAuth } from "../authContext/AuthContext";
import { catchError } from "../../core/request/catchError";
import { STATUS_CREDIT_ALERT, STATUS_CREDIT_BLOQUE, STATUS_CREDIT_INFO } from "../../shared/constants/Constatnts";

export const CreditContext = createContext<CreditContextType>({
    creditRestant: 0,
    creditStatus: STATUS_CREDIT_INFO,
    getCreditRestant: () => { }
});

export const useCredit = () => {
    return useContext<CreditContextType>(CreditContext);
};

const CreditContextProvider = ({ children }: CreditContextProviderType) => {

    const { token, id, creditAbonnement } = useAuth().isAuthenticated;

    const [creditRestant, setCreditRestant] = useState<number>(0);
    const [creditStatus, setCreditStatus] = useState<StatusType>(STATUS_CREDIT_INFO);

    const getStatusCredit = (creditRestant: number) => {
        if(creditRestant > 6){
            return STATUS_CREDIT_INFO;
        };
        
        if(creditRestant <= 0){
            return STATUS_CREDIT_BLOQUE;
        };

        if(creditRestant <= 5 || creditRestant <= 0){
            return STATUS_CREDIT_ALERT;
        };

        return STATUS_CREDIT_INFO;
    };

    const getCreditRestant = () => {
        token && id && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/credit/${id}`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                catchError(data);

                setCreditRestant(() => creditAbonnement - data.creditConsoDuMois);
                setCreditStatus(getStatusCredit(creditAbonnement - data.creditConsoDuMois));
            })
            .catch((error: Error) => {
                console.log(error.message);
            });
    };

    return (
        <CreditContext.Provider value={{
            creditRestant,
            creditStatus,
            getCreditRestant
            
        }}>
            {children}
        </CreditContext.Provider>
    );
};

export default CreditContextProvider;

