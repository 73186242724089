import { styled } from "styled-components";

export const BoxLogoLogin = styled.div`
    width: 100%; 
    height: fit-content; 
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const ImgProfact = styled.img`
    height: auto;
    @media screen and (min-width: 1024px) {
        width: 200px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px)  {
       width: 180px;
    }
    @media screen and (max-width: 767px) {
       width: 160px;
       margin-top: 7.5px;
    }
`;