import { useNavigate, useParams } from "react-router-dom";
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import TitleSection from '../../components/common/titleSection/TitleSection';
import SignatureBox from '../../components/common/field/signatureBox/signatureBox';
import Button from '../../components/common/button/Button';
import Header from '../../components/common/header/header/Header';
import BasePage from "../../components/common/basePage/BasePage";
import { RetourPdfContratSchema, RetourPdfContratType } from "../../core/validation/retourPdfSchema";
import { SinistreContext } from "../../contexts/sinistreContext/SinistreContext";
import { ArcticleFormSignature, BoxArticleConvention, BoxBtnConvention, BoxCheckBoxSignature, FormConvention} from "./FormSignatureConventionStyle";
import TitleArticleLoi from "../../components/common/titleArticleLoi/TitleArticleLoi";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { ControleSigantureSchema } from "./FormSignatureConventionType";
import CheckboxSignature from "../../components/feature/signatureConvention/checkboxSignature/CheckboxSignature";
import { catchError } from "../../core/request/catchError";
import  { AuthContext, AuthTest }  from '../../contexts/authContext/AuthContext';

export default function FormSignatureConvention() {
  const navigate = useNavigate();
  const { dataSinistre, addIdContrat, addSigantureConvention } = useContext(SinistreContext);
  const { pushToast } = useToasts();
  const { id, token } = useContext<AuthTest>(AuthContext).isAuthenticated;
  const { idSinistre } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const refSiganture = useRef<SignaturePad | null>(null);

  const [certifiLecture, setCertifiLecture] = useState<boolean>(false);
  const [luEtApprouve, setLuEtApprouve] = useState<boolean>(false);
  const [accepteCgu, setAccepteCgu] = useState<boolean>(false);

  useEffect(() => {
    !idSinistre && navigate('/');
  }, [idSinistre, navigate]);

  useEffect(() => {
    dataSinistre.idContrat && navigate(`/declaration/documents/${idSinistre}`);
  }, [dataSinistre, idSinistre, navigate]);

  const resetSignature = () => {
    const signaturePad = refSiganture.current;
    if (!signaturePad?.isEmpty()) {
      refSiganture.current?.clear();
    };
  };

  const onSubmit = async (e: FormEvent) => {

    e.preventDefault();

    if (!certifiLecture) {
      pushToast({ content: "Vous devez certifier avoir pris connaissance de l’ensemble des éléments", type: 'alert', duration: 5 });
      return;
    };

    if (!accepteCgu) {
      pushToast({ content: "Vous devez certifier avoir pris connaissance des conditions générales d'utilisation", type: 'alert', duration: 5 });
      return;
    };

    if (!luEtApprouve) {
      pushToast({ content: "Vous devez coché la mention lu et approuvé", type: 'alert', duration: 5 });
      return;
    };

    const signaturePad = refSiganture.current;

    if (signaturePad?.isEmpty()) {
      pushToast({ content: "La signature de la convention de règlement des réparations est obligatoire", type: 'alert', duration: 5 });
      return;
    };

    addSigantureConvention(`${signaturePad?.toDataURL()}`);

    const signatures = {
      certifiLectureTextLoi: certifiLecture,
      accepteCgu: accepteCgu,
      luEtApprouveConvention: luEtApprouve,
      sigantureDeclarationSinistre: dataSinistre.sigantureDeclarationSinistre,
      sigantureConventionDeReglement: `${signaturePad?.toDataURL()}`
    };

    if (!ControleSigantureSchema.safeParse(signatures).success) {
      pushToast({ content: "Nous n'avont pas trouvé les signatures de la convention de règlement des réparations est obligatoire", type: 'alert', duration: 5 });
      return;
    };

    setLoading(() => true);

    token && id && idSinistre && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/document/contrat/${idSinistre}`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(signatures)
    })
      .then((response) => {
        return response.json();
      })
      .then((data: RetourPdfContratType) => {

        catchError(data);

        if (!RetourPdfContratSchema.safeParse(data).success) {
          pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
          return;
        };

        addIdContrat(data.idContrat);
        pushToast({ content: "Le document est bien enregistrées", type: 'success', duration: 5 });
        navigate(`/declaration/documents/${idSinistre}`);
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  return (
    <BasePage>
      <Header />

      <FormConvention onSubmit={onSubmit}>

        <TitleSection title="Convention de règlement des réparations" />

        <BoxArticleConvention>

          <TitleArticleLoi title="Article 1" />

          <ArcticleFormSignature>
            <h4>Remise du véhicule au réparateur pour réparation :</h4>
            <p>Le client déclare remettre ce jour son véhicule au réparateur en vue de procéder à sa remise
              en état après accident conformément à l’ordre de réparation et aux règles de l’art.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 2" />

          <ArcticleFormSignature>
            <h4>Accord sur les travaux :</h4>
            <p>Le réparateur s’engage à procéder aux réparations nécessaires pour remettre en état le
              véhicule du client conformément aux règles de l’art. Le client s’engage à prévenir sa
              compagnie d’assurance des réparations à effectuer. Le client déclare bénéficier de la
              garantie nécessaire pour la prise en charge des réparations par la compagnie d’assurance.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 3" />

          <ArcticleFormSignature>
            <h4>Règlement des réparations :</h4>
            <p>Le client s’engage en contrepartie de la réparation du véhicule à régler au réparateur
              l’ensemble des réparations effectuées en l’exécution de l’ordre de réparation.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 4" />

          <ArcticleFormSignature>
            <h4>Paiement des réparations effectuées :</h4>
            <p>En l’exécution de cette obligation le client cède par la présente au réparateur sa créance à
              l’encontre de sa compagnie d’assurance au titre de la prise en charge du sinistre dont le
              montant total viendra s’imputer en déduction sur le montant total de la réparation. Le solde
              éventuel de la facture restera à la charge du client. Le prix de la cession de créance sera
              également au montant des sommes dues par la compagnie d’assurance au client et sera
              réglé au réparateur par compensation à due concurrence entre le montant de la facture et la
              créance du client sur la compagnie d’assurance.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 5" />

          <ArcticleFormSignature>
            <h4>Effet de la cession :</h4>
            <p>Le client cède par la présence au réparateur qui bénéficiera des mêmes droits en matière de
              subrogation l’intégralité de sa créance ainsi que tous les droits d'action et accessoires qui
              sont attachés sans restriction ni exception et notamment les obligations de sa créance
              concernant les délais de paiement à l’égard de la compagnie d’assurance. En l’application
              de la présente session le réparateur pourra demander en lieu et place à la société
              d’assurance le paiement direct entre ses mains des sommes dues au client qui a interdit de
              solliciter d’accepter ou d’empêcher de quelque manière et pour quelque raison que ce soit le
              règlement.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 6" />

          <ArcticleFormSignature>
            <h4>Garantie du cédant :</h4>
            <p>Le client atteste être à jour au règlement de sa prime d’assurance et garantie expressément
              existence le montant et les accessoires de la créance à l’encontre de la compagnie d’assurance.
              Il garantit le réparateur de tous ces évènements ou recours de tiers à son encontre qui
              pourrait entraîner la déchéance de son droit à garantie ou porter atteinte pour remettre en
              cause le règlement effectif entre les mains du réparateur de la créance, objet de la présente
              session. En conséquence le client ou le signataire de la présente se porte fort du règlement
              effectif des sommes qui sont dues aux clients par sa compagnie d’assurance et s’engage en
              exécution de cette obligation à indemniser le réparateur à hauteur de son préjudice qui ne
              pourra être inférieur au solde de sa facture, dans l’hypothèse où l’assurance règlerait pas
              l’intégralité des sommes restant dû au réparateur on refuserait tout règlement pour quelques
              motifs que ce soit.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 7" />

          <ArcticleFormSignature>
            <h4>Inexécution du contrat :</h4>
            <p>En cas de défaut de règlement effectif par la compagnie d’assurance pour quelque motif que ce
              soit ou en cas de violation par le client de l’une ou quelconque des obligations mises à la
              charge par les présentes où son contrat d’assurance le réparateur pourra à son choix agir
              contre l’assurance au lieu et place du client agir contre le client au titre de sa promesse de
              porte fort. Le réparateur pourra encore invoquer la résolution de plein droit du présent
              contrat et /ou faire constater ses droits par voie de référé.</p>
          </ArcticleFormSignature>

          <TitleArticleLoi title="Article 8" />

          <ArcticleFormSignature>
            <h4>Effets de la résolution du contrat :</h4>
            <p>La résolution du contrat pour une cause imputable au client ou à la compagnie d’assurance
              pourra ouvrir droit à la réparation pour le réparateur. Il pourra demander au client outre le
              paiement intégral de sa facture L’indemnisation complète de son préjudice et éventuellement
              de ses frais de justice. Dans ce cas notamment si le refus de l’assurance de procéder au
              règlement des sommes dues trouve son origine dans une fausse déclaration ou dans une
              manoeuvre dolosive du client le réparateur pourra majorer les sommes dues d’une clause
              pénale égale à 15 % de la facture. En outre à titre de garantie et sous condition suspensive
              des cas d’inexécution du présent contrat prévu aux articles précédents le client consent au
              réparateur un privilège sur son véhicule à hauteur du solde des sommes restant dues
              conformément aux dispositions des articles 2073 et 2075 du Code civil.
              Ce privilège pourra être inscrit auprès de la préfecture dès notification par le réparateur par
              lettre recommandée de sa volonté de résilier le contrat ou sur production de la lettre de refus
              de règlement de l’assurance du client.</p>
          </ArcticleFormSignature>
        </BoxArticleConvention>

        <TitleSection title="Signature du client" />

        <BoxCheckBoxSignature>
          <CheckboxSignature
            label="Je certifie avoir pris connaissance de l’ensemble des éléments ci-dessus"
            id="certifiLecture"
            name="certifiLecture"
            checked={certifiLecture}
            onChange={() => setCertifiLecture((curr: boolean) => !curr)}
          />

          <CheckboxSignature
            label="Je certifie avoir pris connaissance des conditions générales d'utilisation"
            id="accepteCgu"
            name="accepteCgu"
            checked={accepteCgu}
            onChange={() => setAccepteCgu((curr: boolean) => !curr)}
          />

          <CheckboxSignature
            label="Lu et approuvé"
            id="luEtApprouve"
            name="luEtApprouve"
            checked={luEtApprouve}
            onChange={() => setLuEtApprouve((curr: boolean) => !curr)}
          />
        </BoxCheckBoxSignature>

        <SignatureBox
          widthContainer={55}
          width={100}
          ref={refSiganture}
          error=""
          reset={resetSignature}
        />

        <BoxBtnConvention>
          <Button
            label="Créer le contrat"
            type="submit"
            width={200}
            color="#0288d1"
            colorlabel="#EEEEEE"
            loader={loading}
          />
        </BoxBtnConvention>
      </FormConvention>
    </BasePage>
  );
};

