import { styled } from "styled-components";
import { CheckboxstylePropsType } from "./CheckboxType";

export const BoxCheckbox = styled.div<CheckboxstylePropsType>`
    width: ${props => props.$width ? `${props.$width}` : `fit-content`};
    margin-bottom: ${props => props.$mb ? `${props.$mb}px` : `15px`};
    display: flex;
    ${props => props.$reverse === true ? `flex-direction: row-reverse;` : `flex-direction: row;`}
    align-items: flex-start;
`;

export const InputCheckbox = styled.input<CheckboxstylePropsType>`
    width: 20px;
    height: 20px;
    ${props => props.$reverse === true ? ` margin-right: 10px;` : ` margin-left: 10px;`}
    @media screen and (max-width: 767px) {
        width: 15px;
        height: 15px;
    }
`;