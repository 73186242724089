import { keyframes, styled } from "styled-components";
import { XLg, Check2Circle, ExclamationCircle } from "react-bootstrap-icons";
import { ToastStyleProps } from "./ToastType";
import { theme } from "../../../../theme/theme";

export const toastAppear = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const toastBackGround = (color: string) => {

    let colorBase: string = theme.color.info;

    switch (color) {
        case "regular":
            colorBase = theme.color.info;
            break;
        case "alert":
            colorBase = theme.color.alert;
            break;
        case "success":
            colorBase = theme.color.success;
            break;
    };

    return `
      background-color: ${colorBase};
    `;
};

export const ToastContent = styled.div<ToastStyleProps>`
    z-index: 99999;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: ${theme.css.radius};
    color: ${theme.color.white};
    font-size: ${theme.font.xsmall};
    ${props => props.$type && toastBackGround(props.$type)}
    margin-bottom: 10px;
    animation: ${toastAppear} 0.3s forwards;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: 10px 5px;
    }
`;

export const IconClose = styled(XLg)`
    font-size: ${theme.font.small};
    margin-left: 15px;
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.xsmall};
    }
`;

export const IconSuccess = styled(Check2Circle)`
    font-size: ${theme.font.medium};
    margin-right: 15px;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.normal};
    }
`;

export const IconAlert = styled(ExclamationCircle)`
    font-size: ${theme.font.normal};
    margin-right: 15px;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.small};
    }
`;

export const MessageToast = styled.p`
    font-size: ${theme.font.small};
    white-space: nowrap;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.xsmall};
    }
`; 
