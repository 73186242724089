import { BoxFieldCustom } from "./BoxFieldStyle";
import { BoxFieldPropsType } from "./BoxFieldType";

const BoxField = ({ children, mt, mb, width, height, flexDirection, alignItems, justifyContent }: BoxFieldPropsType): JSX.Element => {
    return (
        <BoxFieldCustom
            $mt={mt}
            $mb={mb}
            $width={width}
            $height={height}
            $flexDirection={flexDirection}
            $alignItems={alignItems}
            $justifyContent={justifyContent}
        >
            {children}
        </BoxFieldCustom>
    );
};

export default BoxField;